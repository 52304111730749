export const getFromLocalStorage = (key: string) => {
  const storageValue = localStorage.getItem(key);

  if (!storageValue) {
    return null;
  }

  return JSON.parse(storageValue);
};

export const setInLocalStorage = (key: string, value: string | object | number) => {
  localStorage.setItem(key, JSON.stringify(value));
};
