import { Control, Controller, FieldErrors, useFieldArray, UseFormRegister, UseFormWatch } from "react-hook-form";
import { formatCurrency } from "@/common/utils/formatters";
import { CircleAlert } from "lucide-react";
import { useProductCategories } from "@/common/hooks/queries/useProductCategories";
import { Mapper } from "@/common/services/mapper";
import { ProductCategoryData } from "@/common/types/productCategory.type";
import Select from "@/common/components/Select";
import Accordion from "@/common/components/Accordion";
import Checkbox from "@/common/components/Checkbox";
import useDebounce from "@/common/hooks/useDebounce";
import { CurrencyTextField } from "@/common/components/CurrencyTextField";
import { CreditProductCategoryConfig } from "./CreditProductCategoryConfig";
import { CompanyBranchValidationSchema } from "../../schemas/companyBranch.schema";
import { CompanyData } from "@/features/company/types/company.type";

interface CreditConfigurationProps {
  companyData?: CompanyData;
  register: UseFormRegister<CompanyBranchValidationSchema>;
  control: Control<CompanyBranchValidationSchema>;
  watch: UseFormWatch<CompanyBranchValidationSchema>;
  errors: FieldErrors<CompanyBranchValidationSchema>;
}

export const CreditConfiguration = ({ register, control, watch, errors, companyData }: CreditConfigurationProps) => {
  const [searchProductCategory, setSearchProductCategory] = useDebounce("");

  const { fields: categoryGrantedCreditFields, append: appendCategoryGrantedCredit, remove: removeCategoryGrantedCredit } = useFieldArray({
    control,
    name: "product_category_granted_credits_attributes",
  });

  const { data: productCategoryOptions } = useProductCategories({
    filters: { search: searchProductCategory },
    mapper: Mapper.mapToOptions<ProductCategoryData>({ labelFieldName: "display_name", valueFieldName: "id" }),
  });

  const handleAppendCategory = (option: { value: string, label: string; }) => {
    if (option.value) {
      appendCategoryGrantedCredit({
        product_category_id: option.value,
        granted_credit: "",
        product_granted_credits_attributes: [],
      });
    }
  };

  const enableCreditConfiguration = watch("enableCreditConfiguration");

  return (
    <div className="flex flex-col gap-4">
      <p className="font-bold text-lg">
        Configurações de crédito
      </p>

      <div className="flex flex-col gap-2">
        <Controller
          name="granted_credit"
          control={control}
          render={({ field }) => (
            <CurrencyTextField
              label="Crédito garantido a filial"
              markAsRequired
              allowZero={true}
              errorMessage={errors.granted_credit?.message || undefined}
              {...field}
            />
          )}
        />

        <div className="flex items-center gap-4">
          <p className="text-[12px] font-light italic">
            Crédito garantido a empresa: {formatCurrency(Number(companyData?.current_contract?.granted_credit))}
          </p>

          <p className="text-[12px] font-light italic">
            Crédito livre para alocação: {formatCurrency(Number(0))}
          </p>
        </div>
      </div>

      <Accordion
        head={(
          <div className="flex flex-col gap-2">
            {errors.enableCreditConfiguration && (
              <p className="text-sm text-danger-soft">
                {errors.enableCreditConfiguration.message || undefined}
              </p>
            )}

            <Checkbox
              label="Crédito garantido por serviço"
              onClick={(event) => event.stopPropagation()}
              {...register("enableCreditConfiguration")}
            />
          </div>
        )}
        body={(
          <div className="flex flex-col gap-4">
            <Select
              placeholder="Pesquisar serviços"
              options={productCategoryOptions}
              onChange={(option) => handleAppendCategory(option as { value: string, label: string; })}
              onInputChange={setSearchProductCategory}
              value=""
              isDisabled={!enableCreditConfiguration}
            />

            <div className="flex items-center gap-2">
              <CircleAlert strokeWidth={1} color="var(--warning)" />

              <div>
                <p className="text-[12px]">
                  Ao selecionar um Serviço e/ou Produto, todos os não selecionados serão bloqueados para consumo
                </p>

                <p className="text-[12px] font-light italic">
                  Certifique-se de adicionar as restrições para todos os serviços e produtos que desejar consumir
                </p>
              </div>
            </div>

            {categoryGrantedCreditFields.map((category, index) => (
              <CreditProductCategoryConfig
                key={index}
                categoryId={category.product_category_id}
                categoryIndex={index}
                removeCategory={() => removeCategoryGrantedCredit(index)}
                control={control}
                errors={errors}
                disabled={!enableCreditConfiguration}
              />
            ))}
          </div>
        )}
      />
    </div>
  );
};
