import Badge from "@/common/components/Badge";
import Box from "@/common/components/Box";
import Select from "@/common/components/Select";
import { useProducts } from "@/common/hooks/queries/useProducts";
import { Mapper } from "@/common/services/mapper";
import { ProductData } from "@/common/types/product.type";
import { Boxes } from "lucide-react";
import { Control, Controller, FieldErrors, useFieldArray } from "react-hook-form";
import useDebounce from "@/common/hooks/useDebounce";
import { CurrencyTextField } from "@/common/components/CurrencyTextField";
import useProductCategory from "@/common/hooks/queries/useProductCategory";
import { CreditProductConfig } from "./CreditProductConfig";
import { CompanyBranchValidationSchema } from "../../schemas/companyBranch.schema";

interface CreditProductCategoryConfigProps {
  categoryId: string;
  categoryIndex: number;
  removeCategory: () => void;
  control: Control<CompanyBranchValidationSchema>;
  errors: FieldErrors<CompanyBranchValidationSchema>;
  disabled?: boolean;
}

export const CreditProductCategoryConfig = ({ categoryId, categoryIndex, removeCategory, control, errors, disabled }: CreditProductCategoryConfigProps) => {
  const [searchProduct, setSearchProduct] = useDebounce("");

  const { fields: productGrantedCreditFields, append: appendProductGrantedCredit, remove: removeProductGrantedCredit } = useFieldArray({
    control,
    name: `product_category_granted_credits_attributes.${categoryIndex}.product_granted_credits_attributes`,
  });

  const { data: productCategory } = useProductCategory(categoryId);

  const { data: productsOptions } = useProducts({
    filters: {
      category: categoryId,
      search: searchProduct,
    },
    mapper: Mapper.mapToOptions<ProductData>({ labelFieldName: "display_name", valueFieldName: "id" })
  });

  const handleAppendProduct = (option: { value: string; label: string; }) => {
    if (option.value) {
      appendProductGrantedCredit({
        product_id: option.value,
        display_name: option.label,
        granted_credit: "",
      });
    }
  };

  return (
    <Box className="flex flex-col gap-4">
      <div>
        <Badge
          Icon={Boxes}
          text={productCategory?.display_name || ""}
          onDelete={() => removeCategory()}
          className="rounded-sm flex bg-neutral-op-8 w-fit flex-row-reverse overflow-hidden !p-0 gap-0"
          overwriteClassName
          deleteClassName="bg-black p-2"
          contentClassName="px-3 py-2"
          deleteIconColor="var(--foreground)"
          disabled={disabled}
        />
      </div>

      <Controller
        name={`product_category_granted_credits_attributes.${categoryIndex}.granted_credit`}
        control={control}
        disabled={disabled}
        render={({ field }) => (
          <CurrencyTextField
            label="Valor total"
            markAsRequired
            maxValue={9_999_999.99}
            errorMessage={errors.product_category_granted_credits_attributes?.[categoryIndex]?.granted_credit?.message || " "}
            {...field}
          />
        )}
      />

      <Select
        placeholder="Pesquisar produtos"
        options={productsOptions}
        onChange={(option) => handleAppendProduct(option as { value: string; label: string; })}
        onInputChange={setSearchProduct}
        value=""
        disabled={disabled}
      />

      {productGrantedCreditFields.map((product, index) => (
        <CreditProductConfig
          key={index}
          product={product}
          categoryIndex={categoryIndex}
          productIndex={index}
          control={control}
          errors={errors}
          removeProduct={() => removeProductGrantedCredit(index)}
          disabled={disabled}
        />
      ))}
    </Box>
  );
};
