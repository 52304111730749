import { useMutation } from "@tanstack/react-query";
import { LoginValidationSchema } from "../schemas/login.schema";
import { setInLocalStorage } from "@/common/services/localStorageGetSet";
import { AxiosError } from "axios";
import useCompanyAxios from "@/common/api/useCompanyAxios";
import { useAuth } from "@/common/stores/auth";
import { unFormatCnpj } from "@/common/utils/unFormatters";

interface useLoginProps {
  onSuccess?: (response: any) => void;
  onError?: (error: AxiosError) => void;
}

const useLogin = ({ onSuccess, onError }: useLoginProps) => {
  const companyAxios = useCompanyAxios();
  const { updateAccessToken } = useAuth();

  return useMutation({
    mutationKey: ['login'],
    mutationFn: async (form: LoginValidationSchema) => {
      const { data, headers } = await companyAxios.post('/auth/login', {
        client_user: {
          cpf: unFormatCnpj(form.cpf),
          password: form.password
        }
      });

      return {
        infos: data,
        accessToken: headers?.authorization
      };
    },
    onSuccess: (data) => {
      setInLocalStorage('access-token', data.accessToken);
      updateAccessToken(data.accessToken);
      onSuccess?.(data);
    },
    onError,
  });
};

export default useLogin;
