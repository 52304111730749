import Select from "@/common/components/Select";
import TextField from "@/common/components/TextField";
import { CompanyData } from "../../types/company.type";
import Button from "@/common/components/Button";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "@/common/utils/formatters";
import { ConsumptionRestriction } from "./ConsumptionRestriction";
import { PeriodRestriction } from "./PeriodRestriction";
import { CreditConfiguration } from "./CreditConfiguration";

interface OperationalInformationSectionProps {
  matrixBranch?: CompanyData['matrix_branch'];
  currentContract?: CompanyData['current_contract'];
}

export const OperationalInformationSection = ({
  matrixBranch,
  currentContract,
}: OperationalInformationSectionProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <p className="text-[18px] font-bold">
          Dados operacionais
        </p>

        <Button
          type="button"
          onClick={() => navigate("/company/mine/contract")}
        >
          Ver contrato
        </Button>
      </div>

      <Select
        label="Serviços"
        placeholder="Não informado"
        value={currentContract?.product_categories?.map(({ id, display_name }) => ({ value: id, label: display_name })) || []}
        isDisabled
      />

      <TextField
        label="Quantidade total de Equipamentos"
        placeholder="Não informado"
        defaultValue={matrixBranch?.equipments_count !== null && matrixBranch?.equipments_count !== undefined ? Number(matrixBranch?.equipments_count) : ''}
        disabled
      />

      <TextField
        label="Crédito garantido"
        placeholder="Não informado"
        defaultValue={currentContract?.granted_credit ? formatCurrency(Number(currentContract?.granted_credit)) : ''}
        disabled
      />

      <CreditConfiguration
        productCategoryGrantedCredits={matrixBranch?.product_category_granted_credits}
      />

      <ConsumptionRestriction
        restriction={matrixBranch?.restriction}
      />

      <PeriodRestriction
        periodRestrictions={matrixBranch?.restriction?.period_restrictions}
      />
    </div>
  );
};
