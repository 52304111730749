import usePrivateAxios from "@/common/api/usePrivateAxios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { CompanyData, CompanyResponse } from "../types/company.type";

export const useMineCompany: () => UseQueryResult<CompanyData, unknown> = () => {
  const privateAxios = usePrivateAxios();

  return useQuery<CompanyResponse, unknown, CompanyData>({
    queryKey: ['companiesMine'],
    queryFn: () => privateAxios.get(`/companies/mine`),
    select: (data) => data?.data?.data,
  });
};
