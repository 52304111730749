import { dateTransformer } from '@/common/utils/dateTransformer';
import { formatCpf, formatPhoneNumber } from '@/common/utils/formatters';
import { transformRolesToOptions } from '@/common/utils/roleTransformers';
import { statusOptions } from '@/features/users/constants/statusOptions.constant';
import { ClientUserData } from '@/features/users/types/clientUsers';

export const clientUserDefaultValues = (
  clientUser?: ClientUserData,
) => ({
  cpf: clientUser?.cpf ? formatCpf(clientUser?.cpf) : undefined,
  date_of_birth: clientUser?.date_of_birth
    ? dateTransformer(clientUser?.date_of_birth)
    : undefined,
  full_name: clientUser?.full_name || undefined,
  branch_id: clientUser?.branch
    ? { label: clientUser?.branch.display_name, value: clientUser?.branch.id }
    : undefined,
  user_roles: clientUser?.user_roles.length
    ? transformRolesToOptions(clientUser?.user_roles)
    : [],
  status:
    statusOptions.find(({ value }) => value === clientUser?.status) ||
    undefined,
  phone: {
    number: clientUser?.phone?.number ? formatPhoneNumber(clientUser?.phone?.number) : undefined,
  },
  email: clientUser?.email || undefined,
  enrollment_number: clientUser?.enrollment_number || undefined,
});
