import { privateAxios } from '@/common/api/axios';
import { logRelatedTypes } from '@/common/constants/logRelatedTypes';
import { defaultTransformerResponse } from '@/common/constants/defaultTransformerResponse.constant';
import { Mapper } from '@/common/services/mapper';
import { MetaResponse } from '@/common/types/metaResponse';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

type useLogRequests<T> = {
  filters?: {
    search?: string;
    page?: number;
    success?: boolean;
    performedByType?: keyof typeof logRelatedTypes;
    performedById?: string;
    createdAtGt?: string;
    createdAtLt?: string;
  };
  mapper?: (data: any) => T;
};

const useLogRequests = <T,>({
  filters: { search = '', page, success, performedByType, performedById, createdAtGt, createdAtLt } = {},
  mapper = Mapper.default<T>(),
}: useLogRequests<T>) => {
  const {
    data: { data, meta } = defaultTransformerResponse<T>(),
    isPending,
    ...rest
  } = useQuery({
    queryKey: ['logChanges', search, page, success, performedByType, performedById, createdAtGt, createdAtLt],
    queryFn: () => {
      return privateAxios.get(`/log/requests`, {
        params: {
          search,
          page,
          'q[success_true]': success === true || null,
          'q[success_false]': success === false || null,
          'q[performed_by_type]': logRelatedTypes[performedByType as keyof typeof logRelatedTypes],
          'q[performed_by_id]': performedById,
        },
      });
    },
    select: (data: { data: { data: any[]; meta: MetaResponse; }; }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      };
    },
    placeholderData: keepPreviousData,
  });

  return { data, meta, isPending, ...rest };
};

export default useLogRequests;
