import MaskedTextField from "@/common/components/MaskedTextField";
import TextField from "@/common/components/TextField";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { RequiredFieldName } from "../../types/equipments.type";
import { EquipmentValidationSchema } from "../../schemas/create.schema";

export const FieldComponent = ({
  fieldName,
  register,
  errors,
  disabled,
}: {
  fieldName: RequiredFieldName,
  register: UseFormRegister<EquipmentValidationSchema>,
  errors: FieldErrors<EquipmentValidationSchema>,
  disabled?: boolean;
}) => {
  const fieldMap = {
    official_name: (
      <TextField
        label="Nome"
        markAsRequired
        maxLength={64}
        {...register('official_name')}
        errorMessage={errors.official_name?.message || undefined}
        disabled={disabled}
      />
    ),
    serial_number: (
      <TextField
        label="Número de série"
        markAsRequired
        maxLength={64}
        {...register('serial_number')}
        errorMessage={errors.serial_number?.message || undefined}
        disabled={disabled}
      />
    ),
    license_plate: (
      <MaskedTextField
        label="Placa do veículo"
        markAsRequired
        mask={["aaa0a00", "aaa0000"]}
        {...register('license_plate')}
        errorMessage={errors.license_plate?.message || undefined}
        disabled={disabled}
      />
    ),
    chassis_number: (
      <TextField
        label="Chassi"
        markAsRequired
        maxLength={17}
        {...register('chassis_number')}
        errorMessage={errors.chassis_number?.message || undefined}
        disabled={disabled}
      />
    ),
    renavam: (
      <MaskedTextField
        label="Renavam"
        markAsRequired
        mask="00000000000"
        {...register('renavam')}
        errorMessage={errors.renavam?.message || undefined}
        disabled={disabled}
      />
    ),
  };

  return fieldMap[fieldName];
};
