import { LogRequest } from "@/common/types/logs/request.type";
import { ImageWithPlaceholder } from "../../ImageWithPlaceholder";
import { UserIcon } from "lucide-react";
import { formatDateTime } from "@/common/utils/formatters";
import { UAParser } from "ua-parser-js";

const RequestResume = ({ request }: { request: LogRequest }) => {
  const userAgent = new UAParser(request?.user_agent);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-6">
        <div className="flex flex-col">
          <p className="font-light text-sm italic">Autor</p>

          <div className="flex items-center gap-2">
            <ImageWithPlaceholder
              placeholderIcon={UserIcon}
              url={request?.performed_by?.profile_picture.url}
              className="size-8"
              iconClassName="size-5"
            />

            <div className="flex flex-col">
              <p>{request?.performed_by?.full_name}</p>
              <p className="text-xs">{request?.performed_by?.email}</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <p className="font-light text-sm italic">ID da requisição</p>
          <p>{request?.id}</p>
        </div>

        <div className="flex flex-col">
          <p className="font-light text-sm italic">Data e hora</p>
          <p>{request?.created_at ? formatDateTime(new Date(request?.created_at)) : null}</p>
        </div>
      </div>

      <div className="flex gap-6">
        <div className="flex flex-col">
          <p className="font-light text-sm italic">Ação</p>
          <p>{request?.operation_description}</p>
        </div>

        <div className="flex flex-col">
          <p className="font-light text-sm italic">Resultado</p>
          {request?.success && <p className="text-green-400">Sucesso</p>}
          {!request?.success && <p className="text-red-400">Falha</p>}
        </div>

        <div className="flex flex-col">
          <p className="font-light text-sm italic">Navegador utilizado</p>
          <p>{userAgent.getBrowser().name}</p>
        </div>

        <div className="flex flex-col">
          <p className="font-light text-sm italic">IP</p>
          <p>{request?.ip}</p>
        </div>
      </div>
    </div>
  );
};

export default RequestResume;
