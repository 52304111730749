import {
  Tabs as TabsBase,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/common/components/ui/tabs";
import { useNavigate, useLocation, Route, Routes } from "react-router-dom";
import { useEffect, useMemo } from "react";

export interface TabsProps {
  tabsList: {
    value: string;
    label: string;
    disabled?: boolean;
    body: JSX.Element | string;
  }[];
  defaultValue?: string;
  tabsClassName?: string;
  tabsListClassName?: string;
  tabsTriggerClassName?: string;
  tabsContentClassName?: string;
}

const Tabs = ({
  tabsList,
  defaultValue = '',
  tabsClassName,
  tabsListClassName,
  tabsTriggerClassName,
  tabsContentClassName,
}: TabsProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialPath = useMemo(() => {
    const pathname = location.pathname.split('/');
    const lastPath = pathname.pop();
    const tabsListValues = tabsList.map((tab) => tab.value);

    return tabsListValues.includes(lastPath || '') ? pathname.join('/') : location.pathname;
  }, []);

  const currentTab = useMemo(
    () => location.pathname.slice(initialPath.length).split('/').pop(),
    [initialPath, location.pathname]
  );

  useEffect(() => {
    if (!currentTab) {
      navigate(defaultValue);
    }
  }, [currentTab, defaultValue]);

  const handleTabChange = (value: string) => {
    navigate(value ? `${initialPath}/${value}` : initialPath);
  };

  return (
    <TabsBase
      value={currentTab || defaultValue}
      onValueChange={handleTabChange}
      className={tabsClassName}
    >
      <TabsList className={tabsListClassName}>
        {tabsList.map((tab, index) => (
          <TabsTrigger
            key={index}
            value={tab.value}
            className={tabsTriggerClassName}
            disabled={tab.disabled}
          >
            {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>

      <Routes>
        {tabsList.map((content, index) => (
          <Route
            key={content.value}
            path={content.value}
            element={(
              <TabsContent
                key={index}
                value={content.value}
                className={tabsContentClassName}
              >
                {content.body}
              </TabsContent>
            )}
          />
        ))}
      </Routes>
    </TabsBase>
  );
};

export default Tabs;
