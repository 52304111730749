import Button from "@/common/components/Button";
import Modal from "@/common/components/Modal";
import { useState } from "react";

const ForgotPasswordModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onConfirm={() => setIsOpen(false)}
      Trigger={(
        <Button variant="outline-error" className="hover:text-danger text-sm px-0 ml-auto">
          Esqueci minha senha
        </Button>
      )}
      className="max-w-96"
      showClose={false}
      titleClass="text-2xl"
      subtitleClassName="mt-1 text-white-400"
      title="Você não pode redefinir sua própria senha."
      subtitle="Solicite suporte do seu Gestor ou da Taurus para redefinir sua senha."
      footer="Entendido!"
    />
  );
};

export default ForgotPasswordModal;
