import { privateAxios } from "@/common/api/axios";
import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";

interface updateEquipmentUsageParams {
  value: number;
  reason: string;
}

interface useUpdateEquipmentUsageOptions {
  equipmentId: string;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

const useUpdateEquipmentUsage = ({ equipmentId, onSuccess, onError }: useUpdateEquipmentUsageOptions): UseMutationResult<void, Error, updateEquipmentUsageParams> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ value, reason }: updateEquipmentUsageParams) => {
      await privateAxios.post(`/equipments/${equipmentId}/last_usage_declaration`, {
        last_usage_declaration: {
          value, reason
        }
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['equipment', equipmentId] });
      onSuccess?.();
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};

export default useUpdateEquipmentUsage;
