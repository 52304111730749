import usePrivateAxios from "@/common/api/usePrivateAxios";
import { getRailsUpdate } from "@/common/utils/getRailsUpdate";
import { mapToDeletion } from "@/common/utils/railsHelpers";
import { unFormatCnpj as unFormatMask, unFormatCurrency, unFormatUnit } from "@/common/utils/unFormatters";
import { useMutation } from "@tanstack/react-query";
import { CompanyBranchValidationSchema } from "../schemas/companyBranch.schema";
import { CompanyBranchData, CompanyBranchProductCategoryGrantedCreditData } from "@/features/company/types/company.type";
import { transformNewProductCategoryGrantedCredit, transformUpdatedProductCategoryGrantedCredit } from "../services/companyBranchTransformer";
import { AxiosError, AxiosResponse } from "axios";

interface UseUpdateCompanyBranch {
  branchId: string | undefined,
  branchData: CompanyBranchData | undefined,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError) => void,
}

const useUpdateCompanyBranch = ({
  branchId,
  branchData,
  onSuccess,
  onError,
}: UseUpdateCompanyBranch) => {
  const privateAxios = usePrivateAxios();
  return useMutation({
    mutationKey: ['updateCompanyBranch'],
    mutationFn: async ({ form, file }: { form: CompanyBranchValidationSchema, file?: File }) => {
      const payload = {
        ...form,
        parent_id: form.parent_id?.value,
        master_client_user_id: form.master_client_user_id?.value,
        general_information_attributes: form.general_information_attributes?.willUseOwnData
          ? {
            ...form.general_information_attributes,
            address_attributes: {
              ...form.general_information_attributes?.address_attributes,
              city_id: form.general_information_attributes?.address_attributes?.city_id,
            },
            company_logo: file || undefined,
          } : branchData?.general_information ? {
            id: branchData?.general_information.id,
            _destroy: true
          } : undefined,
        restriction_attributes: {
          period_restrictions_attributes: form.enablePeriodRestriction
            ? form.period_restrictions_attributes?.map((periodRestriction, index) => {
              return {
                id: periodRestriction.id,
                week_day: periodRestriction.week_day,
                intervals_attributes: [
                  ...(() => {
                    const deletedIntervals = [] as { id: string, _destroy: boolean; }[];
                    branchData?.restriction.period_restrictions?.[index]?.intervals?.forEach((interval) => {
                      deletedIntervals.push({
                        id: interval.id,
                        _destroy: true
                      });
                    });
                    return deletedIntervals;
                  })(),
                  ...(() => {
                    const newIntervals = [] as { start_time?: string | null, end_time?: string | null; }[];

                    if (periodRestriction.blocked) {
                      return [];
                    }

                    if (periodRestriction.intervals_attributes?.some((interval) => interval.start_time?.value === 'all_day')) {
                      newIntervals.push({ start_time: '00:00', end_time: '23:59' });
                      return newIntervals;
                    }

                    periodRestriction?.intervals_attributes?.forEach((interval) => {
                      newIntervals.push({ start_time: interval.start_time?.value, end_time: interval.end_time?.value });
                    });
                    return newIntervals;
                  })(),
                ]
              };
            })
            : branchData?.restriction?.period_restrictions?.length ? [
              ...branchData?.restriction?.period_restrictions.map((periodRestriction) => ({
                id: periodRestriction.id,
                _destroy: true
              }))
            ] : undefined,
          minimum_kilometers_usage_between_consumptions:
            form.minimum_kilometers_usage_between_consumptions
              ? unFormatUnit(form.minimum_kilometers_usage_between_consumptions, 'km')
              : null,
          minimum_seconds_usage_between_consumptions:
            form.minimum_seconds_usage_between_consumptions
              ? Number(unFormatMask(form.minimum_seconds_usage_between_consumptions)) * 3600
              : null,
          minimum_seconds_interval_between_consumptions:
            form.minimum_seconds_interval_between_consumptions
              ? Number(unFormatMask(form.minimum_seconds_interval_between_consumptions)) * 3600
              : null,
        },
        granted_credit: form.granted_credit ? unFormatCurrency(form.granted_credit) : undefined,
        product_category_granted_credits_attributes: form.enableCreditConfiguration ? [
          ...getRailsUpdate(
            branchData?.product_category_granted_credits || [],
            form?.product_category_granted_credits_attributes || [],
            (productCategory) => productCategory.product_category.id,
            (formProductCategory) => formProductCategory.product_category_id,
            mapToDeletion,
            (newProductCategory) => transformNewProductCategoryGrantedCredit(newProductCategory, branchData?.product_category_granted_credits || []),
          ),
          ...form.product_category_granted_credits_attributes
            ?.filter((productCategory) => !!productCategory.id)
            ?.map((updatedProductCategory) => transformUpdatedProductCategoryGrantedCredit(updatedProductCategory, branchData?.product_category_granted_credits as CompanyBranchProductCategoryGrantedCreditData[])) || [],
        ] : branchData?.product_category_granted_credits?.map(mapToDeletion) || undefined,
      };

      return privateAxios.put(`/company_branches/${branchId}`, { company_branch: payload });
    },
    onSuccess,
    onError,
  });
};

export default useUpdateCompanyBranch;
