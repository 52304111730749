import Badge from "@/common/components/Badge";
import Box from "@/common/components/Box";
import TextField from "@/common/components/TextField";
import { ProductData } from "@/common/types/product.type";
import { formatCurrency } from "@/common/utils/formatters";
import { FileBox } from "lucide-react";

interface CreditProductConfigProps {
  product: ProductData;
  grantedCredit: string | number;
}

export const CreditProductConfig = ({ product, grantedCredit }: CreditProductConfigProps) => {
  return (
    <Box className="flex flex-col gap-4">
      <Badge
        Icon={FileBox}
        text={product?.display_name || ""}
        className="flex-row-reverse overflow-hidden !p-0 gap-0"
        contentClassName="px-2 py-1"
      />

      <TextField
        label="Valor total"
        value={formatCurrency(Number(grantedCredit))}
        disabled
      />
    </Box>
  )
}
