import { useMemo } from "react";
import { AddressData, CityData } from "@/common/types/address";
import { GeoCityData } from "../types/geoGeoable";

const useAddressMapImage = (address?: Omit<AddressData, "city"> & { city?: CityData | GeoCityData }): string => {
  const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

  const mapUrl = useMemo(() => {
    if (!address) return "";
    
    const fullAddress = `${address?.street || ""}, ${address?.number || ""}, ${
      address?.neighborhood || ""
    }, ${address?.city?.display_name || ""}, ${
      address?.city?.state?.display_name || ""
    }, ${address?.zipcode || ""}`;

    return `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
      fullAddress
    )}&zoom=16&size=800x800&markers=color:red%7C${encodeURIComponent(
      fullAddress
    )}&key=${apiKey}`;
  }, [address?.street,
    address?.neighborhood,
    address?.number,
    address?.city?.display_name,
    address?.city?.state?.display_name,
    address?.zipcode,
    apiKey]);

  return mapUrl;
};

export default useAddressMapImage;
