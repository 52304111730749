import Button from '@/common/components/Button'
import { MultipleDocumentUploader } from '@/common/components/MultipleDocumentUploader'
import TextArea from '@/common/components/TextArea'
import useBlobs from '@/common/hooks/queries/useBlobs'
import { FormTransformers } from '@/common/utils/formTransformers'
import { useCloseSupportTicket } from '@/features/support/hooks/useCloseSupportTicket'
import { useCreateSupportTicketMessage } from '@/features/support/hooks/useCreateSupportTicketMessage'
import {
  supportTicketMessageSchema,
  SupportTicketMessageValidationSchema,
} from '@/features/support/schemas/create.schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

interface FormMessageProps {
  supportTicketId?: string
}

export const FormMessage = ({ supportTicketId }: FormMessageProps) => {
  const queryClient = useQueryClient()

  const [showFormMessage, setShowFormMessage] = useState(false)
  const [baseError, setBaseError] = useState<string[]>([])

  const {
    control,
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<SupportTicketMessageValidationSchema>({
    resolver: zodResolver(supportTicketMessageSchema),
  })

  const { mutateAsync: uploadBlob, isPending: uploadBlobIsPending } = useBlobs()

  const { mutate: closeSupportTicket, isPending: closeSupportTicketIsPending } = useCloseSupportTicket({
    supportTicketId,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['supportTicket', supportTicketId] })
    },
  })

  const { mutate: createSupportTicket, isPending: createSupportTicketIsPending } = useCreateSupportTicketMessage({
    supportTicketId,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['supportTicket', supportTicketId] })
      queryClient.invalidateQueries({ queryKey: ['supportTicketMessages'] })
      closeForm()
    },
    onError: (error) => {
      if (error?.response?.data?.errors?.base?.length) {
        setBaseError(error.response.data.errors.base)
        return
      }

      FormTransformers.errorsTransformer<SupportTicketMessageValidationSchema>({
        setError,
      })(error)
    },
  })

  const onSubmit: SubmitHandler<SupportTicketMessageValidationSchema> = (data) => {
    createSupportTicket(data)
  }

  const closeForm = useCallback(() => {
    setShowFormMessage(false)
    reset()
  }, [])

  return (
    <>
      {showFormMessage ? (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          {baseError?.map((error) => (
            <p className="text-sm text-danger-soft">{error}</p>
          ))}

          <TextArea rows={16} maxLength={2048} errorMessage={errors.content?.message} {...register('content')} />

          <MultipleDocumentUploader
            control={control}
            name="documents"
            uploadBlob={uploadBlob}
            fieldName="documents"
            accept=".png, .jpg, .jpeg, .webp, .pdf, .doc, .docx, .xls, .xlsx"
            disabled={createSupportTicketIsPending || uploadBlobIsPending}
          />

          <div className="flex gap-2 self-end">
            <Button
              type="button"
              variant="outline-error"
              onClick={closeForm}
            >
              Cancelar
            </Button>

            <Button
              type="submit"
              disabled={createSupportTicketIsPending || uploadBlobIsPending}
              isLoading={createSupportTicketIsPending}
            >
              Enviar
            </Button>
          </div>
        </form>
      ) : (
        <div className="flex gap-2 self-end">
          <Button variant="outline-success" onClick={() => closeSupportTicket()} disabled={closeSupportTicketIsPending}>
            Marcar como concluído
          </Button>
          <Button onClick={() => setShowFormMessage(true)}>Responder</Button>
        </div>
      )}
    </>
  )
}
