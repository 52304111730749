import useAddressMapImage from "@/common/hooks/useAddressMapImage";
import { AddressData, CityData } from "@/common/types/address";
import { GeoCityData } from "@/common/types/geoGeoable";

export interface AddressMapImageProps {
    address?: Omit<AddressData, "city"> & { city?: CityData | GeoCityData };
  }

const AddressMapImage = ({ address }: AddressMapImageProps) => {
  const mapUrl = useAddressMapImage(address);

  const showMap =
    (address?.street ||
      address?.neighborhood ||
      address?.city?.display_name ||
      address?.city?.state?.display_name ||
      address?.zipcode) &&
    mapUrl;

  return (
    <>
      {showMap && (
        <div className="flex items-start w-1/2">
          <div className="w-full rounded-sm h-[400px] bg-white-100">
            <img
              src={mapUrl}
              alt="Address Image Map"
              className="w-full h-full object-cover rounded-sm"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AddressMapImage;
