import useLogRequest from "@/common/hooks/queries/useLogRequest";
import { useParams } from "react-router-dom";
import RequestResume from "./components/RequestResume";
import RequestChanges from "./components/RequestChanges";
import RequestResumeLoading from "./components/RequestResumeLoading";

const LogRequestShow = () => {
  const { requestId } = useParams();

  const { data: request, isPending } = useLogRequest(requestId);

  return (
    <div className="flex flex-col gap-6 h-full">
      {isPending && <RequestResumeLoading />}
      {request && <RequestResume request={request} />}

      {request?.log_changes && request?.log_changes?.length > 0 && <RequestChanges request={request} />}
    </div>
  );
};

export default LogRequestShow;