import Button from "@/common/components/Button"
import Modal from "@/common/components/Modal"
import { useCreateClientUserCard } from "@/features/users/hooks/userCreateClientUserCard"
import { useQueryClient } from "@tanstack/react-query"

interface CreateClientUserCardProps {
  clientUserId?: string
}

export const CreateClientUserCard = ({ clientUserId }: CreateClientUserCardProps) => {
  const queryClient = useQueryClient()

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['clientUser', clientUserId] })
  }

  const {
    mutate: createClientUserCard,
    isPending: createClientUserCardIsPending,
  } = useCreateClientUserCard({
    clientUserId: clientUserId,
    onSuccess,
  })

  return (
    <Modal
      Trigger={(
        <Button type="button">
          Solicitar cartão personal
        </Button>
      )}
      title="Você tem certeza?"
      subtitle="Ao solicitar o Cartão Personal será enviada uma ordem de emissão para a Tcard"
      showClose={false}
      useCancellableFooter
      confirmButtonText="Solicitar cartão"
      confirmButtonClassName="bg-blue-700"
      disabled={createClientUserCardIsPending}
      onConfirm={createClientUserCard}
    />
  )
}
