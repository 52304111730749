import MaskedTextField from "@/common/components/MaskedTextField";
import { customUnitMask } from "@/common/constants/masks.constant";
import { RestrictionData } from "@/features/company/types/company.type";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { CompanyBranchValidationSchema } from "../../schemas/companyBranch.schema";

interface ConsumptionRestrictionProps {
  restriction?: RestrictionData;
  register: UseFormRegister<CompanyBranchValidationSchema>;
  errors: FieldErrors<CompanyBranchValidationSchema>;
  disabled?: boolean;
}

export const ConsumptionRestriction = ({
  restriction,
  register,
  errors,
  disabled,
}: ConsumptionRestrictionProps) => {
  return (
    <div className="flex flex-col gap-4">
      <p className="font-bold text-md">Restrição de consumo</p>

      <MaskedTextField
        mask={customUnitMask('H', 9999, false)}
        label="Intervalo de tempo mínimo entre abastecimentos"
        {...register('minimum_seconds_interval_between_consumptions')}
        defaultValue={
          Number(restriction?.minimum_seconds_interval_between_consumptions)
            ? Number(restriction?.minimum_seconds_interval_between_consumptions) / 3600
            : ''
        }
        errorMessage={errors?.minimum_seconds_interval_between_consumptions?.message || ' '}
        disabled={disabled}
      />

      <div className="flex gap-3">
        <MaskedTextField
          className="grow"
          mask={customUnitMask('km', 999999, false)}
          label="Intervalo de Quilometragem entre abastecimentos"
          {...register('minimum_kilometers_usage_between_consumptions')}
          defaultValue={restriction?.minimum_kilometers_usage_between_consumptions || ''}
          errorMessage={errors?.minimum_kilometers_usage_between_consumptions?.message || ' '}
          disabled={disabled}
        />

        <MaskedTextField
          className="grow"
          mask={customUnitMask('H', 9999, false)}
          label="Intervalo de Hora-Uso entre abastecimentos"
          {...register('minimum_seconds_usage_between_consumptions')}
          defaultValue={
            Number(restriction?.minimum_seconds_usage_between_consumptions)
              ? Number(restriction?.minimum_seconds_usage_between_consumptions) / 3600
              : ''
          }
          errorMessage={errors?.minimum_seconds_usage_between_consumptions?.message || ' '}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
