import { ArrowBigRight } from "lucide-react";
import { Link } from "react-router-dom";
import { formatDateTime } from "../utils/formatters";
import { LogRequest } from "../types/logs/request.type";

export class LogRequestsTransformer {
  static tableTransformer() {
    return (logRequests: LogRequest[]) => {
      return logRequests.map((log) => ({
        ...log,
        created_at: formatDateTime(new Date(log.created_at)),
        operation_description: log.operation_description,
        success: log.success ? 'Sucesso' : 'Falha',
        actions: (
          <Link to={log.id}>
            <ArrowBigRight />
          </Link>
        )
      }));
    };
  }
}
