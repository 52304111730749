import usePrivateAxios from "@/common/api/usePrivateAxios";
import { defaultTransformerResponse } from "@/common/constants/defaultTransformerResponse.constant";
import { Mapper } from "@/common/services/mapper";
import { MetaResponse } from "@/common/types/metaResponse";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

type UseBillingEventsProps<T> = {
  filters?: {
    search?: string;
    page?: number;
    clientCompanyId?: string;
    clientBranchId?: string;
    equipmentId?: string;
    authorId?: string;
    productId?: string;
    createdAtStartDate?: string;
    createdAtEndDate?: string;
  };
  mapper?: (data: any) => T,
};

export const useBillingEvents = <T,>({
  filters: {
    search = '',
    page,
    clientCompanyId,
    clientBranchId,
    equipmentId,
    authorId,
    productId,
    createdAtStartDate,
    createdAtEndDate
  } = {},
  mapper = Mapper.default<T>(),
}: UseBillingEventsProps<T>) => {
  const privateAxios = usePrivateAxios();

  const {
    data: { data, meta } = defaultTransformerResponse<T>(),
    ...rest
  } = useQuery({
    queryKey: [
      'billingEvents',
      search,
      page,
      clientCompanyId,
      clientBranchId,
      equipmentId,
      authorId,
      productId,
      createdAtStartDate,
      createdAtEndDate
    ],
    queryFn: () => {
      return privateAxios.get('/billing/events', {
        params: {
          search,
          page,
          'q[client_branch_id_eq]': clientBranchId,
          'q[client_company_id_eq]': clientCompanyId,
          'q[equipment_id_eq]': equipmentId,
          'q[author_id_eq]': authorId,
          'q[product_id_eq]': productId,
          'q[created_at_gteq]': createdAtStartDate,
          'q[created_at_lteq]': createdAtEndDate
        }
      });
    },
    select: (data: { data: { data: any[], meta: MetaResponse; }; }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      };
    },
    placeholderData: keepPreviousData,
  });

  return { data, meta, ...rest };
};
