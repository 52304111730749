import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ResetPasswordValidationSchema } from "../schemas/resetPassword.schema";
import usePrivateAxios from "@/common/api/usePrivateAxios";

interface useResetPasswordProps {
  onSuccess?: (response: any) => void;
  onError?: (error: AxiosError) => void;
}

const useResetPassword = ({ onSuccess, onError }: useResetPasswordProps) => {
  const privateAxios = usePrivateAxios();

  return useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: async (form: ResetPasswordValidationSchema) => {
      return await privateAxios.put('/users/update_password', {
        client_user: {
          password: form.password,
          password_confirmation: form.confirmPassword,
        },
      });
    },
    onSuccess: (data) => {
      onSuccess?.(data);
    },
    onError,
  });
};

export default useResetPassword;
