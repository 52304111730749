import * as React from "react";
import { DayPicker } from "react-day-picker";

import { cn } from "@/common/utils/cn";
import { buttonVariants } from "@/common/components/ui/button";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-wrap justify-between",
        month: "space-y-4 -mt-6",
        month_caption: "flex justify-center pt-1 relative items-center",
        caption_label: "font-medium",
        nav: "flex items-center justify-between w-full z-50",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
        ),
        weekdays: "flex items-center justify-between px-2",
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-9 w-9 p-0 font-normal aria-selected:opacity-100"
        ),
        outside: "bg-accent rounded-none",
        range_start: "bg-blue-500 rounded-s-md text-muted",
        range_end: "bg-blue-500 rounded-e-md text-muted",
        day_button: "w-full h-full",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle:
          "aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
        selected: "bg-blue-300 rounded-none",
        ...classNames,
      }}
      {...props}
    />
  );
}

Calendar.displayName = "Calendar";

export { Calendar };
