import { forwardRef } from "react";
import { VariantProps } from 'class-variance-authority';
import { Check } from "lucide-react";
import { checkboxVariants } from "./styles";
import { twMerge } from "tailwind-merge";

export interface CheckboxProps extends React.HTMLAttributes<HTMLInputElement>, VariantProps<typeof checkboxVariants> {
  label: string,
  name: string,
  value?: string;
  labelClassName?: string;
  boxClassName?: string;
  disabled?: boolean;
  errorMessage?: string;
  checked?: boolean;
  type?: "radio" | "checkbox";
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({
  label,
  name,
  id,
  labelClassName = '',
  boxClassName = '',
  disabled,
  errorMessage,
  ...props
}, ref) => {
  return (
    <label
      className={twMerge("relative flex cursor-pointer items-start gap-3", disabled && 'cursor-default')}
      htmlFor={id}
      data-ripple-dark="true"
      onClick={(e) => e.stopPropagation()}
    >
      <input
        id={id}
        name={name}
        type='checkbox'
        ref={ref}
        className="before:content[''] peer hidden"
        disabled={disabled}
        {...props}
      />
      <div className={twMerge("flex justify-center items-center text-transparent peer-checked:text-white-800  pointer-events-none h-5 w-5 border rounded-md bg-gray-disabled border-gray-disabled peer-checked:border-success peer-checked:bg-success shrink-0", boxClassName)}>
        <Check size='14px' />
      </div>
      <div className="flex flex-col items-start">
        <p className={twMerge(`text-sm`, labelClassName)}>{label}</p>
        {errorMessage && <p className="text-danger-soft text-sm mt-1">{errorMessage}</p>}
      </div>
    </label>
  );
});

export default Checkbox;
