import Button from '@/common/components/Button'
import Checkbox from '@/common/components/Checkbox'
import { CurrencyTextField } from '@/common/components/CurrencyTextField'
import Modal from '@/common/components/Modal'
import SelectController from '@/common/components/SelectController'
import { formatCurrency } from '@/common/utils/formatters'
import { options as applicableProductsPeriodLimit } from '@/common/constants/applicableProductsPeriodLimit.constant'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ClientUserData } from '@/features/users/types/clientUsers'
import { updatePersonalCardLimitsSchema, UpdatePersonalCardLimitsValidationSchema } from '@/features/users/components/showUser/updatePersonalCardLimits/schema'
import { useUpdateClientUserPersonalCardLimits } from '@/features/users/hooks/useUpdateClientUserPersonalCardLimits'

type UpdatePersonalCardLimitsModalProps = {
  userId: string
  card?: ClientUserData['cards'][0]
}

const UpdatePersonalCardLimitsModal = ({ userId, card }: UpdatePersonalCardLimitsModalProps) => {
  const queryClient = useQueryClient()

  const [isOpen, setIsOpen] = useState(false)

  const { mutate: updateClientUserPersonalCardLimits, isPending: updateClientUserPersonalCardLimitsIsPending } =
    useUpdateClientUserPersonalCardLimits({
      userId: userId,
      cardId: card?.id,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['clientUser', userId] })
        setIsOpen(false)
      },
    })

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<UpdatePersonalCardLimitsValidationSchema>({
    resolver: zodResolver(updatePersonalCardLimitsSchema),
    defaultValues: {
      limitByTransaction: !!card?.limit_per_transaction,
      limitByPeriod: !!card?.limit_per_period,
      limit_per_transaction: card?.limit_per_transaction
        ? formatCurrency(parseFloat(card?.limit_per_transaction))
        : undefined,
      limit_period_in_days: card?.limit_period_in_days
        ? (applicableProductsPeriodLimit.find(
            (periodLimit) => periodLimit.value === `${card?.limit_period_in_days}`,
          ) as { label: string; value: '1' | '7' | '15' | '30' })
        : undefined,
      limit_per_period: card?.limit_per_period ? formatCurrency(parseFloat(card?.limit_per_period)) : undefined,
    },
  })

  const onSubmit = (form: UpdatePersonalCardLimitsValidationSchema) => {
    updateClientUserPersonalCardLimits(form)
  }

  const limitByTransaction = watch('limitByTransaction')
  const limitByPeriod = watch('limitByPeriod')

  return (
    <Modal
      isOpen={isOpen}
      Trigger={<Button>Editar limites</Button>}
      title="Editar limites"
      titleClass="text-[24px]"
      useCancellableFooter
      confirmButtonClassName="bg-blue-700"
      disabled={updateClientUserPersonalCardLimitsIsPending}
      subtitleClassName="mt-4"
      setIsOpen={setIsOpen}
      onConfirm={handleSubmit(onSubmit)}
      body={
        <form onSubmit={handleSubmit(onSubmit)} className="flex w-full flex-col gap-y-6">
          <div className="flex items-center gap-x-6">
            <Checkbox
              label="Limite por transação"
              labelClassName="text-[10px] italic font-light"
              {...register('limitByTransaction')}
              disabled={updateClientUserPersonalCardLimitsIsPending}
            />
            <Checkbox
              label="Limite por período"
              labelClassName="text-[10px] italic font-light"
              {...register('limitByPeriod')}
              disabled={updateClientUserPersonalCardLimitsIsPending}
            />
          </div>
          {limitByTransaction && (
            <div className="flex flex-col gap-y-4">
              <p>Limite por Transação</p>
              <Controller
                name="limit_per_transaction"
                control={control}
                disabled={updateClientUserPersonalCardLimitsIsPending}
                render={({ field }) => (
                  <CurrencyTextField
                    label="Valor total"
                    errorMessage={errors.limit_per_transaction?.message || undefined}
                    {...field}
                  />
                )}
              />
            </div>
          )}
          {limitByPeriod && (
            <div className="flex flex-col gap-y-4">
              <p>Limite por Período</p>
              <SelectController
                control={control}
                name={'limit_period_in_days'}
                label="Período"
                options={applicableProductsPeriodLimit}
                errorMessage={errors.limit_period_in_days?.message || undefined}
                isDisabled={updateClientUserPersonalCardLimitsIsPending}
              />
              <Controller
                name="limit_per_period"
                control={control}
                disabled={updateClientUserPersonalCardLimitsIsPending}
                render={({ field }) => (
                  <CurrencyTextField
                    label="Valor total"
                    errorMessage={errors.limit_per_period?.message || undefined}
                    {...field}
                  />
                )}
              />
            </div>
          )}
        </form>
      }
    />
  )
}

export default UpdatePersonalCardLimitsModal
