import Accordion from "@/common/components/Accordion";
import Checkbox from "@/common/components/Checkbox";
import TextField from "@/common/components/TextField";
import { twMerge } from "tailwind-merge";
import { RestrictionData } from "../../types/company.type";
import { weekDaysStrategy } from "../../../../common/constants/periodRestrictions.constant";

type PeriodRestrictionProps = {
  periodRestrictions?: RestrictionData['period_restrictions'];
};

export const PeriodRestriction = ({
  periodRestrictions
}: PeriodRestrictionProps) => {
  return (
    <Accordion
      contentClassName="overflow-hidden"
      head={(
        <div className="flex flex-col gap-2 ">
          <Checkbox
            label="Restrição de períodos (Abastecimento)"
            checked={!!periodRestrictions?.length}
            name="periodRestrictions"
            disabled
          />
        </div>
      )}
      body={(
        <div className="flex flex-col gap-4">
          {periodRestrictions?.map((periodRestriction) => (
            <div className="flex justify-between" key={periodRestriction.week_day}>
              <div className="flex flex-col gap-2">
                <p>{weekDaysStrategy[periodRestriction.week_day as keyof typeof weekDaysStrategy]}</p>
                <Checkbox
                  label="Não permitido"
                  name={`block-${periodRestriction.week_day}`}
                  boxClassName="w-[24px] h-[24px]"
                  labelClassName="text-[10px] italic font-light"
                  checked={!periodRestriction.intervals.length}
                  disabled
                />
              </div>
              {!!periodRestriction.intervals.length && (
                <div className="flex flex-col gap-2 items-start">
                  {periodRestriction?.intervals?.map((interval) => (
                    <div className="flex gap-2" key={interval.id}>
                      <TextField
                        label="Horário permitido (Início)"
                        className={twMerge("w-[182px]", (interval.start_time === '00:00' && interval.end_time === '23:59') && 'w-[372px]')}
                        value={(interval.start_time === '00:00' && interval.end_time === '23:59') ? '24 horas' : interval.start_time}
                        disabled
                      />
                      <TextField
                        label="Horário permitido (Fim)"
                        className={twMerge("w-[182px]", (interval.start_time === '00:00' && interval.end_time === '23:59') && 'hidden')}
                        value={(interval.start_time === '00:00' && interval.end_time === '23:59') ? undefined : interval.end_time}
                        disabled
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    />
  );
};
