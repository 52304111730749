import ListHeader from "@/common/components/ListHeader";
import Table from "@/common/components/Table";
import { DatePickerWithRange } from "@/common/components/ui/dateRangePicker";
import useDebounce from "@/common/hooks/useDebounce";
import { Receipt, SearchIcon } from "lucide-react";
import { useBillingEvents } from "@/common/hooks/queries/useBillingEvents";
import { BillingEventData, BillingEventTableItem } from "@/common/types/billing/event.type";
import { Mapper } from "@/common/services/mapper";
import { BillingEventTransformer } from "../../services/billingEventTransformer";
import billingEventsTableColumns from "../../constants/billingEventsTableColumns.constant";
import TextField from "@/common/components/TextField";
import ListFilters from "@/common/components/ListFilters";
import { useProducts } from "@/common/hooks/queries/useProducts";
import { ProductData } from "@/common/types/product.type";
import { useFilterParams } from "@/common/hooks/useFilterParams";
import { useFilterDate } from "@/common/hooks/useFilterDate";
import { useClientUsers } from "@/features/users/hooks/useClientUsers";
import { ClientUserData } from "@/features/users/types/clientUsers";

const BillingEventsList = () => {
  const { filterParams, setFilterParams } = useFilterParams();

  const { date, formattedDate } = useFilterDate(filterParams);

  const [searchProduct, setSearchProduct] = useDebounce('');
  const [searchUser, setSearchUser] = useDebounce('');

  const { data: productsOptions } = useProducts({
    filters: { search: searchProduct },
    mapper: Mapper.mapToOptions<ProductData>({ valueFieldName: 'id', labelFieldName: 'display_name' }),
  });

  const { data: authorOptions } = useClientUsers({
    filters: { search: searchUser },
    mapper: Mapper.mapToOptions<ClientUserData>({ valueFieldName: 'id', labelFieldName: 'full_name' }),
  });

  const { data: billingEvents, meta, isPending } = useBillingEvents({
    filters: {
      page: filterParams?.page || 1,
      search: filterParams?.search || '',
      createdAtStartDate: formattedDate.from,
      createdAtEndDate: formattedDate.to,
      productId: filterParams?.product?.value || '',
      authorId: filterParams?.author?.value || '',
    },
    mapper: Mapper.mapWithTransformer<BillingEventData[], BillingEventTableItem[]>({
      transformer: BillingEventTransformer.tableTransformer(),
    })
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex">
        <ListHeader
          Icon={<Receipt className="w-10 h-10" strokeWidth={1.5} />}
          title="Transações"
        />
      </div>

      <div className="h-[calc((100vh-104px-1.5rem))] flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <TextField
            LeftIcon={<SearchIcon />}
            placeholder="Pesquisar"
            className="rounded-sm shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.5)]"
            onChange={(e: any) => setFilterParams((params) => ({ ...params, search: e.target.value }))}
            defaultValue={filterParams?.search}
          />

          <div className="flex items-center gap-2">
            <DatePickerWithRange
              date={date}
              setDate={(date) => setFilterParams((params) => ({ ...params, date }))}
            />

            <ListFilters
              filters={[
                {
                  className: "z-50",
                  onChange: (e: any) => setFilterParams((params) => ({ ...params, product: e.value ? e : null })),
                  defaultValue: filterParams?.product,
                  onInputChange: setSearchProduct,
                  options: [{ label: "Todas", value: '' }, ...(productsOptions || [])],
                  placeholder: "Produto",
                },
                {
                  className: "z-50",
                  onChange: (e: any) => setFilterParams((params) => ({ ...params, author: e.value ? e : null })),
                  defaultValue: filterParams?.author,
                  onInputChange: setSearchUser,
                  options: [{ label: "Todas", value: '' }, ...(authorOptions || [])],
                  placeholder: "Autor",
                },
              ]}
            />
          </div>
        </div>

        <Table
          headerClassName="z-30"
          columns={billingEventsTableColumns}
          isPendingData={isPending}
          rows={billingEvents}
          rowEmptyValue="--"
          hasPagination
          page={filterParams.page || 1}
          setPage={(p) => setFilterParams((params) => ({ ...params, page: p }))}
          totalPages={meta.total_pages}
          totalCount={meta.total}
        />
      </div>
    </div>
  );
};

export default BillingEventsList;
