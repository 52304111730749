import Accordion from '@/common/components/Accordion';
import TextField from '@/common/components/TextField';
import { CompanyContractData } from '../../types/contract.type';

interface EquipmentsDetailsProps {
  equipments?: CompanyContractData['contract_equipment_categories'];
}

export const EquipmentsDetails = ({ equipments }: EquipmentsDetailsProps) => {
  return (
    <Accordion
      head={<p>Equipamentos</p>}
      body={
        <div className="flex flex-col gap-4">
          {equipments?.map(({ id, equipment_category, quantity }) => (
            <div key={id} className="flex gap-2">
              <TextField label="Categoria" className="w-1/2" value={equipment_category.display_name} disabled />
              <TextField label="Quantidade" className="w-1/2" value={`${quantity}`} disabled />
            </div>
          ))}
          <div className="flex flex-col items-end gap-1">
            <p className="font-light text-xs italic leading-3">Total de equipamentos</p>
            <p className="font-bold leading-5">{equipments?.reduce((acc, { quantity }) => acc + quantity, 0)}</p>
          </div>
        </div>
      }
    />
  );
};
