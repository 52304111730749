import { ImageWithPlaceholder } from '@/common/components/ImageWithPlaceholder'
import { TicketData } from '@/common/types/support/ticket.type'
import { BriefcaseBusinessIcon, UserRoundIcon } from 'lucide-react'

type SupportTicketCreatorProps = {
  creator: TicketData['creator']
}

export const SupportTicketCreator = ({ creator }: SupportTicketCreatorProps) => {
  return (
    <div className="flex gap-2 items-center">
      <ImageWithPlaceholder
        url={creator?.profile_picture?.url}
        alt={creator?.full_name}
        placeholderIcon={UserRoundIcon}
        className="size-6"
        iconClassName="size-4"
      />

      <p className="text-xs">{creator?.full_name}</p>

      <div className="flex gap-1 items-center">
        <ImageWithPlaceholder
          url={creator?.branch?.general_information?.company_logo?.url}
          alt={creator?.branch?.display_name}
          placeholderIcon={BriefcaseBusinessIcon}
        />
        <p className="text-[10px] font-light italic">{creator?.branch?.display_name}</p>
      </div>
    </div>
  )
}
