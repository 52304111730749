import TextField from "@/common/components/TextField";
import { formatUnit } from "@/common/utils/formatters";
import { RestrictionData } from "../../types/company.type";

export const ConsumptionRestriction = (
  {
    restriction,
  }: {
    restriction?: RestrictionData;
  }
) => {
  return (
    <div className="flex flex-col gap-4">
      <p className="font-bold text-md">Restrição de consumo</p>

      <TextField
        label="Intervalo de tempo mínimo entre abastecimentos"
        placeholder="Não informado"
        value={
          Number(restriction?.minimum_seconds_interval_between_consumptions)
            ? formatUnit(Number(restriction?.minimum_seconds_interval_between_consumptions) / 3600, 'hours')
            : ''
        }
        disabled
      />

      <div className="flex gap-3">
        <TextField
          className="grow"
          label="Intervalo de Quilometragem entre abastecimentos"
          placeholder="Não informado"
          value={
            restriction?.minimum_kilometers_usage_between_consumptions
              ? formatUnit(restriction?.minimum_kilometers_usage_between_consumptions, 'kilometers')
              : ''
          }
          disabled
        />

        <TextField
          className="grow"
          label="Intervalo de Hora-Uso entre abastecimentos"
          placeholder="Não informado"
          value={
            Number(restriction?.minimum_seconds_usage_between_consumptions)
              ? formatUnit(Number(restriction?.minimum_seconds_usage_between_consumptions) / 3600, 'hours')
              : ''
          }
          disabled
        />
      </div>
    </div>
  );
};
