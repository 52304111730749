export default (data: any) => {
  return [
    {
      label: "Consumido",
      value: data.consumed_total,
      textClassName: "text-yellow-400",
    },
    {
      label: "Total em descontos",
      value: data.discount_total,
    },
    {
      label: "Total em tarifas",
      value: data.fees_total,
    },
  ];
};
