import usePrivateAxios from "@/common/api/usePrivateAxios";
import { BillingEventData, BillingEventResponse } from "@/common/types/billing/event.type";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

type useBillingEventProps = {
  id?: string;
  enabled: boolean;
};

type useBillingEventType = ({ id, enabled }: useBillingEventProps) => UseQueryResult<BillingEventData, unknown>;

const useBillingEvent: useBillingEventType = ({ id, enabled }) => {
  const privateAxios = usePrivateAxios();

  return useQuery<BillingEventResponse, unknown, BillingEventData>({
    enabled,
    queryKey: ['billingEvent', id],
    queryFn: () => {
      return privateAxios.get(`/billing/events/${id}`);
    },
    select: (data) => data?.data?.data,
  });
};

export default useBillingEvent;
