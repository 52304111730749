import usePrivateAxios from "@/common/api/usePrivateAxios";
import { unFormatCnpj as unFormatMask, unFormatCurrency, unFormatUnit } from "@/common/utils/unFormatters";
import { useMutation } from "@tanstack/react-query";
import { CompanyBranchValidationSchema } from "../schemas/companyBranch.schema";
import { AxiosError, AxiosResponse } from "axios";

interface UseCreateCompanyBranch {
  companyId?: string,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError) => void,
}

const useCreateCompanyBranch = ({
  companyId,
  onSuccess,
  onError
}: UseCreateCompanyBranch) => {
  const privateAxios = usePrivateAxios();
  return useMutation({
    mutationKey: ['createCompanyBranch'],
    mutationFn: async ({ form, file }: { form: CompanyBranchValidationSchema, file?: File }) => {
      const payload = {
        company_id: companyId,
        display_name: form.display_name,
        parent_id: form.parent_id?.value,
        master_client_user_id: form.master_client_user_id?.value,
        general_information_attributes: form.general_information_attributes?.willUseOwnData
          ? {
            ...form.general_information_attributes,
            address_attributes: {
              ...form.general_information_attributes?.address_attributes,
              city_id: form.general_information_attributes?.address_attributes?.city_id
            },
            company_logo: file || undefined,
          } : undefined,
        restriction_attributes: {
          period_restrictions_attributes: form.enablePeriodRestriction
            ? form.period_restrictions_attributes?.map((periodRestriction) => {
              return {
                id: periodRestriction.id,
                week_day: periodRestriction.week_day,
                intervals_attributes: [
                  ...(() => {
                    const newIntervals = [] as { start_time?: string | null, end_time?: string | null; }[];

                    if (periodRestriction.blocked) {
                      return [];
                    }

                    if (periodRestriction.intervals_attributes?.some((interval) => interval.start_time?.value === 'all_day')) {
                      newIntervals.push({ start_time: '00:00', end_time: '23:59' });
                      return newIntervals;
                    }

                    periodRestriction?.intervals_attributes?.forEach((interval) => {
                      newIntervals.push({ start_time: interval.start_time?.value, end_time: interval.end_time?.value });
                    });
                    return newIntervals;
                  })(),
                ]
              };
            })
            : undefined,
          minimum_kilometers_usage_between_consumptions:
            form.minimum_kilometers_usage_between_consumptions
              ? unFormatUnit(form.minimum_kilometers_usage_between_consumptions, 'km')
              : null,
          minimum_seconds_usage_between_consumptions:
            form.minimum_seconds_usage_between_consumptions
              ? Number(unFormatMask(form.minimum_seconds_usage_between_consumptions)) * 3600
              : null,
          minimum_seconds_interval_between_consumptions:
            form.minimum_seconds_interval_between_consumptions
              ? Number(unFormatMask(form.minimum_seconds_interval_between_consumptions)) * 3600
              : null,
        },
        granted_credit: form.granted_credit ? unFormatCurrency(form.granted_credit) : 0,
        product_category_granted_credits_attributes: form.enableCreditConfiguration ? form.product_category_granted_credits_attributes.map((category) => {
          return {
            ...category,
            granted_credit: unFormatCurrency(category.granted_credit),
            product_granted_credits_attributes: category?.product_granted_credits_attributes?.map((product) => {
              return {
                ...product,
                granted_credit: unFormatCurrency(product.granted_credit),
              };
            })
          };
        }) : null,
      };

      return privateAxios.postForm(`/company_branches`, { company_branch: payload });
    },
    onSuccess,
    onError,
  });
};

export default useCreateCompanyBranch;
