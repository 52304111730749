import { unFormatCurrency, unFormatUnit } from "@/common/utils/unFormatters"
import { options as unitForQuantity } from "@/common/constants/unitForQuantityOptions.constant";
import { CardProductLimitValidationSchema } from "../schemas/create.schema";

export const transformNewApplicableProducts = (applicableProduct: CardProductLimitValidationSchema) => ({
  product_id: applicableProduct.product_id,
  quantity_limit_per_transaction: (applicableProduct.limitByTransaction && applicableProduct.transactionLimit?.quantity && unFormatUnit(applicableProduct.transactionLimit?.quantity, unitForQuantity.find((option) => option.value === applicableProduct.unit_for_quantity)?.symbol || '') > 0)
    ? unFormatUnit(applicableProduct.transactionLimit?.quantity , unitForQuantity.find((option) => option.value === applicableProduct.unit_for_quantity)?.symbol || '')
    : null,
  credit_limit_per_transaction: (applicableProduct.limitByTransaction && applicableProduct.transactionLimit?.totalValue)
    ? unFormatCurrency(applicableProduct.transactionLimit?.totalValue)
    : null,
  period_in_days: applicableProduct.limitByPeriod ? applicableProduct.periodLimit?.period?.value : null,
  quantity_limit_per_period: (applicableProduct.limitByPeriod && applicableProduct.periodLimit?.quantity && unFormatUnit(applicableProduct.periodLimit?.quantity, unitForQuantity.find((option) => option.value === applicableProduct.unit_for_quantity)?.symbol || '') > 0)
    ? unFormatUnit(applicableProduct.periodLimit?.quantity, unitForQuantity.find((option) => option.value === applicableProduct.unit_for_quantity)?.symbol || '')
    : null,
  credit_limit_per_period: (applicableProduct.limitByPeriod && applicableProduct.periodLimit?.totalValue)
    ? unFormatCurrency(applicableProduct.periodLimit?.totalValue)
    : null,
})

export const transformUpdatedApplicableProducts = (applicableProduct: CardProductLimitValidationSchema) => ({
  id: applicableProduct.id,
  quantity_limit_per_transaction: (applicableProduct.limitByTransaction && applicableProduct.transactionLimit?.quantity && unFormatUnit(applicableProduct.transactionLimit?.quantity, unitForQuantity.find((option) => option.value === applicableProduct.unit_for_quantity)?.symbol || '') > 0)
    ? unFormatUnit(applicableProduct.transactionLimit?.quantity , unitForQuantity.find((option) => option.value === applicableProduct.unit_for_quantity)?.symbol || '')
    : null,
  credit_limit_per_transaction: (applicableProduct.limitByTransaction && applicableProduct.transactionLimit?.totalValue)
    ? unFormatCurrency(applicableProduct.transactionLimit?.totalValue)
    : null,
  period_in_days: applicableProduct.limitByPeriod ? applicableProduct.periodLimit?.period?.value : null,
  quantity_limit_per_period: (applicableProduct.limitByPeriod && applicableProduct.periodLimit?.quantity && unFormatUnit(applicableProduct.periodLimit?.quantity, unitForQuantity.find((option) => option.value === applicableProduct.unit_for_quantity)?.symbol || '') > 0)
    ? unFormatUnit(applicableProduct.periodLimit?.quantity, unitForQuantity.find((option) => option.value === applicableProduct.unit_for_quantity)?.symbol || '')
    : null,
  credit_limit_per_period: (applicableProduct.limitByPeriod && applicableProduct.periodLimit?.totalValue)
    ? unFormatCurrency(applicableProduct.periodLimit?.totalValue)
    : null,
})
