import { useMutation } from "@tanstack/react-query";
import usePrivateAxios from "@/common/api/usePrivateAxios";
import { unFormatCurrency, unFormatUnit, unFormatUnknownUnit } from "@/common/utils/unFormatters";
import { unitStrategy } from "@/common/constants/unitStrategies.contants";
import { EquipmentValidationSchema } from "../schemas/create.schema";
import { options as unitForQuantity } from "@/common/constants/unitForQuantityOptions.constant";

interface UseCreateCompanyEquipment {
  onSuccess: () => void,
  onError: (error: any) => void,
}

const useCreateEquipment = ({ onSuccess, onError }: UseCreateCompanyEquipment) => {
  const privateAxios = usePrivateAxios();

  return useMutation({
    mutationKey: ['createEquipment'],
    mutationFn: async (form: EquipmentValidationSchema) => {
      const payload = {
        photos_attachments_attributes: form.photos?.length ? form.photos.map((photo) => ({ blob_id: photo.id })) : undefined,
        status: form.status.value,
        branch_id: form.branch_id.value,
        card_attributes: {
          card_product_limits_attributes: form.card_product_limits_attributes
            ? form.card_product_limits_attributes?.map((cardProductLimit) => {
              return {
                product_id: cardProductLimit.product_id,
                quantity_limit_per_transaction: (cardProductLimit.limitByTransaction && cardProductLimit.transactionLimit?.quantity && unFormatUnknownUnit(cardProductLimit.transactionLimit?.quantity) > 0)
                  ? unFormatUnit(cardProductLimit.transactionLimit?.quantity, unitForQuantity.find((option) => option.value === cardProductLimit.unit_for_quantity)?.symbol || '')
                  : undefined,
                credit_limit_per_transaction: (cardProductLimit.limitByTransaction && cardProductLimit.transactionLimit?.totalValue)
                  ? unFormatCurrency(cardProductLimit.transactionLimit?.totalValue)
                  : undefined,
                period_in_days: cardProductLimit.limitByPeriod ? cardProductLimit.periodLimit?.period?.value : undefined,
                quantity_limit_per_period: (cardProductLimit.limitByPeriod && cardProductLimit.periodLimit?.quantity && unFormatUnknownUnit(cardProductLimit.periodLimit?.quantity) > 0)
                  ? unFormatUnit(cardProductLimit.periodLimit?.quantity, unitForQuantity.find((option) => option.value === cardProductLimit.unit_for_quantity)?.symbol || '')
                  : undefined,
                credit_limit_per_period: (cardProductLimit.limitByPeriod && cardProductLimit.periodLimit?.totalValue)
                  ? unFormatCurrency(cardProductLimit.periodLimit?.totalValue)
                  : undefined,
              };
            })
            : undefined,
          restriction_attributes: {
            period_restrictions_attributes: form.enablePeriodRestriction
              ? form.period_restrictions_attributes?.map((periodRestriction) => {
                return {
                  id: periodRestriction.id,
                  week_day: periodRestriction.week_day,
                  intervals_attributes: [
                    ...(() => {
                      const newIntervals = [] as { start_time?: string | null, end_time?: string | null; }[];

                      if (periodRestriction.blocked) {
                        return [];
                      }

                      if (periodRestriction.intervals_attributes?.some((interval) => interval.start_time?.value === 'all_day')) {
                        newIntervals.push({ start_time: '00:00', end_time: '23:59' });
                        return newIntervals;
                      }

                      periodRestriction?.intervals_attributes?.forEach((interval) => {
                        newIntervals.push({ start_time: interval.start_time?.value, end_time: interval.end_time?.value });
                      });
                      return newIntervals;
                    })(),
                  ]
                };
              })
              : undefined,
          },
        },
        model_id: form.equipment_model.value,
        tank_capacity: form.tank_capacity ? unFormatUnknownUnit(form.tank_capacity) : undefined,
        last_usage_value: form.last_usage_value ? unFormatUnit(form.last_usage_value, form.equipment_model?.unitForConsumption ? unitStrategy[form.equipment_model?.unitForConsumption as keyof typeof unitStrategy] : '') : undefined,
        official_name: form.official_name,
        serial_number: form.serial_number,
        license_plate: form.license_plate,
        chassis_number: form.chassis_number,
        renavam: form.renavam,
        usage_kind: form.usage_kind.value,
        property_kind: form.property_kind?.value,
        property_code: form.property_code || undefined,
        documents_attachments_attributes: form.documents?.length ? form.documents.map((document) => ({ blob_id: document.id })) : undefined,
      };

      return privateAxios.post('/equipments', { equipment: payload });
    },
    onSuccess,
    onError,
  });
};

export default useCreateEquipment;
