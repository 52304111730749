import Skeleton from "../../Skeleton";

const RequestResumeLoading = () => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-6">
        <div className="flex flex-col">
          <p className="font-light text-sm italic">Autor</p>

          <div className="flex items-center gap-2">
            <Skeleton circle className="size-8 bg-white-100" />

            <div className="flex flex-col gap-2">
              <Skeleton className="w-32 bg-white-100" />
              <Skeleton className="w-24 h-4 bg-white-100" />
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <p className="font-light text-sm italic">ID da requisição</p>
          <Skeleton className="w-72 bg-white-100" />
        </div>

        <div className="flex flex-col">
          <p className="font-light text-sm italic">Data e hora</p>
          <Skeleton className="w-40 bg-white-100" />
        </div>
      </div>

      <div className="flex gap-6">
        <div className="flex flex-col">
          <p className="font-light text-sm italic">Ação</p>
          <Skeleton className="w-56 bg-white-100" />
        </div>

        <div className="flex flex-col">
          <p className="font-light text-sm italic">Resultado</p>
          <Skeleton className="w-16 bg-white-100" />
        </div>

        <div className="flex flex-col">
          <p className="font-light text-sm italic">Navegador utilizado</p>
          <Skeleton className="w-24 bg-white-100" />
        </div>

        <div className="flex flex-col">
          <p className="font-light text-sm italic">IP</p>
          <Skeleton className="w-28 bg-white-100" />
        </div>
      </div>
    </div>
  );
};

export default RequestResumeLoading;
