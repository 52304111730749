import Accordion from "@/common/components/Accordion";
import Checkbox from "@/common/components/Checkbox";
import { PeriodRestrictionInfo } from "@/features/equipments/components/form/PeriodRestrictionInfo";
import { Control, FieldErrors, useFieldArray, UseFormRegister, UseFormWatch } from "react-hook-form";
import { PeriodRestrictionIntervals } from "./PeriodRestrictionIntervals";
import { weekDaysStrategy } from "@/common/constants/periodRestrictions.constant";
import { CompanyBranchValidationSchema } from "../../schemas/companyBranch.schema";

interface PeriodRestrictionProps {
  control: Control<CompanyBranchValidationSchema, any>;
  register: UseFormRegister<CompanyBranchValidationSchema>;
  watch: UseFormWatch<CompanyBranchValidationSchema>;
  errors: FieldErrors<CompanyBranchValidationSchema>;
  disabled?: boolean;
}

export const PeriodRestriction = ({
  control,
  register,
  watch,
  errors,
  disabled,
}: PeriodRestrictionProps) => {
  const { fields: periodRestrictionFields } = useFieldArray({
    control,
    name: `period_restrictions_attributes`,
  });

  return (
    <Accordion
      contentClassName="overflow-hidden"
      head={(
        <div className="flex flex-col gap-2 ">
          {errors.enablePeriodRestriction && (
            <p className="text-sm text-danger-soft">
              {errors.enablePeriodRestriction.message}
            </p>
          )}
          <Checkbox
            label="Restrição de períodos (Abastecimento)"
            onClick={(event) => event.stopPropagation()}
            {...register('enablePeriodRestriction')}
          />
        </div>
      )}
      body={(
        <div className="flex flex-col gap-4 mb-[100px]">
          <PeriodRestrictionInfo />

          {periodRestrictionFields.map((periodRestriction, periodRestrictionIndex) => (
            <div className="flex justify-between" key={periodRestriction.week_day}>
              <div className="flex flex-col gap-2">
                <p>{weekDaysStrategy[periodRestriction.week_day as keyof typeof weekDaysStrategy]}</p>
                <Checkbox
                  label="Não permitido"
                  boxClassName="w-[24px] h-[24px]"
                  labelClassName="text-[10px] italic font-light"
                  {...register(`period_restrictions_attributes.${periodRestrictionIndex}.blocked`)}
                />
              </div>
              {!watch(`period_restrictions_attributes.${periodRestrictionIndex}.blocked`) && (
                <div className="flex flex-col gap-2 items-start">
                  <PeriodRestrictionIntervals
                    periodRestrictionIndex={periodRestrictionIndex}
                    control={control}
                    watch={watch}
                    errors={errors}
                    disabled={disabled}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    />
  );
};
