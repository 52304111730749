import { mapStyles } from "./style";

export const brasiliaCoordinates = {lat: -15.793889, lng: -47.882778}

export const loaderOptions = {
    id: 'google-map-script',
    googleMapsApiKey: `${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`
  }

export const earthBounds = {
    north: 85, 
    south: -85,
    west: -180,
    east: 180,
};

export const mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    zoomControlOptions: null,
    styles: mapStyles,
    minZoom: 3,
    maxZoom: 19,
    restriction: {
      latLngBounds: earthBounds,
      strictBounds: true,
    },
}

