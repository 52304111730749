import { twMerge } from 'tailwind-merge';

interface SkeletonProps {
  height?: number;
  width?: number | string;
  className?: string;
  circle?: boolean;
}

export const Skeleton = ({ height = 20, width = '100%', className, circle }: SkeletonProps) => {
  return (
    <div
      className={twMerge(
        'relative overflow-hidden bg-transparent',
        circle ? 'rounded-full' : 'rounded',
        className
      )}
      style={{ height, width: circle ? height : width }}
    >
      <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-transparent via-white-200 to-transparent animate-shimmer"></div>
    </div>
  );
};

export default Skeleton;
