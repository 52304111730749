import { LogRequest } from "@/common/types/logs/request.type";
import Box from "../../Box";
import Accordion from "../../Accordion";

const RequestChanges = ({ request }: { request: LogRequest; }) => {
  return (
    <Box className="flex flex-col gap-4 overflow-y-auto">
      <div className="grid grid-cols-2">
        <p className="text-lg text-white-800">Antes</p>
        <p className="text-lg text-white-800">Depois</p>
      </div>

      <div className="flex flex-col gap-2">
        {request.log_changes?.map((log) => (
          <Accordion
            key={log.id}
            className="w-full rounded-sm overflow-hidden p-0"
            triggerClassName="grid grid-cols-2 items-start text-start relative p-0"
            bodyClassName="pb-0"
            iconStyles="absolute top-6 right-4"
            head={(
              <>
                <div className="bg-[#FCCDCA33] p-4">
                  <p className="text-md text-white-700">
                    {log.item_name_translated}
                  </p>

                  <p className="text-xs text-white-300">
                    {log.item_id}
                  </p>
                </div>

                <div className="bg-[#BEEFC233] h-full" />
              </>
            )}
            body={(
              <>
                {Object.entries(log.object_changes_translated).map(([field, diff]) => (
                  <div className="grid grid-cols-2" key={`${log.id}${field}`}>
                    <div className="bg-[#FCCDCA33] p-4 flex flex-col gap-1">
                      <p className="text-xs">
                        {field.split('.').pop()}
                      </p>

                      <div className="w-full h-full bg-white-200 rounded-sm p-3">
                        {diff[0] || '--'}
                      </div>
                    </div>

                    <div className="bg-[#BEEFC233] p-4 flex flex-col gap-1">
                      <p className="text-xs">
                        {field.split('.').pop()}
                      </p>

                      <div className="w-full h-full bg-white-200 rounded-sm p-3">
                        {diff[1] || '--'}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          />
        ))}
      </div>
    </Box>
  );
};

export default RequestChanges;