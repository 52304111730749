import { useMemo } from "react";
import PaginationButton from "./paginationButton";

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const Pagination = ({ currentPage, totalPages, setPage }: PaginationProps) => {

  const pages = useMemo(() => {
    if (totalPages <= 5) {
      return Array(totalPages).fill(0).map((_, idx) => (
        <PaginationButton
          onClick={() => setPage(idx + 1)}
          disabled={currentPage === idx + 1}
          variant={currentPage === idx + 1 ? 'selected' : 'default'}
          key={idx}
        >
          {idx + 1}
        </PaginationButton>
      ));
    }
    if (currentPage < 3) {
      return (
        <>
          {Array(3).fill(0).map((_, idx) => (
            <PaginationButton
              onClick={() => setPage(idx + 1)}
              disabled={currentPage === idx + 1}
              variant={currentPage === idx + 1 ? 'selected' : 'default'}
              key={idx}
            >
              {idx + 1}
            </PaginationButton>
          ))}
          <PaginationButton disabled >....</PaginationButton>
          <PaginationButton
            onClick={() => setPage(totalPages)}
          >
            {totalPages}
          </PaginationButton>
        </>
      );
    }
    if (currentPage > totalPages - 2) {
      return (
        <>
          <PaginationButton
            onClick={() => setPage(1)}
          >
            {1}
          </PaginationButton>
          <PaginationButton disabled >....</PaginationButton>
          {Array(3).fill(0).map((_, idx) => (
            <PaginationButton
              onClick={() => setPage(idx + 1 + totalPages - 3)}
              disabled={currentPage === idx + 1 + totalPages - 3}
              variant={currentPage === idx + 1 + totalPages - 3 ? 'selected' : 'default'}
              key={idx}
            >
              {idx + 1 + totalPages - 3}
            </PaginationButton>
          ))}
        </>
      );
    }
    return (
      <>
        <PaginationButton
          onClick={() => setPage(1)}
        >
          {1}
        </PaginationButton>
        <PaginationButton disabled >....</PaginationButton>
        <PaginationButton onClick={() => setPage(currentPage - 1)} >{currentPage - 1}</PaginationButton>
        <PaginationButton variant='selected' onClick={() => setPage(currentPage)} >{currentPage}</PaginationButton>
        <PaginationButton onClick={() => setPage(currentPage + 1)} >{currentPage + 1}</PaginationButton>
        <PaginationButton disabled >....</PaginationButton>
        <PaginationButton
          onClick={() => setPage(totalPages)}
        >
          {totalPages}
        </PaginationButton>
      </>
    );
  }, [totalPages, currentPage, setPage]);

  return (
    <div className="flex gap-2" >
      {pages}
    </div>
  );
};
