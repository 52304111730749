import { formatCurrency, formatDecimal } from "@/common/utils/formatters";
import { FeeMovementData, ProductMovementData } from "../types/movementTotalizer.type";

export class MovementsTotalizerTransformer {
  static productsTableTransformer() {
    return (movements?: ProductMovementData[]) => {
      return movements?.map((movement) => ({
        product_display_name: movement.product_display_name,
        refunded_movements_count: movement.refunded_movements_count,
        refunded_movement_total_sum:
          movement.refunded_movement_total_sum && formatCurrency(parseFloat(movement.refunded_movement_total_sum)),
        movements_count: movement.movements_count,
        movement_total_sum: movement.movement_total_sum && formatCurrency(parseFloat(movement.movement_total_sum)),
        product_quantity_sum: movement.product_quantity_sum ? formatDecimal(movement.product_quantity_sum) : '',
        product_price_avg: movement.product_price_avg && formatCurrency(parseFloat(movement.product_price_avg)),
        final_movements_count: movement.movements_count - movement.refunded_movements_count,
        final_movement_total_sum:
          movement.movement_total_sum &&
          movement.refunded_movement_total_sum &&
          formatCurrency(parseFloat(movement.movement_total_sum) - parseFloat(movement.refunded_movement_total_sum)),
      }))
    }
  }

  static feesTableTransformer() {
    return (movements?: FeeMovementData[]) => {
      return movements?.map((movement) => {
        const finalMovementTotalSum =
          movement.movement_total_sum && movement.refunded_movement_total_sum
            ? parseFloat(movement.movement_total_sum) - parseFloat(movement.refunded_movement_total_sum)
            : 0

        return {
          fee_display_name: movement.fee_display_name,
          source_name: movement.source_name,
          movements_count: movement.movements_count,
          movement_total_sum: movement.movement_total_sum && formatCurrency(parseFloat(movement.movement_total_sum)),
          refunded_movements_count: movement.refunded_movements_count,
          refunded_movement_total_sum:
            movement.refunded_movement_total_sum && formatCurrency(parseFloat(movement.refunded_movement_total_sum)),
          final_movements_count: movement.movements_count - movement.refunded_movements_count,
          final_movement_total_sum: movement.movement_total_sum && movement.refunded_movement_total_sum && (
            <p className={finalMovementTotalSum >= 0 ? 'text-valid' : 'text-danger-soft'}>
              {formatCurrency(finalMovementTotalSum)}
            </p>
          ),
        }
      })
    }
  }
}
