import { optionsObjectToArray } from "@/common/utils/optionsObjectToArray";
import { optionsToStrategy } from "@/common/utils/optionsToStrategy";

export const optionsObject = {
  private_company: 'Privado',
  public_company: 'Público',
};

export const options = optionsObjectToArray(optionsObject);

export const strategy = optionsToStrategy(options);
