import { z } from "zod";

export const addressSchema = z.object({
  zipcode: z.string().optional().nullable(),
  street: z.string().optional().nullable(),
  number: z.string().optional().nullable(),
  neighborhood: z.string().optional().nullable(),
  city_id: z.string().optional().nullable(),
  complement: z.string().optional().nullable(),
  latitude: z.string().optional().nullable(),
  longitude: z.string().optional().nullable(),
});

export type AddressValidationSchema = z.infer<typeof addressSchema>;
