import { useMemo } from "react";
import { dateToISOStingTransformer } from "../utils/dateTransformer";

export const useFilterDate = (filterParams: any) => {
  const date = useMemo(() => ({
    from: filterParams?.date?.from ? new Date(filterParams?.date?.from) : undefined,
    to: filterParams?.date?.to ? new Date(filterParams?.date?.to) : undefined,
  }), [filterParams?.date?.from, filterParams?.date?.to]);

  const formattedDate = useMemo(() => ({
    from: dateToISOStingTransformer(date?.from),
    to: dateToISOStingTransformer(date?.to),
  }), [date?.from, date?.to]);

  return { date, formattedDate };
};
