import { CompanyBranchData, CompanyBranchTransformerToStructureResponse } from "@/common/types/companyBranches.type";

export class CompanyBranchesTransformer {
  static structureTransformer() {
    return (companyBranches: CompanyBranchData[]) => {
      const nodes = companyBranches.map((branch) => ({
        id: branch.id,
        data: { ...branch },
        type: 'custom',
        position: {
          x: 0,
          y: 0
        }
      }));

      const edges = companyBranches.filter((branch) => branch.parent_id !== null).map((branch) => ({
        id: `${branch.id}-${branch.parent_id}`,
        source: branch.parent_id,
        target: branch.id
      }));

      return { nodes, edges } as CompanyBranchTransformerToStructureResponse;
    };
  }
}
