import { ImageWithPlaceholder } from '@/common/components/ImageWithPlaceholder'
import { TicketData } from '@/common/types/support/ticket.type'
import { cn } from '@/common/utils/cn'
import { strategy as statusStrategy } from '@/features/support/constants/statusOptions.constant'
import { UserRoundIcon } from 'lucide-react'

type SupportTicketStatusProps = {
  status: TicketData['status']
  responsible?: TicketData['responsible']
}

export const SupportTicketStatus = ({ status, responsible }: SupportTicketStatusProps) => {
  return (
    <div className="flex gap-2 items-center">
      {status === 'closed' && !!responsible && (
        <ImageWithPlaceholder
          url={responsible.profile_picture?.url}
          alt={responsible.full_name}
          placeholderIcon={UserRoundIcon}
          className="size-6"
          iconClassName="size-4"
        />
      )}

      <p className={cn(status === 'pending' && 'text-danger-soft')}>
        {statusStrategy[status as keyof typeof statusStrategy]}
      </p>
    </div>
  )
}
