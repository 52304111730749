import { optionSchema } from '@/common/schemas/option.schema'
import { z } from 'zod'

export const supportTicketMessageSchema = z.object({
  content: z
    .string({ message: 'Campo obrigatório' })
    .min(3, 'Mínimo de 3 caracteres')
    .max(2048, 'Máximo de 2048 caracteres'),
  documents: z
    .array(
      z.object({
        id: z.string(),
        file: z.custom<File>().optional(),
        fileName: z.string(),
        currentFileUrl: z.string(),
      }),
    )
    .optional(),
})

export type SupportTicketMessageValidationSchema = z.infer<typeof supportTicketMessageSchema>

export const supportTicketSchema = z.intersection(
  z.object({
    reason: optionSchema,
    subject_line: z
      .string({ message: 'Campo obrigatório' })
      .min(3, 'Mínimo de 3 caracteres')
      .max(128, 'Máximo de 128 caracteres'),
  }),
  supportTicketMessageSchema,
)

export type SupportTicketValidationSchema = z.infer<typeof supportTicketSchema>
