export const createClusterMarkerIcon = (count: number) => {
    const pathForOne = `
      <path d="M12.0007 16.3327C14.2098 16.3327 16.0007 14.5418 16.0007 12.3327C16.0007 10.1235 14.2098 8.33268 12.0007 8.33268C9.79151 8.33268 8.00065 10.1235 8.00065 12.3327C8.00065 14.5418 9.79151 16.3327 12.0007 16.3327Z" stroke="white" stroke-opacity="0.9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    `;
    const svg = `
      <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.6673 12.3327C22.6673 20.3327 12.0007 28.3327 12.0007 28.3327C12.0007 28.3327 1.33398 20.3327 1.33398 12.3327C1.33398 9.50371 2.45779 6.7906 4.45818 4.79021C6.45857 2.78982 9.17167 1.66602 12.0007 1.66602C14.8296 1.66602 17.5427 2.78982 19.5431 4.79021C21.5435 6.7906 22.6673 9.50371 22.6673 12.3327Z" fill="#1E1E1E"/>
        <path d="M12.0007 16.3327C14.2098 16.3327 16.0007 14.5418 16.0007 12.3327C16.0007 10.1235 14.2098 8.33268 12.0007 8.33268C9.79151 8.33268 8.00065 10.1235 8.00065 12.3327C8.00065 14.5418 9.79151 16.3327 12.0007 16.3327Z" fill="#1E1E1E"/>
        <path d="M22.6673 12.3327C22.6673 20.3327 12.0007 28.3327 12.0007 28.3327C12.0007 28.3327 1.33398 20.3327 1.33398 12.3327C1.33398 9.50371 2.45779 6.7906 4.45818 4.79021C6.45857 2.78982 9.17167 1.66602 12.0007 1.66602C14.8296 1.66602 17.5427 2.78982 19.5431 4.79021C21.5435 6.7906 22.6673 9.50371 22.6673 12.3327Z" stroke="white" stroke-opacity="0.9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        ${count === 1 ? pathForOne : ''}
        <text x="11.5" y="18" text-anchor="middle" font-size="12" fill="white" font-family="Arial">${count !== 1 ? count : ''}</text>
      </svg>
    `;
    return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`;
};