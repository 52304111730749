import Table from '@/common/components/Table';
import TextField from '@/common/components/TextField';
import { DatePickerWithRange } from '@/common/components/ui/dateRangePicker';
import useDebounce from '@/common/hooks/useDebounce';
import { useFilterDate } from '@/common/hooks/useFilterDate';
import { useFilterParams } from '@/common/hooks/useFilterParams';
import { Mapper } from '@/common/services/mapper';
import eventsColumns from '@/features/users/constants/eventsColumns.constant';
import { useClientUserEvents } from '@/features/users/hooks/useClientUserEvents';
import { ClientUserEventTransformer } from '@/features/users/services/clientUserEventsTransformer';
import { SearchIcon } from 'lucide-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

const EventsTab = () => {
  const { id: userId } = useParams()

  const { filterParams, setFilterParams } = useFilterParams()

  const [search, setSearch] = useDebounce(filterParams.consumptionHistorySearch || '')
  const [page, setPage] = useState(1)

  const { date, formattedDate } = useFilterDate(filterParams);

  const {
    data: userEvents,
    meta,
    isLoading: userEventsIsLoading,
  } = useClientUserEvents({
    userId,
    filters: {
      search,
      dateFrom: formattedDate.from,
      dateTo: formattedDate.to,
    },
    mapper: Mapper.mapWithTransformer({
      transformer: ClientUserEventTransformer.tableTransformer(),
    }),
  })

  return (
    <div className="flex flex-col gap-6">
      <TextField
        LeftIcon={<SearchIcon />}
        placeholder="Pesquisar"
        className="rounded-sm shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.5)]"
        defaultValue={filterParams.consumptionHistorySearch || ''}
        onChange={(e: any) => {
          setSearch(e.target?.value)
          setFilterParams((prev) => ({
            ...prev,
            consumptionHistorySearch: e.target?.value,
          }))
        }}
      />

      <div className="flex">
        <DatePickerWithRange
          date={date}
          setDate={(date) => setFilterParams((params) => ({ ...params, date }))}
        />
      </div>

      <Table
        className="max-h-[calc(100vh-324px)]"
        columns={eventsColumns}
        isPendingData={userEventsIsLoading}
        rows={userEvents}
        rowEmptyValue="--"
        page={page}
        setPage={(p) => setPage(p)}
        totalPages={meta.total_pages}
        totalCount={meta.total}
      />
    </div>
  )
};

export default EventsTab;
