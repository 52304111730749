import usePrivateAxios from "@/common/api/usePrivateAxios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { CompanyContractData, CompanyContractResponse } from "../types/contract.type";

export const useMineContract: () => UseQueryResult<CompanyContractData, unknown> = () => {
  const privateAxios = usePrivateAxios();

  return useQuery<CompanyContractResponse, unknown, CompanyContractData>({
    queryKey: ['contractsMine'],
    queryFn: () => privateAxios.get(`/contracts/mine`),
    select: (data) => data?.data?.data,
  });
};
