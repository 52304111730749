import Box from '@/common/components/Box'
import { DocumentFileList } from '@/common/components/DocumentFileList'
import { ImageWithPlaceholder } from '@/common/components/ImageWithPlaceholder'
import { MessageData } from '@/common/types/support/message.type'
import { cn } from '@/common/utils/cn'
import { formatDateTime } from '@/common/utils/formatters'
import { getFilenameFromUrl } from '@/common/utils/getFilenameFromUrl'
import { UserRoundIcon } from 'lucide-react'

interface MessageProps {
  message: MessageData
  margin?: 'left' | 'right'
}

const marginClass = {
  left: 'ml-8',
  right: 'mr-8',
}

export const Message = ({ message, margin }: MessageProps) => {
  return (
    <div className={cn('flex flex-col gap-2', margin && marginClass[margin])}>
      <div className="flex items-center gap-2">
        <p className="text-[11px]">Criado por</p>

        <div className="flex gap-1 items-center">
          <ImageWithPlaceholder
            url={message.creator?.profile_picture?.url}
            alt={message.creator?.full_name}
            placeholderIcon={UserRoundIcon}
          />

          <p className="text-[11px]">{message.creator?.full_name}</p>
        </div>

        {message.created_at && <p className="text-[11px]">no dia {formatDateTime(new Date(message.created_at))}</p>}
      </div>

      <Box className={cn("flex flex-col gap-4", margin === 'right' && 'bg-yellow-op-2')}>
        <p className="text-sm">{message.content}</p>

        {!!message.documents?.length && (
          <DocumentFileList
            files={message.documents.map((document) => ({
              fileName: getFilenameFromUrl(document.url),
              currentFileUrl: document.url,
            }))}
          />
        )}
      </Box>
    </div>
  )
}
