import Accordion from '@/common/components/Accordion';
import Select from '@/common/components/Select';
import TextField from '@/common/components/TextField';
import { formatCnpj, formatCurrency } from '@/common/utils/formatters';
import { InfoIcon } from 'lucide-react';
import { CompanyContractData } from '../../types/contract.type';
import { strategy as paymentModalityStrategies } from '../../constants/paymentModality.constant';
import { strategy as cycleStrategies } from '../../constants/cycleOptions.constant';
import { strategy as billGroupingModeStrategies } from '../../constants/billGroupingModeOptions.constant';
import { strategy as fiscalDocumentRequirementStrategies } from '../../constants/fiscalDocumentRequirement.constant';
import { strategy as paymentMethodStrategies } from '../../constants/paymentMethodOptions.constant';

type BillDetailsProps = {
  contract?: CompanyContractData;
};

export const BillDetails = ({ contract }: BillDetailsProps) => {
  return (
    <Accordion
      head={<p>Detalhes da fatura</p>}
      body={
        <div className="flex flex-col gap-4">
          <TextField
            label="Modalidade de pagamento"
            value={
              contract?.payment_modality
                ? paymentModalityStrategies[contract.payment_modality as keyof typeof paymentModalityStrategies]
                : ''
            }
            disabled
          />
          <TextField
            label="Ciclo de fatura"
            value={contract?.cycle ? cycleStrategies[contract.cycle as keyof typeof cycleStrategies] : ''}
            disabled
          />
          <div className="flex gap-2">
            <TextField
              label="Dia de abertura"
              className="w-1/2"
              value={contract?.cycle_start_day ? `${contract.cycle_start_day}` : ''}
              disabled
            />
            <TextField
              label="Vencimento da fatura"
              className="w-1/2"
              value={contract?.closing_to_due_in_days ? `D+${contract.closing_to_due_in_days}` : ''}
              disabled
            />
          </div>
          <TextField
            label="Modo de agrupamento da Fatura"
            value={
              contract?.bill_grouping_mode
                ? billGroupingModeStrategies[contract.bill_grouping_mode as keyof typeof billGroupingModeStrategies]
                : ''
            }
            disabled
          />
          <div className="flex gap-2">
            <InfoIcon strokeWidth={1} className="text-warning" />
            <div>
              <p className="font-bold text-[10px] leading-3">Atente-se ao seu ciclo de fatura e dias de abertura</p>
              <p className="font-light text-[10px] leading-3 italic">
                Caso o dia selecionado seja o primeiro dia mês (1), a fatura vai correr conforme ciclo definido
                <br />
                e o fechamento será no último dia do mês.
                <br />
                <br />
                Caso seja um dia posterior ao primeiro, a fatura vai seguir o ciclo definido, tendo os
                <br />
                seus períodos fixos de fechamento de 15 ou 30 dias.
              </p>
            </div>
          </div>
          <TextField
            label="Dias após o vencimento até inadimplência"
            value={contract?.due_to_suspension_in_days ? `D+${contract.due_to_suspension_in_days}` : ''}
            disabled
          />
          <TextField
            label="Tipo de recolha"
            value={
              contract?.fiscal_document_requirement
                ? fiscalDocumentRequirementStrategies[
                contract.fiscal_document_requirement as keyof typeof fiscalDocumentRequirementStrategies
                ]
                : ''
            }
            disabled
          />
          <Select
            label="Serviços"
            placeholder="Nenhum"
            value={
              contract?.contract_product_categories?.length
                ? contract.contract_product_categories.map(({ product_category }) => ({
                  label: product_category.display_name,
                  value: product_category.id,
                }))
                : []
            }
            isMulti
            isDisabled
          />
          <TextField
            label="Crédito Liberado"
            value={contract?.granted_credit ? formatCurrency(parseFloat(contract.granted_credit)) : ''}
            disabled
          />
          <TextField
            label="Padrão de CNPJ para emissão de NF"
            value={contract?.cnpj_for_fiscal_document ? formatCnpj(contract.cnpj_for_fiscal_document) : ''}
            disabled
          />
          <TextField
            label="Método de pagamento"
            value={
              contract?.payment_method
                ? paymentMethodStrategies[contract.payment_method as keyof typeof paymentMethodStrategies]
                : ''
            }
            disabled
          />
        </div>
      }
    />
  );
};
