export const dateTransformer = (date: string) => {
  const [year, month, day] = date.split('-');
  return `${day}/${month}/${year}`;
};

export const dateTimeTransformer = (date: Date) => {
  return `${date.toLocaleDateString()} - ${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
};

export const dateToISOStingTransformer = (date: Date | string | undefined) => {
  if (!date) {
    return "";
  }

  if (date instanceof Date) {
    date = date.toISOString();
  }

  return date ? `${date?.split("T")[0]}` : "";
};
