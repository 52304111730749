import { privateAxios } from "@/common/api/axios";
import { LogRequest, LogRequestResponse } from "@/common/types/logs/request.type";

import { UseQueryResult, useQuery } from "@tanstack/react-query";

type UseLogRequestType = (id?: string) => UseQueryResult<LogRequest, unknown>;

const useLogRequest: UseLogRequestType = (id?: string) => {
  return useQuery<LogRequestResponse, unknown, LogRequest>({
    enabled: !!id,
    queryKey: ['logRequests', id],
    queryFn: () => {
      return privateAxios.get(`/log/requests/${id}`);
    },
    select: (data) => data?.data?.data,
  });
};

export default useLogRequest;
