import usePrivateAxios from '@/common/api/usePrivateAxios';
import { transformNewRoles } from '@/common/utils/roleTransformers';
import { removeSpecialCharacters } from '@/common/utils/unFormatters';
import { ClientUserValidationSchema } from '@/features/users/schemas/create.schema';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

interface UseCreateClientUserProps {
  onSuccess: (response: AxiosResponse) => void;
  onError: (error: AxiosError) => void;
}

export const useCreateClientUser = ({
  onSuccess,
  onError,
}: UseCreateClientUserProps) => {
  const privateAxios = usePrivateAxios();

  return useMutation({
    mutationKey: ['createClientUser'],
    mutationFn: async (form: ClientUserValidationSchema) => {
      const payload = {
        cpf: removeSpecialCharacters(form.cpf),
        date_of_birth: form.date_of_birth || null,
        full_name: form.full_name,
        user_roles_attributes: form?.user_roles?.length
          ? form.user_roles.map(transformNewRoles)
          : [],
        branch_id: form?.branch_id?.value || undefined,
        status: form?.status?.value,
        phone_attributes: form?.phone?.number
          ? {
            number: form?.phone?.number ? removeSpecialCharacters(form.phone.number) : undefined,
          }
          : undefined,
        enrollment_number: form?.enrollment_number || undefined,
        email: form?.email || undefined,
      };

      return privateAxios.post('/users', { client_user: payload });
    },
    onSuccess,
    onError,
  });
};
