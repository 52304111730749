import Accordion from "@/common/components/Accordion";
import Checkbox from "@/common/components/Checkbox";
import MaskedTextField from "@/common/components/MaskedTextField";
import TextField from "@/common/components/TextField";
import { cnpjMask } from "@/common/constants/masks.constant";
import { useMineCompany } from "@/features/company/hooks/useMineCompany";
import { CompanyBranchData } from "@/features/company/types/company.type";
import { strategy as kindStrategy } from "../../../../common/constants/kind.constant";
import { ImageWithPlaceholder } from "@/common/components/ImageWithPlaceholder";
import { BriefcaseBusinessIcon } from "lucide-react";
import AddressMapImage from "@/common/components/AddressMapImage";

export type GeneralInformationProps = {
  generalInformation?: CompanyBranchData['general_information'];
};

export const GeneralInformation = ({
  generalInformation,
}: GeneralInformationProps) => {
  const { data: companyData } = useMineCompany();

  return (
    <Accordion
      head={(
        <div className="flex flex-col gap-2 ">
          <Checkbox
            label='Dados empresariais específicos'
            name='Dados empresariais específicos'
            defaultChecked={!!generalInformation}
            disabled
          />
        </div>
      )}
      body={(
        <div className="flex flex-col gap-4">
          <div className="flex gap-2 items-center">
            <div>
              <p>Logo</p>

              <ImageWithPlaceholder
                className="size-20"
                iconClassName="size-14"
                placeholderIcon={BriefcaseBusinessIcon}
                url={generalInformation?.company_logo?.url}
                alt={generalInformation?.fantasy_name}
              />
            </div>
            <div className="flex gap-2 w-[90%]">
              <MaskedTextField
                label="CNPJ"
                mask={cnpjMask}
                className="w-1/2"
                value={generalInformation?.cnpj || companyData?.matrix_branch?.general_information.cnpj || ''}
                disabled
              />
              <TextField
                label="Tipo de empresa"
                className="w-1/2"
                placeholder="Privada"
                value={companyData?.kind ? kindStrategy[companyData.kind as keyof typeof kindStrategy] : ''}
                disabled
              />
            </div>
          </div>
          <div className="flex gap-2">
            <TextField
              label="Nome Fantasia"
              className="w-1/2"
              value={generalInformation?.fantasy_name || companyData?.matrix_branch?.general_information.fantasy_name || ''}
              disabled
            />
            <TextField
              label="Nome Empresarial"
              className="w-1/2"
              value={generalInformation?.official_name || companyData?.matrix_branch?.general_information.official_name || ''}
              disabled
            />
          </div>
          <TextField
            label="Endereço eletrônico"
            maxLength={64}
            value={generalInformation?.email || companyData?.matrix_branch?.general_information.email || ''}
            disabled
          />
          <div className="flex gap-2">
            <TextField
              label="IE (Inscrição Estadual)"
              maxLength={14}
              className="w-1/2"
              value={generalInformation?.state_inscription || companyData?.matrix_branch?.general_information.state_inscription || ''}
              disabled
            />
            <TextField
              label="CNAE"
              maxLength={7}
              className="w-1/2"
              value={generalInformation?.primary_cnae || companyData?.matrix_branch?.general_information.primary_cnae || ''}
              disabled
            />
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex justify-between">
              <p className="text-[18px] font-bold">Endereço</p>
            </div>
            <div className="flex gap-6">
              <div className="w-1/2 flex flex-col gap-4 items-center">
                <MaskedTextField
                  mask='00000-000'
                  label="CEP"
                  className="w-full"
                  value={generalInformation?.address?.zipcode || companyData?.matrix_branch?.general_information?.address?.zipcode || ''}
                  disabled
                />
                <div className="flex w-full gap-2">
                  <TextField
                    label="Logradouro"
                    className="w-3/4"
                    value={generalInformation?.address?.street || companyData?.matrix_branch?.general_information?.address?.street || ''}
                    disabled
                  />
                  <MaskedTextField
                    label="Número"
                    mask='00000'
                    className="w-1/4"
                    value={generalInformation?.address?.number || companyData?.matrix_branch?.general_information?.address?.number || ''}
                    disabled
                  />
                </div>
                <TextField
                  label="Município"
                  className="w-full"
                  value={generalInformation?.address?.city?.display_name || companyData?.matrix_branch?.general_information?.address?.city?.display_name || ''}
                  disabled
                />
                <TextField
                  label="Bairro"
                  maxLength={32}
                  className="w-full"
                  value={generalInformation?.address?.neighborhood || companyData?.matrix_branch?.general_information?.address?.neighborhood || ''}
                  disabled
                />
                <TextField
                  label="Complemento"
                  className="w-full"
                  value={generalInformation?.address?.complement || companyData?.matrix_branch?.general_information?.address?.complement || ''}
                  disabled
                />
              </div>

              <AddressMapImage address={generalInformation?.address || companyData?.matrix_branch?.general_information?.address}/>
            </div>
          </div>
        </div>
      )}
    />
  );
};
