import { useLogChangesTable } from "@/common/stores/logChangesTable";
import { LogChangeDiff } from "@/common/types/logs/change.type";
import { useEffect, useRef } from "react";

interface ChangesRowProps {
  id: string;
  field: string;
  diff: LogChangeDiff;
}

const ChangesRow = ({ id, field, diff }: ChangesRowProps) => {
  const { columnWidth, updateColumnWidth } = useLogChangesTable();

  const oldValueRef = useRef<HTMLDivElement>(null);
  const newValueRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (oldValueRef.current && oldValueRef.current.offsetWidth > columnWidth) {
      updateColumnWidth(oldValueRef.current.offsetWidth);
    }

    if (newValueRef.current && newValueRef.current.offsetWidth > columnWidth) {
      updateColumnWidth(newValueRef.current.offsetWidth);
    }

    if (oldValueRef.current) {
      oldValueRef.current.style.width = `${columnWidth}px`;
    }

    if (newValueRef.current) {
      newValueRef.current.style.width = `${columnWidth}px`;
    }
  }, [oldValueRef.current?.id, newValueRef.current?.id, columnWidth]);

  return (
    <div className="flex gap-0 items-stretch justify-end h-[56px]">
      <div className="px-3 flex items-center grow">
        {field}
      </div>

      <div ref={oldValueRef} id={`${id}_${field}_0`} className="log_cell px-3 flex items-center bg-[#FCCDCA33] min-w-max border-s border-s-white-200">
        {diff[0] || '--'}
      </div>

      <div ref={newValueRef} id={`${id}_${field}_1`} className="log_cell px-3 flex items-center bg-[#BEEFC233] min-w-max border-s border-s-white-200">
        {diff[1]}
      </div>
    </div>
  );
};

export default ChangesRow;
