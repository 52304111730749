import { cva } from "class-variance-authority";

export const textAreaVariants = cva(
  'p-4 w-full text-white-800 text-sm rounded-md border bg-neutral-op-8 outline-none border border-transparent',
  {
    variants: {
      variant: {
        default: '',
        disabled: '',
        error: 'border-danger'
      }
    },
    defaultVariants: {
      variant: 'default',
    }
  }
);
