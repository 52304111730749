import { InfoIcon } from "lucide-react";

export const PeriodRestrictionInfo = () => {
  return (
    <div className="flex items-center gap-x-2">
      <InfoIcon strokeWidth={1} className="size-6 text-yellow-600" />

      <p className="font-bold text-[10px] leading-3">
        Marque o checkbox “Não permitido” para os dias que você deseja desabilitar consumos.

        <br />

        <span className="font-light italic">
          Selecione os horários PERMITIDOS para consumo nos campos de início e fim, caso deseje habilitar o dia inteiro, selecione a opção de 24 horas no Horário de início, caso deseje criar períodos durante um dia, clique no + a direita do Horário de fim.
        </span>
      </p>
    </div>
  );
};
