import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../stores/auth";

const PrivateRoute = () => {
  const location = useLocation();
  const { accessToken } = useAuth();

  return accessToken ? <Outlet /> : <Navigate to='/login' state={{ from: location }} />;
};

export default PrivateRoute;
