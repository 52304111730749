import { copyButtonStates } from '@/common/constants/copyButtonStates.constant';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

export const useCopyButton = () => {
  const [copyState, setCopyState] = useState(copyButtonStates.default);
  const [timeoutId, setTimeoutId] = useState<number | null>(null);

  const tooltipId = useMemo(() => uuid(), []);

  const copySuccess = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopyState(copyButtonStates.success);
    resetButton();
  };

  const copyFailed = () => {
    setCopyState(copyButtonStates.failed);
    resetButton();
  };

  const resetButton = useCallback(() => {
    const timeout = window.setTimeout(() => {
      setCopyState(copyButtonStates.default);
    }, 2000);

    setTimeoutId(timeout);
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return {
    control: {
      tooltipId,
      icon: copyState.icon,
      message: copyState.message,
    },
    copySuccess,
    copyFailed,
  };
};
