import { ImageWithPlaceholder } from '@/common/components/ImageWithPlaceholder'
import { UserRoundIcon } from 'lucide-react'

type AuthorEventableProps = {
  data: {
    full_name: string
  }
}

const CompanyEventAuthor = ({ data: author }: AuthorEventableProps) => {
  return (
    <div className="flex gap-2 items-center">
      <ImageWithPlaceholder
        alt={author?.full_name}
        placeholderIcon={UserRoundIcon}
        className="size-6"
        iconClassName="size-4"
      />
      <p className="text-xs">{author?.full_name}</p>
    </div>
  )
}

export default CompanyEventAuthor
