import usePrivateAxios from "@/common/api/usePrivateAxios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { EquipmentData, EquipmentResponse } from "../types/equipments.type";

type UseEquipmentProps = {
  id: string;
};

type UseEquipmentType = ({ }: UseEquipmentProps) => UseQueryResult<EquipmentData, unknown>;

const useEquipment: UseEquipmentType = ({ id }) => {
  const privateAxios = usePrivateAxios();

  return useQuery<EquipmentResponse, unknown, EquipmentData>({
    queryKey: ['equipment', id],
    queryFn: () => {
      return privateAxios.get(`/equipments/${id}`);
    },
    select: (data) => data?.data?.data,
  });
};

export default useEquipment;
