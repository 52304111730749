import { cn } from "@/common/utils/cn";
import { VariantProps } from "class-variance-authority";
import { buttonVariants } from "./styles";
import { forwardRef } from "react";
import Spinner from "../Spinner";

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  isLoading?: boolean;
  spinnerClassName?: string;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  children,
  variant,
  className,
  disabled = false,
  isLoading = false,
  type = 'button',
  spinnerClassName,
  ...props
}, ref) => {
  return (
    <button
      className={cn(buttonVariants({ variant: variant }), className, disabled || isLoading ? 'opacity-50 pointer-events-none' : '')}
      type={type}
      ref={ref}
      {...props}
    >
      {children}

      {isLoading && <Spinner className={cn("w-4 h-4 fill-white-800", spinnerClassName)} />}
    </button>
  );
});
Button.displayName = "Button";

export default Button;
