import { formatDateTime } from "@/common/utils/formatters";
import { Link } from "react-router-dom";
import { EquipmentData } from "../types/equipments.type";
import { equipmentStatusTranslation } from "@/common/constants/equipmentStatus.constants";

interface EquipmentsGridItemProps {
  equipment: EquipmentData;
}

export const EquipmentsGridItem = ({ equipment }: EquipmentsGridItemProps) => {
  return (
    <Link to={`/equipments/${equipment.id}`} className="flex flex-col bg-white-200 rounded-lg overflow-hidden">
      {equipment.photos.length > 0 ? (
        <img
          src={equipment.photos[0].url}
          alt={equipment.model?.display_name}
          className="w-full object-cover aspect-[4/3]"
        />
      ) : (
        <div className="w-full bg-gray-300 aspect-[4/3]" />
      )}
      <div className="w-full space-y-2 text-white-800 p-4">
        <p className="font-bold text-sm">
          {equipment.license_plate || equipment.official_name || 'Não informado'}
        </p>

        <div className="space-y-1">
          <p className="text-xs">{equipment.model?.brand || 'Não informado'}</p>
          <p className="text-xs">{equipment.model?.display_name} {equipment.model?.year}</p>
        </div>

        <div className="flex gap-x-2">
          <div className="space-y-1">
            <p className="font-light text-xs italic">Status</p>
            <p className="text-[11px] leading-3">{equipmentStatusTranslation[(equipment.status || 'default') as keyof typeof equipmentStatusTranslation]}</p>
          </div>

          <div className="space-y-1">
            <p className="font-light text-xs italic">Filial</p>
            <p className="text-[11px] leading-3">{equipment.branch.display_name}</p>
          </div>
        </div>

        <div className="space-y-1">
          <p className="font-light text-xs italic">Cartão</p>
          <p className="text-[11px] leading-3">**** **** **** {equipment.card?.last_four_digits}</p>
        </div>

        <div className="space-y-1">
          <p className="font-light text-xs italic">{equipment.model?.category?.unit_for_consumption === 'hours' ? 'Horas' : 'Quilometragem'}</p>
          <p className="text-[11px] leading-3">{equipment.last_usage_value}{equipment.model?.category?.unit_for_consumption === 'hours' ? ' h' : ' km'} {equipment.last_usage_at && <span className="font-light italic">{formatDateTime(new Date(equipment.last_usage_at))}</span>}</p>
        </div>

        <div className="space-y-1">
          <p className="font-light text-xs italic">Último consumo</p>
          <p className="text-[11px] leading-3">Não implementado</p>
        </div>

        <div className="space-y-1">
          <p className='text-[8px] italic font-light'>
            {`Cadastrado em ${formatDateTime(new Date(equipment.created_at || 0))}`}
          </p>
        </div>
      </div>
    </Link>
  );
};
