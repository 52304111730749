import { create } from "zustand";

type State = {
  columnWidth: number;
};

type Action = {
  updateColumnWidth: (newColumnWidth: State['columnWidth']) => void;
};

export const useLogChangesTable = create<State & Action>((set) => ({
  columnWidth: 0,
  updateColumnWidth: (newColumnWidth: State['columnWidth']) => set((state) =>
    state.columnWidth !== newColumnWidth ? { columnWidth: newColumnWidth } : state
  ),
}));