export const getCompanyDomain = () => {
  const hostname = window.location.hostname;
  const hostnameParts = hostname.split('.');

  if (hostnameParts.at(-1) === 'localhost' && hostnameParts.length > 1) {
    return hostnameParts[0];
  }

  if (hostnameParts.includes('staging') && hostnameParts.length > 5) {
    return hostnameParts[0];
  }

  if (!hostnameParts.includes('staging') && hostnameParts.length > 4) {
    return hostnameParts[0];
  }

  return null;
};

export const setCompanyDomain = (domain: string) => {
  const hostname = window.location.hostname;

  if (!hostname.startsWith(domain)) {
    const currentUrl = window.location.href;
    const newHost = `${domain}.${hostname}`;
    const newUrl = currentUrl.replace(hostname, newHost);

    window.location.href = newUrl;
  }
};
