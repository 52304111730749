import usePrivateAxios from '@/common/api/usePrivateAxios';
import {
  ClientUserData,
  ClientUserResponse,
} from '@/features/users/types/clientUsers';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

interface UseClientUserProps {
  id: string;
}

export const useClientUser: (
  props: UseClientUserProps,
) => UseQueryResult<ClientUserData, unknown> = ({ id }) => {
  const privateAxios = usePrivateAxios();

  return useQuery<ClientUserResponse, unknown, ClientUserData>({
    enabled: !!id,
    queryKey: ['clientUser', id],
    queryFn: () => {
      return privateAxios.get(`/users/${id}`);
    },
    select: (data) => data?.data?.data,
  });
};
