import { SearchIcon } from "lucide-react";
import TextField from "../TextField";
import { DatePickerWithRange } from "../ui/dateRangePicker";
import { useFilterParams } from "@/common/hooks/useFilterParams";
import { useFilterDate } from "@/common/hooks/useFilterDate";
import Table from "../Table";
import { logRelatedTypes } from "@/common/constants/logRelatedTypes";
import { Mapper } from "@/common/services/mapper";
import useLogRequests from "@/common/hooks/queries/useLogRequests";
import ListFilters from "../ListFilters";
import { logRequestResponseOptions } from "@/common/constants/logRequestResponseOptions.constant";
import { logRequestsTableColumns } from "@/common/constants/logRequestsTableColumns";
import { LogRequestsTransformer } from "@/common/services/logRequestsTransformer";

interface LogRequestsTableProps {
  type: keyof typeof logRelatedTypes;
  id?: string;
}

const LogRequestsTable = ({ type, id }: LogRequestsTableProps) => {
  const { filterParams, setFilterParams } = useFilterParams();

  const { date, formattedDate } = useFilterDate(filterParams);

  const { data: logRequests, meta, isPending } = useLogRequests({
    filters: {
      search: filterParams.search,
      page: filterParams.page,
      performedByType: type,
      performedById: id,
      success: filterParams.success?.value && Number(filterParams.success.value) === 1,
      createdAtGt: formattedDate.from,
      createdAtLt: formattedDate.to,
    },
    mapper: Mapper.mapWithTransformer({
      transformer: LogRequestsTransformer.tableTransformer(),
    })
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <TextField
          LeftIcon={<SearchIcon />}
          placeholder="Pesquisar por ID"
          className="rounded-sm shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.5)]"
          defaultValue={filterParams.search}
          onChange={(e: any) => setFilterParams((params) => ({ ...params, search: e.target.value }))}
        />

        <div className="flex gap-2 items-center">
          <DatePickerWithRange
            date={date}
            setDate={(date) => setFilterParams((params) => ({ ...params, date }))}
          />

          <ListFilters
            filters={[
              {
                className: '',
                placeholder: 'Resultado',
                options: logRequestResponseOptions,
                onChange: (value) => setFilterParams((prev) => ({ ...prev, success: value })),
                defaultValue: filterParams.success,
              }
            ]}
          />
        </div>
      </div>

      <div>
        <Table
          className="max-h-[calc(100vh-268px-3rem)]"
          columns={logRequestsTableColumns}
          rows={logRequests}
          isPendingData={isPending}
          hasPagination
          totalCount={meta.total}
          totalPages={meta.total_pages}
          page={filterParams.page || 1}
          setPage={(page) => setFilterParams((prev) => ({ ...prev, page }))}
        />
      </div>
    </div>
  );
};

export default LogRequestsTable;
