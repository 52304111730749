export const totalizerProductsColumns = [
  { label: 'Produto', value: 'product_display_name' },
  { label: 'Quantidade/Un', value: 'product_quantity_sum' },
  { label: 'Valor médio Un', value: 'product_price_avg' },
  { label: 'Transações', value: 'movements_count' },
  { label: 'Transações Estornadas', value: 'refunded_movements_count' },
  { label: 'Transações Finais', value: 'final_movements_count' },
  { label: 'Valor Bruto', value: 'movement_total_sum' },
  { label: 'Valor Estornado', value: 'refunded_movement_total_sum' },
  { label: 'Valor Final', value: 'final_movement_total_sum' },
]

export const totalizerFeesColumns = [
  { label: 'Nome da tarifa', value: 'fee_display_name' },
  { label: 'Recurso', value: 'source_name', className: 'w-10' },
  { label: 'Transações', value: 'movements_count', className: 'w-10' },
  { label: 'Transações Estornadas', value: 'refunded_movements_count', className: 'w-10' },
  { label: 'Transações Finais', value: 'final_movements_count', className: 'w-10' },
  { label: 'Valor Bruto', value: 'movement_total_sum', className: 'w-10' },
  { label: 'Valor Estornado', value: 'refunded_movement_total_sum', className: 'w-10' },
  { label: 'Valor Final', value: 'final_movement_total_sum', className: 'w-10' },
]
