import Accordion from '@/common/components/Accordion'
import Checkbox from '@/common/components/Checkbox'
import TextField from '@/common/components/TextField'
import { formatCurrency } from '@/common/utils/formatters'
import { options as applicableProductsPeriodLimit } from '@/common/constants/applicableProductsPeriodLimit.constant'
import { ClientUserData } from '@/features/users/types/clientUsers'
import UpdatePersonalCardLimitsModal from '@/features/users/components/showUser/updatePersonalCardLimits'

interface ConsumptionLimitProps {
  userId: string
  card: ClientUserData['cards'][0]
}

export const ConsumptionLimit = ({
  userId,
  card,
}: ConsumptionLimitProps) => {
  return (
    <Accordion
      head={(
        <Checkbox
          label="Limites de Consumo"
          name="consumptionLimit"
          checked={(!!card.limit_per_transaction || !!card.limit_per_period)}
          disabled
        />
      )}
      body={(
        <div className="flex w-full flex-col gap-y-6">
          <div className='flex items-center justify-between gap-x-4'>
            <div className="flex items-center gap-x-6">
              <Checkbox
                label="Limite por transação"
                name="limitByTransaction"
                labelClassName="text-[10px] italic font-light"
                checked={!!card.limit_per_transaction}
                disabled
              />
              <Checkbox
                label="Limite por período"
                name="limitByPeriod"
                labelClassName="text-[10px] italic font-light"
                checked={!!card.limit_per_period}
                disabled
              />
            </div>
            <UpdatePersonalCardLimitsModal userId={userId} card={card} />
          </div>
          {card.limit_per_transaction && (
            <div className="flex flex-col gap-y-4">
              <p>Limite por Transação</p>
              <TextField
                label="Valor total"
                value={card.limit_per_transaction ? formatCurrency(parseFloat(card.limit_per_transaction)) : ''}
                disabled
              />
            </div>
          )}
          {card.limit_per_period && (
            <div className="flex flex-col gap-y-4">
              <p>Limite por Período</p>
              <TextField
                label="Período"
                value={card.limit_period_in_days ? applicableProductsPeriodLimit.find((option) => option.value === `${card.limit_period_in_days}`)?.label || '' : ''}
                disabled
              />
              <TextField
                label="Valor total"
                value={card.limit_per_period ? formatCurrency(parseFloat(card.limit_per_period)) : ''}
                disabled
              />
            </div>
          )}
        </div>
      )}
    />
  )
}
