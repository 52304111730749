import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogConfirmFooter,
} from "@/common/components/ui/dialog";

export interface ModalProps {
  Trigger?: JSX.Element;
  className?: string;
  title?: JSX.Element | string;
  titleClass?: string;
  subtitle?: string;
  subtitleClassName?: string;
  body?: JSX.Element;
  footer?: string;
  footerClass?: string;
  useCancellableFooter?: boolean;
  confirmButtonText?: string;
  confirmButtonClassName?: string;
  disabled?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
  showClose?: boolean;
}

const Modal = ({
  Trigger,
  className,
  title,
  titleClass,
  subtitle,
  body,
  footer,
  footerClass,
  useCancellableFooter,
  confirmButtonText,
  confirmButtonClassName,
  disabled,
  onConfirm,
  onCancel,
  isOpen,
  subtitleClassName = "",
  setIsOpen,
  showClose,
}: ModalProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen} >
      {Trigger && <DialogTrigger>{Trigger}</DialogTrigger>}
      <DialogContent className={className}>
        {title
          ? <DialogHeader showClose={showClose}>
            <DialogTitle className={titleClass}>{title}</DialogTitle>
            {subtitle
              ? <p className={`${subtitleClassName} text-sm`}>{subtitle}</p>
              : null
            }
          </DialogHeader>
          : null
        }
        {body
          ? <DialogDescription>
            {body}
          </DialogDescription>
          : null
        }
        {useCancellableFooter
          ? <DialogConfirmFooter
            cancelButton={{
              children: 'Cancelar',
              disabled: disabled,
              onClick: onCancel,
            }}
            confirmButton={{
              children: confirmButtonText || 'Confirmar',
              className: confirmButtonClassName,
              disabled: disabled,
              onClick: onConfirm,
            }}
          />
          : footer
            ? <DialogFooter className={footerClass} onClick={onConfirm}>
              {footer}
            </DialogFooter>
            : null
        }
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
