import { privateAxios } from '@/common/api/axios';
import { defaultTransformerResponse } from '@/common/constants/defaultTransformerResponse.constant';
import { Mapper } from '@/common/services/mapper';
import { MetaResponse } from '@/common/types/metaResponse';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

type UseClientUserEventsProps<T> = {
  userId?: string;
  filters?: {
    search?: string;
    page?: number;
    dateFrom?: string;
    dateTo?: string;
  };
  mapper?: (data: any) => T;
};

export const useClientUserEvents = <T,>({
  userId = '',
  filters: { search = '', page, dateFrom, dateTo } = {},
  mapper = Mapper.default<T>(),
}: UseClientUserEventsProps<T>) => {
  const { data: { data, meta } = defaultTransformerResponse<T>(), ...rest } =
    useQuery({
      enabled: !!userId,
      queryKey: ['clientUserEvents', userId, page, search, dateFrom, dateTo],
      queryFn: () => {
        return privateAxios.get(`/users/${userId}/events`, {
          params: {
            search,
            page,
            'q[created_at_gteq]': dateFrom,
            'q[created_at_lteq]': dateTo,
          },
        });
      },
      select: (data: { data: { data: any[]; meta: MetaResponse; }; }) => {
        return {
          data: mapper(data?.data?.data),
          meta: data?.data?.meta,
        };
      },
      placeholderData: keepPreviousData,
    });

  return { data, meta, ...rest };
};
