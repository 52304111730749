import QueryString from 'qs';
import { useSearchParams } from 'react-router-dom';

export const useFilterParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setFilterParams = (mutationFn: (searchParams: any) => any) => {
    const newObject = mutationFn(QueryString.parse(searchParams.toString()));

    setSearchParams(
      QueryString.stringify(
        Object.fromEntries(
          Object.entries(newObject).filter(([_, value]) => !!value),
        ),
      ),
    );
  };

  const filterParams = QueryString.parse(searchParams.toString()) as {
    [key: string]: any;
  };

  return { filterParams, setFilterParams };
};
