import usePrivateAxios from '@/common/api/usePrivateAxios'
import { defaultTransformerResponse } from '@/common/constants/defaultTransformerResponse.constant'
import { Mapper } from '@/common/services/mapper'
import { MetaResponse } from '@/common/types/metaResponse'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

type UseSupportTicketMessagesProps<T> = {
  id: string
  mapper?: (data: any) => T
}

export const useSupportTicketMessages = <T,>({ id, mapper = Mapper.default<T>() }: UseSupportTicketMessagesProps<T>) => {
  const privateAxios = usePrivateAxios()

  const { data: { data, meta } = defaultTransformerResponse<T>(), ...rest } = useQuery({
    queryKey: ['supportTicketMessages', id],
    refetchInterval: 1000 * 60, // 1 minute
    queryFn: () => {
      return privateAxios.get(`/support/tickets/${id}/messages`)
    },
    select: (data: { data: { data: any[]; meta: MetaResponse } }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      }
    },
    placeholderData: keepPreviousData,
  })

  return { data, meta, ...rest }
}
