import Accordion from "@/common/components/Accordion";
import Checkbox from "@/common/components/Checkbox";
import { CompanyBranchProductCategoryGrantedCreditData } from "../../types/company.type";
import { CreditProductCategoryConfig } from "./CreditProductCategoryConfig";

interface CreditConfigurationProps {
  productCategoryGrantedCredits?: CompanyBranchProductCategoryGrantedCreditData[];
}

export const CreditConfiguration = ({ productCategoryGrantedCredits }: CreditConfigurationProps) => {
  return (
    <>
      {productCategoryGrantedCredits?.length ? (
        <Accordion
          head={(
            <Checkbox
              label="Crédito garantido por serviço"
              checked={!!productCategoryGrantedCredits.length}
              name="productCategories"
              disabled
            />
          )}
          body={(
            <div className="flex flex-col gap-4">
              {productCategoryGrantedCredits?.map((category, index) => (
                <CreditProductCategoryConfig
                  key={index}
                  category={category?.product_category}
                  grantedCredit={category?.granted_credit}
                  products={category?.product_granted_credits || []}
                />
              ))}
            </div>
          )}
        />
      ) : null}
    </>
  );
};
