import SelectController from "@/common/components/SelectController";
import TextField from "@/common/components/TextField";
import { Control, FieldErrors, UseFormRegister } from "react-hook-form";
import { CompanyBranchData } from "@/common/types/companyBranches.type";
import { Mapper } from "@/common/services/mapper";
import useDebounce from "@/common/hooks/useDebounce";
import { useCompanyBranches } from "@/common/hooks/queries/useCompanyBranches";
import { CompanyBranchValidationSchema } from "../../schemas/companyBranch.schema";

export type BranchInformationProps = {
  register: UseFormRegister<CompanyBranchValidationSchema>;
  errors: FieldErrors<CompanyBranchValidationSchema>;
  control: Control<CompanyBranchValidationSchema, any>;
  companyId?: string;
  showParentIdField?: boolean;
};

export const BranchInformation = ({
  register,
  errors,
  control,
  showParentIdField
}: BranchInformationProps) => {
  const [searchCompanyBranch, setSearchCompanyBranch] = useDebounce("");

  const { data: companyBranchOptions } = useCompanyBranches({
    filters: { search: searchCompanyBranch },
    mapper: Mapper.mapToOptions<CompanyBranchData>({ labelFieldName: 'display_name', valueFieldName: 'id' }),
  });

  return (
    <div className="flex gap-2 w-full justify-between">
      <TextField
        label="Nome da filial"
        maxLength={64}
        errorMessage={errors.display_name?.message || undefined}
        className="w-full"
        {...register('display_name')}
      />
      {showParentIdField && (
        <SelectController
          control={control}
          name="parent_id"
          label="Filial Mãe"
          className="w-full"
          errorMessage={errors?.parent_id?.message || undefined}
          options={companyBranchOptions || []}
          onInputChange={setSearchCompanyBranch}
        />
      )}
    </div>
  );
};
