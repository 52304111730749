import { ArrowBigRight } from "lucide-react";
import { Link } from "react-router-dom";
import { LogChange } from "../types/logs/change.type";
import { formatDateTime } from "../utils/formatters";
import ChangesColumn from "../components/LogChangesTable/components/ChangesColumn";

export class LogChangesTransformer {
  static tableTransformer() {
    return (logChanges: LogChange[]) => {
      return logChanges.map((log) => ({
        ...log,
        created_at: formatDateTime(new Date(log.created_at)),
        item_name_translated: log.item_name_translated,
        changes: <ChangesColumn id={log.id} changes={log.object_changes_translated} />,
        actions: (
          <Link to={log.request_id}>
            <ArrowBigRight />
          </Link>
        )
      }));
    };
  }
}
