import usePrivateAxios from '@/common/api/usePrivateAxios'
import { ErrorResponse } from '@/common/types/errorResponse'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

interface UseCloseSupportTicketProps {
  supportTicketId?: string
  onSuccess?: (response: AxiosResponse) => void
  onError?: (error: ErrorResponse) => void
}

export const useCloseSupportTicket = ({ supportTicketId, onSuccess, onError }: UseCloseSupportTicketProps) => {
  const privateAxios = usePrivateAxios()

  return useMutation({
    mutationKey: ['closeSupportTicket'],
    mutationFn: async () => {
      return privateAxios.put(`/support/tickets/${supportTicketId}/close`)
    },
    onSuccess,
    onError,
  })
}
