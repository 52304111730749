import { PiggyBankIcon } from "lucide-react"

const CompanyFeeName = ({ name }: { name: string }) => {
  return (
    <div className="flex gap-2 items-center">
        <PiggyBankIcon strokeWidth={.6} className="w-5 h-5" />
        <div >
        <p className="">{name}</p>
        </div>
    </div>
  )
}

export default CompanyFeeName
