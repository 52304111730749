import { Option } from "@/common/types/option";
import { formatCurrency, formatDecimal } from "@/common/utils/formatters";
import { getFilenameFromUrl } from "@/common/utils/getFilenameFromUrl";
import { options as applicableProductsPeriodLimit } from "../constants/applicableProductsPeriodLimit.constant";
import { equipmentStatusOptions } from "../constants/equipmentStatusOptions.constant";
import { options as propertyKindOptions } from "../constants/propertyKindOptions.constant";
import { options as usageKindOptions } from "../constants/usageKindOptions.constant";
import { companyBranchDefaultPeriodRestrictionData } from "./companyBranchDefaultPeriodRestrictionData";
import { EquipmentData } from "../types/equipments.type";
import { EquipmentValidationSchema } from "../schemas/create.schema";

export const companyEquipmentDefaultValues = ({
  equipmentData,
  companyBranchOptions,
}: {
  equipmentData?: EquipmentData,
  companyBranchOptions: Option[],
}) => ({
  photos: equipmentData?.photos?.map((photo) => ({ id: photo.attachment_id, file: photo.url })) || [],
  status: equipmentData?.status ? equipmentStatusOptions.find((status) => status.value === equipmentData?.status) : undefined,
  branch_id: equipmentData?.branch.id ? companyBranchOptions?.find((branch) => branch.value === equipmentData?.branch.id) : undefined,
  equipment_model: equipmentData?.model?.id ? Object.assign({}, {
    label: equipmentData.model.display_name,
    value: equipmentData.model.id,
    unitForConsumption: equipmentData.model.category.unit_for_consumption,
    category: equipmentData.model.category.display_name,
    requiredFieldNames: equipmentData.model.category.required_field_names?.map((requiredFieldName) => ({ requiredFieldName })),
  }) : undefined,
  tank_capacity: equipmentData?.tank_capacity ? formatDecimal(equipmentData?.tank_capacity) : undefined,
  last_usage_value: equipmentData?.last_usage_value ? equipmentData?.last_usage_value.toString() : '',
  serial_number: equipmentData?.serial_number || undefined,
  official_name: equipmentData?.official_name || undefined,
  license_plate: equipmentData?.license_plate || undefined,
  chassis_number: equipmentData?.chassis_number || undefined,
  renavam: equipmentData?.renavam || undefined,
  usage_kind: equipmentData?.usage_kind ? usageKindOptions.find((usageKind) => usageKind.value === equipmentData?.usage_kind) : undefined,
  property_kind: equipmentData?.property_kind ? propertyKindOptions.find((propertyKind) => propertyKind.value === equipmentData?.property_kind) : undefined,
  property_code: equipmentData?.property_code || undefined,
  enablePeriodRestriction: !!equipmentData?.card?.restriction?.period_restrictions?.length,
  period_restrictions_attributes: equipmentData?.card?.restriction?.period_restrictions?.length ? companyBranchDefaultPeriodRestrictionData(equipmentData?.card?.restriction?.period_restrictions) : companyBranchDefaultPeriodRestrictionData(),
  documents: equipmentData?.documents?.map((document) => ({ id: document.attachment_id, currentFileUrl: document.url, fileName: getFilenameFromUrl(document.url) })) || [],
  card_product_limits_attributes: equipmentData?.card?.card_product_limits?.map((cardProductLimit) => ({
    id: cardProductLimit.id,
    product_id: cardProductLimit.product.id,
    display_name: cardProductLimit.product.display_name,
    unit_for_quantity: cardProductLimit.product.unit_for_quantity,
    limitByTransaction: !!cardProductLimit.quantity_limit_per_transaction || !!cardProductLimit.credit_limit_per_transaction,
    limitByPeriod: !!cardProductLimit.quantity_limit_per_period || !!cardProductLimit.credit_limit_per_period,
    transactionLimit: {
      quantity: cardProductLimit.quantity_limit_per_transaction || undefined,
      totalValue: cardProductLimit.credit_limit_per_transaction ? formatCurrency(parseFloat(cardProductLimit.credit_limit_per_transaction)) : undefined,
    },
    periodLimit: {
      period: cardProductLimit.period_in_days ? applicableProductsPeriodLimit.find((periodLimit) => periodLimit.value === `${cardProductLimit.period_in_days}`) as ({ label: string, value: '1' | '7' | '15' | '30'; }) : undefined,
      quantity: cardProductLimit.quantity_limit_per_period || undefined,
      totalValue: cardProductLimit.credit_limit_per_period ? formatCurrency(parseFloat(cardProductLimit.credit_limit_per_period)) : undefined,
    }
  })) || [],
} as EquipmentValidationSchema);
