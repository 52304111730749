import { BillingEventData } from "@/common/types/billing/event.type";
import React from "react";
import { formatCurrency, formatDateTime } from "@/common/utils/formatters";
import ConsumptionDetails from "../components/ConsumptionDetails";
import FeesDetails from "../components/FeesDetails";
import RefundDetails from "../components/RefundDetails";
import { twMerge } from "tailwind-merge";
import { PiggyBankIcon } from "lucide-react";

export class BillingEventTransformer {
  static tableTransformer() {
    return (events: BillingEventData[]) => {
      return events.map((event) => ({
        status: eventStatusTranslation[event.status],
        protocol_number: event.protocol_number,
        bill: '',
        eventable_type: eventableTypeTranslations[event.eventable_type] || 'Nao implementado',
        details: eventableDetailsComponent[event.eventable_type] ? React.createElement(eventableDetailsComponent[event.eventable_type], { data: event.eventable }) : '',
        total_amount: event.total_amount ? formatCurrency(event.total_amount) : '',
        fee_total_amount: <FeesDetails {...event} />,
        incode_tax_total_amount: '',
        branch: event.branch_name || '',
        card: event.eventable?.card?.last_four_digits.padStart(8, '*') || '',
        author: event?.author?.full_name || '',
        buyer: event?.eventable?.buyer?.full_name || '',
        created_at: formatDateTime(new Date(event?.created_at || 0))
      }));
    };
  }

  static feesDetailsTableTransformer() {
    return (fees: BillingEventData['fees']) => {
      return fees?.map((fee) => {
        const totalFee = Number(fee.amount);
        return ({
          amount: (
            <p className={twMerge(totalFee > 0 ? 'text-success' : totalFee < 0 ? 'text-danger-soft' : '')}>
              {formatCurrency(totalFee)}
            </p>
          ),
          display_name: fee?.fee_source?.fee_template?.display_name ? (
            <div className="flex gap-2 items-center">
              <PiggyBankIcon strokeWidth={0.6} className="w-5 h-5" />

              <div>
                <p>{fee.fee_source.fee_template.display_name}</p>
              </div>
            </div>
          ) : '',
          product_display_name: fee?.source?.display_name ? (
            <div className="flex gap-2 items-center">
              <img src="/Paper.svg" />

              <div>
                <p>{fee.source.display_name}</p>
              </div>
            </div>
          ) : '',
        });
      }) || [];
    };
  }
}

const eventStatusTranslation: { [key: string]: string; } = {
  committed: 'Efetivado'
};
const eventableDetailsComponent: { [key: string]: React.ComponentType<any>; } = {
  'Client::Consumption': ConsumptionDetails,
  'Billing::Refund': RefundDetails,
};

const eventableTypeTranslations: { [key: string]: string; } = {
  'Client::Consumption': 'Consumo',
  'Billing::Refund': 'Estorno',
};
