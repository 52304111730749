import Accordion from '@/common/components/Accordion';
import Select from '@/common/components/Select';
import { useProducts } from '@/common/hooks/queries/useProducts';
import useDebounce from '@/common/hooks/useDebounce';
import { Mapper } from '@/common/services/mapper';
import { Option } from '@/common/types/option';
import { ProductData } from '@/common/types/product.type';
import { InfoIcon } from 'lucide-react';
import { Control, FieldErrors, useFieldArray, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { EquipmentValidationSchema } from '../../schemas/create.schema';
import { ApplicableProduct } from './ApplicableProduct';

interface ApplicableProductsProps {
  control: Control<EquipmentValidationSchema, any>;
  register: UseFormRegister<EquipmentValidationSchema>;
  watch: UseFormWatch<EquipmentValidationSchema>;
  errors: FieldErrors<EquipmentValidationSchema>;
  disabled?: boolean;
}

export const ApplicableProducts = ({
  control,
  register,
  watch,
  errors,
  disabled,
}: ApplicableProductsProps) => {
  const [searchProducts, setSearchProducts] = useDebounce('');

  const {
    fields: applicableProductsFields,
    append: appendApplicableProduct,
    remove: removeApplicableProduct,
  } = useFieldArray({
    control,
    name: `card_product_limits_attributes`,
  });

  const {
    data: products,
  } = useProducts<ProductData[]>({
    filters: {
      search: searchProducts,
    },
  });

  const handleAppendApplicableProduct = (option: Option) => {
    if (option.value) {
      appendApplicableProduct({
        product_id: option.value,
        display_name: option.label,
        unit_for_quantity: products.find((product) => product.id === option.value)?.unit_for_quantity || '',
      });
    }
  };

  return (
    <Accordion
      contentClassName={twMerge('pb-4', !applicableProductsFields.length && 'pb-64')}
      head={(
        <p>Produtos aplicáveis</p>
      )}
      body={(
        <div className="flex w-full flex-col gap-y-4">
          <Select
            placeholder="Pesquisar produtos"
            options={Mapper.mapToOptions<ProductData>({ labelFieldName: 'display_name', valueFieldName: 'id' })(products)}
            value=""
            onInputChange={(value) => setSearchProducts(value)}
            onChange={(option) => handleAppendApplicableProduct(option as Option)}
            className="p-px"
            disabled={disabled}
          />
          <div className="flex w-full items-center gap-x-2">
            <InfoIcon strokeWidth={1} className="text-yellow-600" />
            <div>
              <p className="font-bold text-[10px] leading-3">Ao selecionar um Produto, todos os não selecionados serão bloqueados para consumo</p>
              <p className="font-light text-[10px] italic leading-3">Certifique-se de adicionar as restrições para todos os produtos que desejar consumir.</p>
            </div>
          </div>
          {applicableProductsFields.map((applicableProduct, applicableProductIndex) => {
            const limitByTransaction = watch(`card_product_limits_attributes.${applicableProductIndex}.limitByTransaction`);
            const limitByPeriod = watch(`card_product_limits_attributes.${applicableProductIndex}.limitByPeriod`);

            return (
              <ApplicableProduct
                key={applicableProduct.id}
                applicableProduct={applicableProduct}
                applicableProductIndex={applicableProductIndex}
                removeApplicableProduct={removeApplicableProduct}
                limitByTransaction={limitByTransaction}
                limitByPeriod={limitByPeriod}
                control={control}
                register={register}
                errors={errors}
                disabled={disabled}
              />
            );
          })}
        </div>
      )}
    />
  );
};
