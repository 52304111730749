import { optionsToStrategy } from '@/common/utils/optionsToStrategy'

export const options = [
  { label: 'Não respondido', value: 'pending' },
  { label: 'Aguardando resposta do cliente', value: 'awaiting_customer_response' },
  { label: 'Aguardando resposta do TCard', value: 'awaiting_tcard_response' },
  { label: 'Resolvido', value: 'closed' },
]

export const strategy = optionsToStrategy(options)
