
export class ErrorsMapper {
  static default() {
    return (errors: Record<string, string[]>) => Object.entries(errors).map(([key, value]) => ({
      [key]: Array.isArray(value) ? value.join(', ') : value,
    }));
  }

  static mapWithTransformer<R>({ transformer }: { transformer: (errors: Record<string, string[]>) => R; }) {
    return (errors: Record<string, string[]>) => transformer(errors);
  }
}
