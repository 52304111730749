import { FC, HTMLAttributes } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "../../utils/cn";

const paginationButtonVariants = cva(
  'flex justify-center items-center rounded-md underline text-xs',
  {
    variants: {
      variant: {
        default: '',
        selected: 'text-blue-800',
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export interface PaginationButtonProps extends HTMLAttributes<HTMLButtonElement>, VariantProps<typeof paginationButtonVariants> {
  disabled?: boolean;
}

const PaginationButton: FC<PaginationButtonProps> = ({ variant, className, disabled, ...props }) => {
  return <button
    className={cn(paginationButtonVariants({ className, variant }))}
    type="button"
    disabled={disabled}
    {...props}
  />;
};

export default PaginationButton;
