import axios from "axios";

export const publicAxios = axios.create({
  baseURL: `${import.meta.env.VITE_BASE_URL}/api/client`
});

export const privateAxios = axios.create({
  baseURL: `${import.meta.env.VITE_BASE_URL}/api/client`,
  headers: { 'Content-type': 'application/json' },
  withCredentials: true
});
