import Button from '@/common/components/Button';
import { ShowHeader } from '@/common/components/ShowHeader';
import Tabs from '@/common/components/Tabs';
import { Link, useParams } from 'react-router-dom';
import useEquipment from '../../hooks/useEquipment';
import { EquipmentData } from '../../components/show/equipmentData';
import { ConsumptionHistory } from '../../components/show/consumptionHistory';
import EquipmentLogChanges from '../../components/show/changeHistory';

const EquipmentsShow = () => {
  const { id: equipmentId } = useParams();

  const { data: equipment } = useEquipment({ id: equipmentId || '' });

  return (
    <div>
      <ShowHeader
        backTo='/equipments'
        logoUrl={equipment?.photos?.[0]?.url}
        displayName={equipment?.license_plate || equipment?.official_name || 'Não informado'}
        buttons={(
          <Link to={`/equipments/${equipmentId}/edit`}>
            <Button type="button" className="shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)]">
              Editar
            </Button>
          </Link>
        )}
      />

      <Tabs
        tabsListClassName="w-full bg-transparent rounded-none"
        tabsTriggerClassName="h-8 w-full rounded-none border-b border-[#444444] text-white-800 text-xs data-[state=active]:bg-transparent data-[state=active]:text-white-800 data-[state=active]:shadow-none data-[state=active]:border-blue-800"
        tabsContentClassName="mt-6"
        tabsList={[
          {
            label: 'Dados',
            value: '',
            body: <EquipmentData equipmentId={equipmentId} equipment={equipment} />,
          },
          {
            label: 'Histórico de consumo',
            value: 'consumptionHistory',
            body: <ConsumptionHistory />,
          },
          {
            label: 'Histórico de mudanças',
            value: 'changeHistory',
            body: <EquipmentLogChanges equipmentId={equipmentId} />,
          },
        ]}
      />
    </div>
  );
};

export default EquipmentsShow;
