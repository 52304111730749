import React, { createElement } from "react";
import { formatCardNumber, formatCurrency, formatDateTime, formatDecimal, formatUnit } from "@/common/utils/formatters";
import { UserEventData } from "../types/events.type";
import ConsumptionDetails from "@/features/billingEvents/components/ConsumptionDetails";
import RefundDetails from "@/features/billingEvents/components/RefundDetails";
import { BillingEventData } from "@/common/types/billing/event.type";
import ClientEventTotalFee from "@/features/users/components/clientConsumptionTotalFee";
import CompanyEventAuthor from "@/common/components/CompanyEventAuthor";

export class ClientUserEventTransformer {
  static tableTransformer() {
    return (userEvents: UserEventData[]) => {
      return userEvents.map((userEvent) => ({
        status: userEvent.status ? eventStatusTranslation[userEvent.status] : '',
        protocol_number: userEvent.protocol_number ? `${userEvent.protocol_number}` : '',
        bill: 'Não implementado',
        eventable_type: userEvent.eventable_type ? eventableTypeTranslations[userEvent.eventable_type] : '',
        details: eventableDetailsComponent[userEvent.eventable_type]
          ? createElement(eventableDetailsComponent[userEvent.eventable_type], { data: userEvent.eventable })
          : '',
        total_amount: userEvent.eventable?.total_amount
          ? formatCurrency(parseFloat(userEvent.eventable?.total_amount))
          : '',
        fee_total_amount: userEvent.fee_total
          ? createElement(ClientEventTotalFee, { data: userEvent as unknown as BillingEventData })
          : '',
        tax: 'Não implementado',
        branch: userEvent.eventable?.establishment_branch?.display_name || '',
        card: userEvent.eventable.card.last_four_digits
          ? formatCardNumber(userEvent.eventable.card.last_four_digits)
          : '',
          usage_declaration: userEvent.eventable?.current_equipment_usage
          ? formatUnit(formatDecimal(userEvent.eventable?.current_equipment_usage, 0), 'kilometers')
          : '',
        author: userEvent.author
          ? createElement(CompanyEventAuthor, { data: userEvent.author })
          : '',
        created_at: userEvent.created_at ? formatDateTime(new Date(userEvent.created_at)) : '',
      }))
    }
  }
}

const eventableDetailsComponent: { [key: string]: React.ComponentType<any>; } = {
  'Client::Consumption': ConsumptionDetails,
  'Billing::Refund': RefundDetails,
};

const eventStatusTranslation: { [key: string]: string; } = {
  committed: 'Efetivado'
};

const eventableTypeTranslations: { [key: string]: string; } = {
  'Client::Consumption': 'Consumo',
  'Billing::Refund': 'Estorno',
};
