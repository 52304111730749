import { formatCurrency } from '@/common/utils/formatters';
import movementsTotalizerResumeContant from '../constants/movementsTotalizerResume.contant';
import { MovementsTotalizerData } from '../types/movementTotalizer.type';

interface ResumeListProps {
  data: MovementsTotalizerData;
}

const ResumeList = ({ data }: ResumeListProps) => {
  return (
    <div className="flex gap-6">
      {movementsTotalizerResumeContant(data).map(({ label, value, textClassName = "" }, index) => (
        <div key={index}>
          <p className={`font-light italic text-[14px] ${textClassName}`}>{label}</p>
          <p className={`text-[18px] ${textClassName}`}>{formatCurrency(Number(value) || 0)}</p>
        </div>
      ))}
    </div>
  );
};

export default ResumeList;
