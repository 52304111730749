import { zodResolver } from "@hookform/resolvers/zod"
import TextArea from "@/common/components/TextArea"
import Modal from "@/common/components/Modal"
import { Dispatch} from "react"
import { Controller, useForm } from "react-hook-form"
import MaskedTextField from "@/common/components/MaskedTextField"
import { customUnitMask } from "@/common/constants/masks.constant"
import { editEquipmentUsageFormSchema, EditEquipmentUsageValidationSchema } from "../schemas/editEquipmentUsage.schema";
import useUpdateEquipmentUsage from "../hooks/useUpdateEquipmentUsage";
import { unities } from "@/common/constants/unities.constants";

type EditEquipmentUsageModalProps = {
  setIsOpen: Dispatch<React.SetStateAction<boolean>>
  isOpen: boolean
  equipmentId: string
  initialValue?: number
  unitForConsumption?: string
}

const EditEquipmentUsageModal = ({ setIsOpen, isOpen, equipmentId, initialValue, unitForConsumption }: EditEquipmentUsageModalProps) => {
  const { mutate: updateEquipmentUsage } = useUpdateEquipmentUsage({
    equipmentId,
    onSuccess: () => {
      setIsOpen(false)
    }
  })

  const { register, handleSubmit, control, formState: { errors } } = useForm<EditEquipmentUsageValidationSchema>({
    resolver: zodResolver(editEquipmentUsageFormSchema),
    defaultValues: {
      value: initialValue,
      reason: ""
    }
  })

  const onSubmit = (form: EditEquipmentUsageValidationSchema) => updateEquipmentUsage(form);

  return (
    <Modal
      isOpen={isOpen}
      title="Justificativa de ajuste"
      titleClass="text-[24px]"
      footer="Confirmar justificativa e salvar alteração"
      subtitle="Preencha o motivo para alteração."
      subtitleClassName="mt-4"
      setIsOpen={setIsOpen}
      onConfirm={handleSubmit(onSubmit)}
      body={(
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <MaskedTextField
                markAsRequired
                className="text-white-800"
                label="Novo Valor"
                mask={customUnitMask(unities[unitForConsumption || 'kilometers'], 999999)}
                errorMessage={errors.value?.message}
                onBlur={onBlur}
                value={value?.toString()}
                onChange={({target: { value }}) => (onChange(parseInt(value)))}
              />
            )}
            name="value"
          />
          <TextArea
            className="mt-6 text-white-800"
            label="Justificativa"
            markAsRequired
            placeholder="Escreva aqui sua justificativa"
            errorMessage={errors.reason?.message}
            {...register("reason")}
          />
        </form>
      )}
    />
  )
}

export default EditEquipmentUsageModal
