import { privateAxios } from "@/common/api/axios";
import { defaultTransformerResponse } from "@/common/constants/defaultTransformerResponse.constant";
import { Mapper } from "@/common/services/mapper";
import { MetaResponse } from "@/common/types/metaResponse";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

type UseCompanyBranchesProps<T> = {
  filters?: {
    search?: string;
    pageSize?: number;
  };
  mapper?: (data: any) => T,
};

export const useCompanyBranches = <T,>({
  filters: { search = '', pageSize = 50 } = {},
  mapper = Mapper.default<T>(),
}: UseCompanyBranchesProps<T>) => {
  const {
    data: { data, meta } = defaultTransformerResponse<T>(),
    ...rest
  } = useQuery({
    queryKey: ['companyBranches', search, pageSize],
    queryFn: () => {
      return privateAxios.get('/company_branches', {
        params: {
          page_size: pageSize,
          search
        }
      });
    },
    select: (data: { data: { data: any[], meta: MetaResponse; }; }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      };
    },
    placeholderData: keepPreviousData,
  });

  return { data, meta, ...rest };
};
