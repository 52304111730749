import { LogChange } from "@/common/types/logs/change.type";
import ChangesRow from "./ChangesRow";
import React from "react";

interface ChangesColumnProps {
  id: string;
  changes: LogChange['object_changes_translated'];
}

const ChangesColumn = ({ id, changes }: ChangesColumnProps) => {
  return (
    <div className="flex flex-col">
      {Object.entries(changes).map(([field, diff], index) => (
        <React.Fragment key={field}>
          {index > 0 && <div className="h-[1px] bg-white-200" />}

          <ChangesRow id={id} field={field} diff={diff} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default ChangesColumn;
