import Checkbox from "@/common/components/Checkbox";
import TextField from "@/common/components/TextField";

type MasterClientUserProps = {
  masterClientUser?: {
    id: string;
    full_name: string;
  };
};

export const MasterClientUser = ({
  masterClientUser,
}: MasterClientUserProps) => {
  return (
    <div className="flex flex-col gap-4">
      <p className="font-bold text-lg">Responsável</p>
      <TextField
        label='Gestor de frota'
        value={masterClientUser?.full_name}
        disabled
      />
      <div className="flex gap-2">
        <Checkbox
          label="Permitir Gestor editar as restrições de consumo da própria filial (Não implementado)"
          labelClassName="italic text-[10px]"
          name="notimplemented1"
          disabled
        />
        <Checkbox
          label="Permitir Gestor alterar restrições de consumo das filiais abaixo (Não implementado)"
          labelClassName="italic text-[10px]"
          name="notimplemented1"
          disabled
        />
      </div>
    </div>
  );
};
