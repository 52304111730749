import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { UserResponse, UserData } from "@/common/types/users";
import usePrivateAxios from "../api/usePrivateAxios";

interface UseUserProps {
  id: string;
  staleTime?: number;
}

const useUser: (props: UseUserProps) => UseQueryResult<UserData, unknown> = ({ id, staleTime }) => {
  const privateAxios = usePrivateAxios();

  return useQuery<UserResponse, unknown, UserData>({
    queryKey: ['user', id],
    queryFn: () => {
      return privateAxios.get(`/users/${id}`);
    },
    select: (data) => data?.data?.data,
    staleTime
  });
};

export default useUser;
