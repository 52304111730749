import Accordion from "@/common/components/Accordion";
import Button from "@/common/components/Button";
import { Sheet, SheetClose, SheetContent, SheetTitle, SheetTrigger } from "@/common/components/ui/sheet";
import { Option } from "@/common/types/option";
import { ChevronLeftIcon, FilterIcon } from "lucide-react";

interface Filter {
  label: string;
  options: (Option & { selected?: boolean, onClick?: () => void; })[];
}

interface SheetFiltersProps {
  filters: Filter[];
}

export const SheetFilters = ({ filters }: SheetFiltersProps) => {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button className="flex h-8 gap-x-2 items-center font-light text-xs italic rounded-xl bg-blue-200 text-white-800 hover:bg-blue-300 shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.5)]">
          Ver mais
          <FilterIcon className="size-4" />
        </Button>
      </SheetTrigger>
      <SheetContent className="w-72 bg-black-300 text-white-800 border-none backdrop-blur-3xl p-0" showClose={false}>
        <div className="flex items-center gap-x-2 mx-4 my-8">
          <SheetClose asChild>
            <Button variant="outline-text" className="flex items-center justify-center size-8 p-0">
              <ChevronLeftIcon className="size-8" strokeWidth={1.33} />
            </Button>
          </SheetClose>
          <SheetTitle className="font-bold text-xl">Filtro</SheetTitle>
        </div>
        <div className="overflow-y-auto no-scrollbar max-h-[calc(100vh-96px)]">
          {filters.map((filter, idx) => {
            return (
              <Accordion
                key={idx}
                className="rounded-none p-0"
                triggerClassName="px-4"
                contentClassName="bg-black-900 overflow-y-auto max-h-[232px]"
                head={
                  <p className="font-light text-sm italic">{filter.label}</p>
                }
                body={(
                  <div className="pt-4 px-4">
                    {filter.options.map(({ label, value, selected, onClick }) => (
                      <div
                        key={value}
                        className="flex items-center gap-x-2 py-2 cursor-pointer"
                        onClick={onClick}
                      >
                        {selected && <div className="w-1 h-5 shrink-0 bg-blue-800 rounded-full" />}
                        <p className="font-light text-sm">{label}</p>
                      </div>
                    ))}
                  </div>
                )}
              />
            );
          })}
        </div>
      </SheetContent>
    </Sheet>
  );
};
