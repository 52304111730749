import { memo, useEffect, useRef, useState } from 'react';
import { Handle, Position, NodeToolbar } from '@xyflow/react';
import { formatCurrency, formatDateTime } from '@/common/utils/formatters';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Spinner from '@/common/components/Spinner';
import { useCompanyBranch } from '@/common/hooks/queries/useCompanyBranch';
import { ImageWithPlaceholder } from '@/common/components/ImageWithPlaceholder';
import { BriefcaseBusinessIcon } from 'lucide-react';

interface CustomNodeProps {
  data: {
    companyId: string, display_name: string, id: string, parent_id?: string, parentRef: React.RefObject<HTMLDivElement>;
  };
}

const CustomNode = ({ data }: CustomNodeProps) => {
  const requested = useRef(false);
  const nodeRef = useRef<HTMLDivElement>(null);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [enableRequest, setEnableRequest] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<'top' | 'bottom'>('top');
  const [tooltipStyle, setTooltipStyle] = useState({ left: '0px' });

  const { data: branchData, isPending: isLoadingBranchData } = useCompanyBranch(data.id || '', enableRequest);

  const tooltipWidth = 216;

  useEffect(() => {
    if (tooltipOpen && nodeRef.current && data.parentRef.current) {
      const nodeRect = nodeRef.current.getBoundingClientRect();
      const parentRect = data.parentRef.current.getBoundingClientRect();

      const spaceTop = nodeRect.top - parentRect.top;
      const spaceBottom = parentRect.bottom - nodeRect.bottom;

      if (spaceTop > spaceBottom || spaceTop > 245) {
        setTooltipPosition('top');
      } else {
        setTooltipPosition('bottom');
      }

      const spaceLeft = nodeRect.left - parentRect.left;
      const spaceRight = parentRect.right - nodeRect.right;

      let adjustedLeft = 0;

      if (spaceRight < tooltipWidth / 2) {
        adjustedLeft = -(tooltipWidth / 2 - spaceRight - nodeRect.width / 2);
      } else if (spaceLeft < tooltipWidth / 2) {
        adjustedLeft = Math.max(adjustedLeft, tooltipWidth / 2 - spaceLeft - nodeRect.width / 2);
      }

      setTooltipStyle({ left: `${adjustedLeft}px` });
    }
  }, [tooltipOpen]);

  return (
    <div
      ref={nodeRef}
      className="relative px-4 py-2 hover:bg-white-200 rounded-md cursor-pointer"
      onMouseOver={() => {
        if (!requested.current) {
          setEnableRequest(true);
          requested.current = true;
        }
        setTooltipOpen(true);
      }}
      onMouseLeave={() => setTooltipOpen(false)}
    >
      <NodeToolbar
        isVisible={tooltipOpen}
        position={tooltipPosition as Position}
        style={tooltipStyle}
      >
        <div className={`w-[${tooltipWidth}px] py-4 flex flex-col gap-2 bg-white-200 backdrop-blur-lg z-50 rounded-md `}>
          {isLoadingBranchData ? (
            <div className='h-[156px] content-center'>
              <Spinner className="m-auto w-[216px] my-auto" />
            </div>
          ) : (
            <div className='px-4 flex flex-col gap-2'>
              <div className='flex gap-2 items-center'>
                <ImageWithPlaceholder
                  placeholderIcon={BriefcaseBusinessIcon}
                  url={branchData?.general_information?.company_logo?.url}
                  alt={branchData?.general_information?.fantasy_name}
                />
                <p className='font-bold'>{branchData?.display_name}</p>
              </div>

              <p className='text-xs font-light italic'>Garantido: <span className='font-bold not-italic'>{formatCurrency(Number(branchData?.granted_credit || 0))}</span></p>
              <p className='text-xs font-light italic text-yellow-400'>Consumido: <span className='font-bold not-italic'>{formatCurrency(Number(branchData?.consumed_credit || 0))}</span></p>
              <p className='text-xs font-light italic text-valid'>Restante: <span className='font-bold not-italic'>{formatCurrency(Number(branchData?.remaining_credit || 0))}</span></p>

              <div>
                <p className='italic font-light text-sm'>Equipamentos</p>
                <p className='text-xs underline'>{Number(branchData?.equipments_count || 0)}</p>
              </div>

              <div>
                <p className='italic font-light text-sm'>Usuários</p>
                <p className='text-xs underline'>{Number(branchData?.client_users_count || 0)}</p>
              </div>

              <p className='text-[8px] italic font-light'>{`Cadastrado em ${formatDateTime(new Date(branchData?.created_at || 0))}`}</p>
            </div>
          )}
        </div>
      </NodeToolbar>

      <Link to={`/company-branches/${data.id}`} className="flex items-center p-2">
        <ImageWithPlaceholder
          className={twMerge('size-6', !data.parent_id && 'size-8')}
          iconClassName={twMerge('size-4', !data.parent_id && 'size-6')}
          placeholderIcon={BriefcaseBusinessIcon}
          url={branchData?.general_information?.company_logo?.url}
          alt={branchData?.general_information?.fantasy_name}
        />

        <div className="ml-2 flex-1 max-w-44">
          <div className={twMerge("text-lg font-bold break-words whitespace-normal", !data.parent_id && 'text-3xl')}>
            {data.display_name}
          </div>
        </div>
      </Link>

      <Handle
        type="target"
        position={Position.Top}
        className='invisible'
      />

      <Handle
        type="source"
        position={Position.Bottom}
        className='invisible'
      />
    </div>
  );
};

export default memo(CustomNode);
