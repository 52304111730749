import Button from "@/common/components/Button";
import SelectController from "@/common/components/SelectController";
import { Plus, Trash2 } from "lucide-react";
import { Control, FieldErrors, useFieldArray, UseFormWatch } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { v4 as uuid } from 'uuid';
import { EquipmentValidationSchema } from "../../schemas/create.schema";
import { periodOptions } from "@/common/constants/periodRestrictions.constant";

interface IntervalProps {
  periodRestrictionIndex: number;
  control: Control<EquipmentValidationSchema, any>;
  watch: UseFormWatch<EquipmentValidationSchema>;
  errors: FieldErrors<EquipmentValidationSchema>;
  disabled?: boolean;
}

export const PeriodRestrictionIntervals = ({
  periodRestrictionIndex,
  control,
  watch,
  errors,
  disabled,
}: IntervalProps) => {
  const { fields: intervalFields, append, remove } = useFieldArray({
    control,
    name: `period_restrictions_attributes.${periodRestrictionIndex}.intervals_attributes`,
  });

  return (
    <>
      {intervalFields.map((_, intervalIndex) => {
        const startTime = watch(`period_restrictions_attributes.${periodRestrictionIndex}.intervals_attributes.${intervalIndex}.start_time`);
        const id = uuid();

        if (watch(`period_restrictions_attributes.${periodRestrictionIndex}.intervals_attributes.${0}.start_time`)?.value === 'all_day' && intervalIndex !== 0)
          return null;

        return (
          <div key={id} className="flex gap-2">
            <SelectController
              control={control}
              name={`period_restrictions_attributes.${periodRestrictionIndex}.intervals_attributes.${intervalIndex}.start_time`}
              label="Horário permitido (Início)"
              className={twMerge("w-[182px]", startTime?.value === 'all_day' && 'w-[372px] mr-16')}
              options={[(intervalIndex === 0 ? { label: '24 horas', value: 'all_day' } : { isDisabled: true } as { label: string, value: string, isDisabled: boolean; }), ...periodOptions]}
              placeholder="Selecione um."
              markAsRequired
              errorMessage={errors?.period_restrictions_attributes?.[periodRestrictionIndex]?.intervals_attributes?.[intervalIndex]?.start_time?.message || undefined}
              isDisabled={disabled}
            />

            <SelectController
              control={control}
              name={`period_restrictions_attributes.${periodRestrictionIndex}.intervals_attributes.${intervalIndex}.end_time`}
              label="Horário permitido (Fim)"
              className={twMerge("w-[182px]", startTime?.value === 'all_day' && 'hidden')}
              options={[...periodOptions, { label: '23:59', value: '23:59' }]}
              placeholder="Selecione um."
              markAsRequired
              errorMessage={errors?.period_restrictions_attributes?.[periodRestrictionIndex]?.intervals_attributes?.[intervalIndex]?.end_time?.message || undefined}
              isDisabled={disabled}
            />

            {intervalIndex === 0 ? (
              <Button
                type="button"
                className={startTime?.value === 'all_day' ? 'hidden' : ''}
                variant="outline-error"
                onClick={() => append({ start_time: null, end_time: null })}
                disabled={disabled}
              >
                <Plus color="#7CC4F8" />
              </Button>
            ) : startTime?.value !== 'all_day' ? (
              <Button
                type="button"
                variant="outline-error"
                onClick={() => remove(intervalIndex)}
                disabled={disabled}
              >
                <Trash2 color="#E03E1A" />
              </Button>
            ) : null}
          </div>
        );
      })}
    </>
  );
};
