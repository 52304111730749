import { privateAxios } from '@/common/api/axios';
import { logRelatedTypes } from '@/common/constants/logRelatedTypes';
import { defaultTransformerResponse } from '@/common/constants/defaultTransformerResponse.constant';
import { Mapper } from '@/common/services/mapper';
import { MetaResponse } from '@/common/types/metaResponse';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

type UseEquipmentLogChanges<T> = {
  equipmentId?: string;
  filters?: {
    search?: string;
    page?: number;
    relatedToOrItemId?: string;
    createdAtGt?: string;
    createdAtLt?: string;
  };
  mapper?: (data: any) => T;
};

const useEquipmentLogChanges = <T,>({
  equipmentId,
  filters: { search = '', page, relatedToOrItemId, createdAtGt, createdAtLt } = {},
  mapper = Mapper.default<T>(),
}: UseEquipmentLogChanges<T>) => {
  const {
    data: { data, meta } = defaultTransformerResponse<T>(),
    isPending,
    ...rest
  } = useQuery({
    enabled: !!equipmentId,
    queryKey: ['equipmentLogChanges', equipmentId, search, page, relatedToOrItemId, createdAtGt, createdAtLt],
    queryFn: () => {
      return privateAxios.get(`/equipments/${equipmentId}/log_changes`, {
        params: {
          search,
          page,
          'q[related_to_or_item_type]': logRelatedTypes['ClientEquipment'],
          'q[related_to_or_item_id]': relatedToOrItemId,
          'q[created_at_gteq]': createdAtGt,
          'q[created_at_lteq]': createdAtLt,
        },
      });
    },
    select: (data: { data: { data: any[]; meta: MetaResponse; }; }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      };
    },
    placeholderData: keepPreviousData,
  });

  return { data, meta, isPending, ...rest };
};

export default useEquipmentLogChanges;
