import { privateAxios } from "@/common/api/axios";
import { EstablishmentCompanyData, EstablishmentCompanyResponse } from "@/features/company/types/company.type";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

const useEstablishmentCompany: (id: string , enabled?: boolean) => UseQueryResult<EstablishmentCompanyData, unknown> = (id, enabled = true) => {
  return useQuery<EstablishmentCompanyResponse, unknown, EstablishmentCompanyData>({
    queryKey: ["establishmentCompany", id],
    queryFn: () => privateAxios.get(`/establishment/companies/${id}`),
    enabled,
    select: (data) => data?.data?.data,
  });
};

export default useEstablishmentCompany;
