import Checkbox from "@/common/components/Checkbox";
import SelectController from "@/common/components/SelectController";
import { useCompanyUsers } from "@/common/hooks/queries/useCompanyUsers";
import useDebounce from "@/common/hooks/useDebounce";
import { Mapper } from "@/common/services/mapper";
import { FieldErrors, Control } from "react-hook-form";
import { CompanyUserData } from "../../types/companyUsers.type";
import { CompanyBranchValidationSchema } from "../../schemas/companyBranch.schema";

interface MasterClientUserProps {
  companyId?: string;
  errors: FieldErrors<CompanyBranchValidationSchema>;
  control: Control<CompanyBranchValidationSchema, any>;
}

export const MasterClientUser = ({
  companyId,
  errors,
  control,
}: MasterClientUserProps) => {
  const [search, setSearch] = useDebounce('');

  const { data: companyUserOptions } = useCompanyUsers({
    filters: {
      search,
      companyId: companyId || '',
    },
    mapper: Mapper.mapToOptions<CompanyUserData>({ labelFieldName: 'full_name', valueFieldName: 'id' }),
  });

  return (
    <div className="flex flex-col gap-4">
      <p className="font-bold text-lg">Responsável</p>
      <SelectController
        control={control}
        name="master_client_user_id"
        label='Gestor de frota'
        markAsRequired
        errorMessage={errors?.master_client_user_id?.message || undefined}
        onInputChange={(newValue) => setSearch(newValue)}
        options={companyUserOptions || []}
      />
      <div className="flex gap-2">
        <Checkbox
          label="Permitir Gestor editar as restrições de consumo da própria filial (Não implementado)"
          labelClassName="italic text-[10px]"
          name="notimplemented1"
          disabled
        />
        <Checkbox
          label="Permitir Gestor alterar restrições de consumo das filiais abaixo (Não implementado)"
          labelClassName="italic text-[10px]"
          name="notimplemented1"
          disabled
        />
      </div>
    </div>
  );
};
