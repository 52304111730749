export const getRailsUpdate = <T extends object, J extends object>(
  currentList: T[],
  updatedList: J[],
  accessCurrentListId: (x: T) => string,
  accessUpdatedListId: (x: J) => string,
  transformDeleted: (x: T) => any,
  transformNew: (x: J) => any,
) => {
  const updatedListIds = updatedList.map(accessUpdatedListId);
  const currentListIds = currentList.map(accessCurrentListId);
  const deletedItems = currentList
    .filter((item) => {
      return !updatedListIds.includes(accessCurrentListId(item));
    })
    .map(transformDeleted);
  const newItems = updatedList
    .filter((item) => {
      return !currentListIds.includes(accessUpdatedListId(item));
    })
    .map(transformNew);
  return [...deletedItems, ...newItems];
};
