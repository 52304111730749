import { privateAxios } from "@/common/api/axios";
import { defaultTransformerResponse } from "@/common/constants/defaultTransformerResponse.constant";
import { Mapper } from "@/common/services/mapper";
import { MetaResponse } from "@/common/types/metaResponse";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

type UseEquipmentModelsProps<T> = {
  filters?: {
    search?: string,
    page?: number,
    category?: string,
    year?: string;
  };
  mapper?: (data: any) => T,
};

export const useEquipmentModels = <T,>({
  filters: { search = '', page, category, year } = {},
  mapper = Mapper.default<T>(),
}: UseEquipmentModelsProps<T>) => {
  const {
    data: { data, meta } = defaultTransformerResponse<T>(),
    isPending,
    ...rest
  } = useQuery({
    queryKey: ['equipmentModels', search, page, category, year],
    queryFn: () => {
      return privateAxios.get('/equipment_models', {
        params: {
          search,
          page,
          'q[category_id_eq]': category,
          'q[year_eq]': year
        }
      });
    },
    select: (data: { data: { data: any[], meta: MetaResponse; }; }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      };
    },
    placeholderData: keepPreviousData,
  });

  return { data, meta, isPending, ...rest };
};
