import { optionsToStrategy } from "@/common/utils/optionsToStrategy";

export const options = [
  { label: 'Litros', value: 'liter', symbol: 'L' },
  { label: 'Unidade', value: 'item', symbol: 'un' },
  { label: 'Gramas', value: 'gram', symbol: 'g' },
  { label: 'Metros cubicos', value: 'cubic_meter', symbol: 'm²' },
];

export const strategy = optionsToStrategy(options);
