import { useCallback, useState, useEffect, useMemo } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Building, Search } from 'lucide-react';
import Box from '@/common/components/Box';
import { brasiliaCoordinates, loaderOptions, mapOptions } from './constants/constant';
import { useEstablishmentClusters } from './hooks/useEstablishmentClusters';
import { createClusterMarkerIcon } from './components/clusterSvg';
import { Cluster } from './types/cluster';
import useEstablishmentCompany from '@/features/establishments/hooks/useEstablishmentCompany';
import { MarkerTooltip } from './components/MarkerTooltip';
import TextField from '@/common/components/TextField';
import { EstablishmentClustersFilterProps } from './types/clusterFilters';
import ListHeader from '@/common/components/ListHeader';
import { useFilterParams } from '@/common/hooks/useFilterParams';

const Map = () => {
  const { isLoaded, loadError } = useJsApiLoader(loaderOptions);
  const { filterParams, setFilterParams } = useFilterParams();

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [hasCalledBoundsChange, setHasCalledBoundsChange] = useState(false);
  const [center, setCenter] = useState<google.maps.LatLngLiteral | null>(null);
  const [hoveredMarkerId, setHoveredMarkerId] = useState('');

  const { data, dataUpdatedAt } = useEstablishmentClusters(filterParams as EstablishmentClustersFilterProps);

  const { data: establishment, isPending: isLoadingEstablishmentData } = useEstablishmentCompany(hoveredMarkerId, !!hoveredMarkerId);

  const identifiedClusters = useMemo(
    () =>
      data?.map(cluster => {
        return {
          ...cluster,
          name: `marker-${cluster.latitude}-${cluster.longitude}-${cluster.establishment_count}`,
        };
      }),
    [dataUpdatedAt],
  );

  const handleGetInitialZoom = () => {
    if (center?.lat === brasiliaCoordinates.lat && center.lng === brasiliaCoordinates.lng) {
      return 6; // Case when the browser has no access to location
    } else {
      return 15;
    }
  };

  const handleMarkerClick = (cluster: Cluster) => {
    if (cluster.establishment_count > 1) {
      const newZoom = map?.getZoom()! + 1;

      map?.setZoom(newZoom);
      map?.setCenter({ lat: cluster.latitude, lng: cluster.longitude });
    }
  };

  const handleOnMouseOver = (cluster: Cluster & { name: string; }) => {
    if (cluster.establishment_count === 1) {
      setHoveredMarkerId(cluster.establishment_company_id);
    }
  };

  const handleMapBoundsChange = useCallback((cameFromOnBound?: boolean) => {
    if (cameFromOnBound) {
      setHasCalledBoundsChange(true);
    }

    setHoveredMarkerId('');
    const bounds = map?.getBounds();

    if (bounds) {
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();

      setFilterParams((prevParams) => ({
        ...prevParams,
        top_left_lat: northEast.lat(),
        top_left_lng: southWest.lng(),
        bottom_right_lat: southWest.lat(),
        bottom_right_lng: northEast.lng(),
      }));
    }
  }, [map, setFilterParams]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenter({ lat: latitude, lng: longitude });
        },
        (error) => {
          setCenter(brasiliaCoordinates);
          console.error('Error getting location', error);
        }
      );
    } else {
      setCenter(brasiliaCoordinates);
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  if (!isLoaded || loadError || !center) {
    return (
      <Box className="bg-transparent flex w-full h-full justify-center items-center">
        <div>Carregando mapa...</div>
      </Box>
    );
  }

  return (
    <div className={`flex h-screen -m-8`}>
      <div className="flex flex-col gap-6 absolute z-20 p-8">
        <ListHeader
          Icon={<Building className="w-10 h-10" strokeWidth={1.5} />}
          title="Rede credenciada"
        />
        <div className="flex flex-row -ml-3">
          <TextField
            LeftIcon={<Search />}
            placeholder="Pesquisar"
            className="rounded-sm bg-map-search-100 shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.5)"
          />
        </div>
      </div>

      <GoogleMap
        mapContainerStyle={{
          flex: 1,
        }}
        center={center}
        zoom={handleGetInitialZoom()}
        onLoad={(mapInstance) => {
          setMap(mapInstance);
        }}
        options={mapOptions}
        onZoomChanged={() => handleMapBoundsChange()}
        onDragEnd={() => handleMapBoundsChange()}
        onBoundsChanged={() => {
          if (!hasCalledBoundsChange) {
            handleMapBoundsChange(true);
          }
        }}
      >
        {identifiedClusters?.map((cluster) => (
          <div key={`${cluster.name}`}>
            <Marker
              position={{
                lat: Number(cluster.latitude),
                lng: Number(cluster.longitude),
              }}
              icon={{
                url:
                  hoveredMarkerId === cluster.establishment_company_id
                    ? '/markerHovered.svg'
                    : createClusterMarkerIcon(cluster.establishment_count),
              }}
              onClick={() => handleMarkerClick(cluster)}
              onMouseOver={() => handleOnMouseOver(cluster)}
              onMouseOut={() => setHoveredMarkerId('')}
            />
            {hoveredMarkerId === cluster.establishment_company_id && (
              <MarkerTooltip
                cluster={cluster}
                establishment={establishment}
                isLoadingEstablishmentData={isLoadingEstablishmentData}
              />
            )}
          </div>
        ))}
      </GoogleMap>
    </div>
  );
};

export default Map;
