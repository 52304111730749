import { OverlayView } from '@react-google-maps/api';
import { Cluster } from '../../types/cluster';
import Spinner from '@/common/components/Spinner';
import { EstablishmentTooltipDetails } from './EstablishmentTooltipDetails';
import { EstablishmentCompanyData } from '@/features/company/types/company.type';

interface MarkerTooltipProps {
    cluster: Cluster
    establishment?: EstablishmentCompanyData;
    isLoadingEstablishmentData: boolean;
  }

export const MarkerTooltip = ({ cluster, establishment, isLoadingEstablishmentData }: MarkerTooltipProps) => {
  return (
    <OverlayView
      position={{
        lat: Number(cluster.latitude),
        lng: Number(cluster.longitude),
      }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div
        className="
        tooltip-content
        items-start
        justify-between
        w-[336px]
        backdrop-blur-3xl
        flex-col
        bg-white-200
        z-50
        rounded-md
        absolute
        left-1/2
        bottom-[18px]
        -translate-y-[12%]"
      >
      {isLoadingEstablishmentData ? (
          <Spinner className="m-auto h-[168px]" />
        ) : (
          <EstablishmentTooltipDetails establishment={establishment} />
      )}
      </div>
    </OverlayView>
  );
};
