import Accordion from '@/common/components/Accordion';
import Select from '@/common/components/Select';
import TextField from '@/common/components/TextField';
import { formatCurrency, formatPercentage } from '@/common/utils/formatters';
import { CompanyContractData } from '../../types/contract.type';

interface FeesDetailsProps {
  fees?: CompanyContractData['contract_fees'];
}

export const FeesDetails = ({ fees }: FeesDetailsProps) => {
  return (
    <Accordion
      head={<p>Regras e Tarifas</p>}
      body={
        <div className="flex flex-col gap-4">
          <Select
            label="Regras de preço"
            defaultValue={{ label: 'Dinâmica', value: 'dynamic' }}
            options={[{ label: 'Dinâmica', value: 'dynamic' }]}
            isDisabled
          />
          <p>Tarifas padrão</p>
          {fees?.map(({ id, fee_template, value }) => (
            <div key={id} className="flex gap-2">
              <TextField label="Categoria" value={fee_template.display_name} className="w-1/2" disabled />
              <TextField
                label="Valor"
                value={
                  fee_template.calculate_as === 'percentage'
                    ? formatPercentage(parseFloat(value))
                    : formatCurrency(parseFloat(value))
                }
                className="w-1/2"
                disabled
              />
            </div>
          ))}
        </div>
      }
    />
  );
};
