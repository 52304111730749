import { privateAxios } from "@/common/api/axios";
import { ProductCategoryData, ProductCategoryResponse } from "@/common/types/productCategory.type";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

const useProductCategory: (id: string) => UseQueryResult<ProductCategoryData, unknown> = (id) => {
  return useQuery<ProductCategoryResponse, unknown, ProductCategoryData>({
    queryKey: ['productCategory'],
    queryFn: () => privateAxios.get(`/product_categories/${id}`),
    select: (data) => data?.data?.data
  });
};

export default useProductCategory;
