import Badge from "@/common/components/Badge";
import Box from "@/common/components/Box";
import { Boxes } from "lucide-react";
import TextField from "@/common/components/TextField";
import { formatCurrency } from "@/common/utils/formatters";
import { ProductCategoryData } from "@/common/types/productCategory.type";
import { CompanyBranchProductGrantedCreditData } from "../../types/company.type";
import { CreditProductConfig } from "./CreditProductConfig";

interface CreditProductCategoryConfigProps {
  category: ProductCategoryData;
  grantedCredit: string | number;
  products: CompanyBranchProductGrantedCreditData[];
}

export const CreditProductCategoryConfig = ({ category, grantedCredit, products }: CreditProductCategoryConfigProps) => {
  return (
    <Box className="flex flex-col gap-4">
      <div>
        <Badge
          Icon={Boxes}
          text={category?.display_name || ""}
          className="flex-row-reverse overflow-hidden !p-0 gap-0"
          contentClassName="px-2 py-1"
        />
      </div>

      <TextField
        label="Valor total"
        value={formatCurrency(Number(grantedCredit))}
        disabled
      />

      {products.map((product, index) => <CreditProductConfig key={index} product={product?.product} grantedCredit={product?.granted_credit} />)}
    </Box>
  );
};
