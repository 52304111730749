import ListHeader from "@/common/components/ListHeader";
import Table from "@/common/components/Table";
import { DatePickerWithRange } from "@/common/components/ui/dateRangePicker";
import { useBillingBills } from "@/common/hooks/queries/useBillingBills";
import { FileText } from "lucide-react";
import billsTableColumns from "../../constants/billsTableColumns.constant";
import ListFilters from "@/common/components/ListFilters";
import { options as statusOptions } from "../../constants/billsStatusOptions.constant";
import { Mapper } from "@/common/services/mapper";
import { BillsTransformer } from "../../services/billsTransformer";
import { BillData } from "../../types/bills.type";
import { useFilterParams } from "@/common/hooks/useFilterParams";
import { useFilterDate } from "@/common/hooks/useFilterDate";

const BillingBillsList = () => {
  const { filterParams, setFilterParams } = useFilterParams();

  const { date, formattedDate } = useFilterDate(filterParams);

  const { data: bills, meta, isPending: isPendingBills } = useBillingBills({
    filters: {
      page: filterParams?.page || 1,
      status: filterParams?.status?.value || '',
      startDate: formattedDate.from,
      endDate: formattedDate.to,
    },
    mapper: Mapper.mapWithTransformer<BillData[], any[]>({
      transformer: BillsTransformer.tableTransformer(),
    }),
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex">
        <ListHeader
          Icon={<FileText className="w-10 h-10" strokeWidth={1.5} />}
          title="Faturas"
        />
      </div>

      <div className="h-[calc((100vh-104px-1.5rem))] flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-4">
            <DatePickerWithRange
              date={date}
              setDate={(date) => setFilterParams((params) => ({ ...params, date }))}
            />

            <ListFilters
              filters={[
                {
                  className: "",
                  onChange: (e: any) => setFilterParams((params) => ({...params, status: e.value ? e : null })),
                  defaultValue: filterParams?.status,
                  options: [{ label: "Todas", value: '' }, ...(statusOptions || [])],
                  placeholder: "Status",
                },
              ]}
            />
          </div>
        </div>

        <Table
          columns={billsTableColumns}
          isPendingData={isPendingBills}
          rows={bills}
          rowEmptyValue="--"
          page={filterParams.page || 1}
          setPage={(p) => setFilterParams((params) => ({ ...params, page: p }))}
          totalPages={meta.total_pages}
          totalCount={meta.total}
        />
      </div>
    </div>
  );
};

export default BillingBillsList;
