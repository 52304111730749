import { create } from "zustand";

type State = {
  collapse: boolean;
};

type Action = {
  updateCollapse: (newToken: State['collapse']) => void;
};

export const useMenu = create<State & Action>((set) => ({
  collapse: false,
  updateCollapse: (newToken: boolean) => set(() => ({ collapse: newToken }))
}));
