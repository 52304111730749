import usePrivateAxios from '@/common/api/usePrivateAxios'
import { ErrorResponse } from '@/common/types/errorResponse'
import { SupportTicketMessageValidationSchema } from '@/features/support/schemas/create.schema'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

interface UseCreateSupportTicketMessageProps {
  supportTicketId?: string
  onSuccess: (response: AxiosResponse) => void
  onError: (error: ErrorResponse) => void
}

export const useCreateSupportTicketMessage = ({
  supportTicketId,
  onSuccess,
  onError,
}: UseCreateSupportTicketMessageProps) => {
  const privateAxios = usePrivateAxios()

  return useMutation({
    mutationKey: ['createSupportTicketMessage'],
    mutationFn: async (form: SupportTicketMessageValidationSchema) => {
      const payload = {
        content: form.content,
        documents_attachments_attributes: form.documents?.length
          ? form.documents.map((document) => ({ blob_id: document.id }))
          : undefined,
      }

      return privateAxios.post(`/support/tickets/${supportTicketId}/messages`, { support_message: payload })
    },
    onSuccess,
    onError,
  })
}
