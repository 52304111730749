import { optionsObjectToArray } from "@/common/utils/optionsObjectToArray";

export const optionsObject = {
  open: "Aberto",
  settled: "Estabelecido",
};

export const options = optionsObjectToArray(optionsObject);

export const keyToObject = (key: string) => ({ label: optionsObject[key as keyof typeof optionsObject], value: key });
