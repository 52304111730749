import usePrivateAxios from "@/common/api/usePrivateAxios";
import { defaultTransformerResponse } from "@/common/constants/defaultTransformerResponse.constant";
import { Mapper } from "@/common/services/mapper";
import { MetaResponse } from "@/common/types/metaResponse";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

type useMovementsTotalizerProps<T> = {
  filters?: {
    startDate?: string;
    endDate?: string;
  };
  mapper?: (data: any) => T,
};

export const useMovementsTotalizer = <T,>({
  filters: { startDate, endDate } = {},
  mapper = Mapper.default<T>(),
}: useMovementsTotalizerProps<T>) => {
  const privateAxios = usePrivateAxios();

  const {
    data: { data, meta } = defaultTransformerResponse<T>(),
    ...rest
  } = useQuery({
    queryKey: ['productsPrices', startDate, endDate],
    queryFn: () => {
      return privateAxios.get('/companies/mine/movements_totalizer', {
        params: {
          'q[created_at_gteq]': startDate,
          'q[created_at_lteq]': endDate,
        }
      });
    },
    select: (data: { data: { data: any[], meta: MetaResponse; }; }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      };
    },
    placeholderData: keepPreviousData,
  });

  return { data, meta, ...rest };
};
