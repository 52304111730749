import { unFormatCurrency } from "@/common/utils/unFormatters";
import { getRailsUpdate } from "@/common/utils/getRailsUpdate";
import { mapToDeletion } from "@/common/utils/railsHelpers";
import { ProductCategoryGrantedCreditValidationSchema, ProductGrantedCreditValidationSchema } from "../schemas/companyBranch.schema";
import { CompanyBranchProductCategoryGrantedCreditData } from "@/features/company/types/company.type";

export const transformNewProductCategoryGrantedCredit = (formProductCategory: ProductCategoryGrantedCreditValidationSchema, branchDataProductCategories: CompanyBranchProductCategoryGrantedCreditData[]) => {
  const branchDataProductCategory = branchDataProductCategories?.find((productCategory) => productCategory.product_category.id === formProductCategory.product_category_id);

  return {
    product_category_id: formProductCategory.product_category_id,
    granted_credit: formProductCategory.granted_credit ? unFormatCurrency(formProductCategory.granted_credit) : null,
    product_granted_credits_attributes: getRailsUpdate(
      branchDataProductCategory?.product_granted_credits || [],
      formProductCategory?.product_granted_credits_attributes || [],
      (branchDataProduct) => branchDataProduct?.product.id,
      (product) => product?.product_id,
      mapToDeletion,
      (product) => product.id ? transformUpdatedProductGrantedCredit(product) : transformNewProductGrantedCredit(product),
    ),
  };
};

export const transformUpdatedProductCategoryGrantedCredit = (formProductCategory: ProductCategoryGrantedCreditValidationSchema, branchDataProductCategories: CompanyBranchProductCategoryGrantedCreditData[]) => {
  return {
    id: formProductCategory.id,
    ...transformNewProductCategoryGrantedCredit(formProductCategory, branchDataProductCategories),
  };
};

export const transformNewProductGrantedCredit = (product: ProductGrantedCreditValidationSchema) => {
  return {
    product_id: product.product_id,
    granted_credit: product.granted_credit ? unFormatCurrency(product.granted_credit) : null,
  };
};

export const transformUpdatedProductGrantedCredit = (product: ProductGrantedCreditValidationSchema) => {
  return {
    id: product.id,
    ...transformNewProductGrantedCredit(product),
  };
};
