import { ImageWithPlaceholder } from '@/common/components/ImageWithPlaceholder';
import { BillingEventData } from '@/common/types/billing/event.type';
import { formatCardNumber, formatCurrency, formatDateTime, formatDecimal, formatUnit } from '@/common/utils/formatters';
import { UserRoundIcon } from 'lucide-react';
import { createElement } from 'react';
import { UserEventData } from '../types/userEvents.type';
import CompanyEventTotalFee from '../components/show/consumptionHistory/CompanyEventTotalFee';
import ConsumptionDetails from '@/features/billingEvents/components/ConsumptionDetails';
import RefundDetails from '@/features/billingEvents/components/RefundDetails';

export class EquipmentEventsTransformer {
  static tableTransformer() {
    return (userEvents: UserEventData[]) => {
      return userEvents.map((userEvent) => ({
        status: userEvent.status ? eventStatusTranslation[userEvent.status] : '',
        protocol_number: userEvent.protocol_number ? `${userEvent.protocol_number}` : '',
        bill: 'Não implementado',
        type: userEvent.eventable_type ? eventableTypeTranslations[userEvent.eventable_type] : '',
        details: eventableDetailsComponent[userEvent.eventable_type]
          ? createElement(eventableDetailsComponent[userEvent.eventable_type], { data: userEvent.eventable })
          : '',
        total_amount: userEvent.eventable?.total_amount
          ? formatCurrency(parseFloat(userEvent.eventable?.total_amount))
          : '',
        fee_total: userEvent.fee_total
          ? createElement(CompanyEventTotalFee, { data: userEvent as unknown as BillingEventData })
          : '',
        tax: 'Não implementado',
        branch: userEvent.eventable?.establishment_branch?.display_name || '',
        card: userEvent.eventable.card.last_four_digits
          ? formatCardNumber(userEvent.eventable.card.last_four_digits)
          : '',
        current_equipment_usage: userEvent.eventable?.current_equipment_usage
          ? formatUnit(formatDecimal(userEvent.eventable?.current_equipment_usage, 0), 'kilometers')
          : '',
        author: userEvent.author?.full_name ? (
          <div className="flex items-center gap-2">
            <ImageWithPlaceholder
              alt={userEvent.author?.full_name}
              placeholderIcon={UserRoundIcon}
              className="size-6"
              iconClassName="size-4"
            />
            <p className="text-xs">{userEvent.author?.full_name}</p>
          </div>
        ) : (
          ''
        ),
        created_at: userEvent.created_at ? formatDateTime(new Date(userEvent.created_at)) : '',
      }));
    };
  }
}

export const eventStatusTranslation: { [key: string]: string; } = {
  committed: 'Efetivado'
};
export const eventableDetailsComponent: { [key: string]: React.ComponentType<any>; } = {
  'Client::Consumption': ConsumptionDetails,
  'Billing::Refund': RefundDetails,
};

export const eventableTypeTranslations: { [key: string]: string; } = {
  'Client::Consumption': 'Consumo',
  'Billing::Refund': 'Estorno',
};
