import { LucideProps } from "lucide-react";
import { ForwardRefExoticComponent, RefAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface ImageWithPlaceholderProps {
  url?: string;
  alt?: string;
  className?: string;
  iconClassName?: string;
  placeholderIcon: ForwardRefExoticComponent<Omit<LucideProps, "ref"> & RefAttributes<SVGSVGElement>>;
}

export const ImageWithPlaceholder = ({
  url,
  alt,
  className,
  iconClassName,
  placeholderIcon: PlaceholderIcon,
}: ImageWithPlaceholderProps) => {
  if (url) {
    return (
      <img src={url} alt={alt} className={twMerge("size-4 object-cover rounded-full shrink-0", className)} />
    );
  }

  return (
    <div className={twMerge("flex size-4 items-center justify-center bg-white-800 rounded-full shrink-0", className)}>
      <PlaceholderIcon strokeWidth={1} className={twMerge("text-black-800 size-3", iconClassName)} />
    </div>
  );
};
