import Accordion from '@/common/components/Accordion';
import { InfoIcon } from 'lucide-react';
import { CardProductLimit } from '../../../types/equipments.type';
import { ApplicableProduct } from './ApplicableProduct';

interface ApplicableProductsProps {
  applicableProducts: CardProductLimit[];
}

export const ApplicableProducts = ({
  applicableProducts
}: ApplicableProductsProps) => {
  return (
    <Accordion
      head={(
        <p>Produtos aplicáveis</p>
      )}
      body={(
        <div className="flex w-full flex-col gap-y-4">
          <div className="flex w-full items-center gap-x-2">
            <InfoIcon strokeWidth={1} className="text-yellow-600" />
            <div>
              <p className="font-bold text-[10px] leading-3">
                Ao selecionar um Produto, todos os não selecionados serão bloqueados para consumo
              </p>

              <p className="font-light text-[10px] italic leading-3">
                Certifique-se de adicionar as restrições para todos os produtos que desejar consumir.
              </p>
            </div>
          </div>

          {applicableProducts.map((applicableProduct) => {
            const limitByTransaction = !!applicableProduct.credit_limit_per_transaction || !!applicableProduct.quantity_limit_per_transaction;
            const limitByPeriod = !!applicableProduct.credit_limit_per_period || !!applicableProduct.quantity_limit_per_period;

            return (
              <ApplicableProduct
                key={applicableProduct.id}
                applicableProduct={applicableProduct}
                limitByTransaction={limitByTransaction}
                limitByPeriod={limitByPeriod}
              />
            );
          })}
        </div>
      )}
    />
  );
};
