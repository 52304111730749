import Skeleton from "@/common/components/Skeleton";

export const EquipmentsGridLoading = () => {
  return (
    <div className="flex flex-col bg-white-200 rounded-lg overflow-hidden">
      <div className="w-full object-cover aspect-[4/3]">
        <Skeleton className="h-full" />
      </div>

      <div className="w-full space-y-1 text-white-800 p-4">
        {Array(11).fill(0).map((_, index) => {
          const randomWidth = `${Math.floor(Math.random() * (60 - 25) + 25)}%`;

          return (
            <Skeleton key={index} width={randomWidth} />
          );
        })}
      </div>
    </div>
  );
};
