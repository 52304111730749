import { privateAxios } from "@/common/api/axios";
import { defaultTransformerResponse } from "@/common/constants/defaultTransformerResponse.constant";
import { Mapper } from "@/common/services/mapper";
import { MetaResponse } from "@/common/types/metaResponse";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

type UseEquipmentsProps<T> = {
  filters?: {
    search?: string;
    page?: number;
    companyId?: string;
    branchId?: string;
    propertyKind?: string;
    usageKind?: string;
    status?: string;
    categoryId?: string;
  };
  mapper?: (data: any) => T,
};

export const useEquipments = <T,>({
  filters: {
    search = '',
    page,
    companyId,
    branchId,
    propertyKind,
    usageKind,
    status,
    categoryId,
  } = {},
  mapper = Mapper.default<T>(),
}: UseEquipmentsProps<T>) => {
  const {
    data: { data, meta } = defaultTransformerResponse<T>(),
    isPending,
    ...rest
  } = useQuery({
    queryKey: [
      'equipments',
      search,
      page,
      companyId,
      branchId,
      propertyKind,
      usageKind,
      status,
      categoryId,
    ],
    queryFn: () => {
      return privateAxios.get('/equipments', {
        params: {
          search,
          page,
          'q[company_id_eq]': companyId,
          'q[branch_id_eq]': branchId,
          'q[property_kind_eq]': propertyKind,
          'q[usage_kind_eq]': usageKind,
          'q[status_eq]': status,
          'q[category_id_eq]': categoryId
        }
      });
    },
    select: (data: { data: { data: any[], meta: MetaResponse; }; }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      };
    },
    placeholderData: keepPreviousData,
  });

  return { data, meta, isPending, ...rest };
};

