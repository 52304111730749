import usePrivateAxios from '@/common/api/usePrivateAxios'
import { TicketData, TicketResponse } from '@/common/types/support/ticket.type'
import { UseQueryResult, useQuery } from '@tanstack/react-query'

interface UseSupportTicketProps {
  id: string
}

export const useSupportTicket: (props: UseSupportTicketProps) => UseQueryResult<TicketData, unknown> = ({ id }) => {
  const privateAxios = usePrivateAxios()

  return useQuery<TicketResponse, unknown, TicketData>({
    enabled: !!id,
    queryKey: ['supportTicket', id],
    queryFn: () => {
      return privateAxios.get(`/support/tickets/${id}`)
    },
    select: (data) => data?.data?.data,
  })
}
