import usePrivateAxios from '@/common/api/usePrivateAxios'
import { defaultTransformerResponse } from '@/common/constants/defaultTransformerResponse.constant'
import { Mapper } from '@/common/services/mapper'
import { MetaResponse } from '@/common/types/metaResponse'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

type UseSupportReasonsProps<T> = {
  mapper?: (data: any) => T
}

export const useSupportReasons = <T,>({ mapper = Mapper.default<T>() }: UseSupportReasonsProps<T>) => {
  const privateAxios = usePrivateAxios()

  const { data: { data, meta } = defaultTransformerResponse<T>(), ...rest } = useQuery({
    queryKey: ['supportReasons'],
    queryFn: () => {
      return privateAxios.get('/support/reasons')
    },
    select: (data: { data: { data: any[]; meta: MetaResponse } }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      }
    },
    placeholderData: keepPreviousData,
  })

  return { data, meta, ...rest }
}
