import { useMutation } from "@tanstack/react-query";
import usePrivateAxios from "@/common/api/usePrivateAxios";

interface useZipcodeDataProps {
  onSuccess: (response: any) => void;
  onError: (response: any) => void;
}

const useZipcodeData = ({ onSuccess, onError }: useZipcodeDataProps) => {
  const privateAxios = usePrivateAxios();

  const { mutate, isPending } = useMutation({
    mutationKey: ["initializeFromZipcode"],
    mutationFn: async (zipcode: string) => {
      const payload = {
        address: { zipcode },
      };

      return privateAxios.post("/geo/addresses/initialize_from_zipcode", payload);
    },
    onSuccess,
    onError,
  });

  return { mutate, isPending };
};

export default useZipcodeData;
