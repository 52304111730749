import { z } from 'zod';

const rolesSchema = z.object({
  value: z.string().optional(),
  type: z.string().optional()
})

export const clientUserSchema = z
  .object({
    full_name: z.string().min(1, 'Campo Nome é obrigatório'),
    email: z.union([
      z.literal(''),
      z.string().email('Use um formato de e-mail válido'),
    ]),
    status: z.object(
      {
        label: z.string(),
        value: z.string(),
      },
      { message: 'Campo Status é obrigatório' },
    ),
    cpf: z
      .string()
      .min(1, 'Campo CPF é obrigatório')
      .min(14, 'Use um CPF válido'),
    date_of_birth: z
      .string()
      .min(1, 'Campo obrigatório')
      .refine((data) => {
        if(data.length < 10) return false
        const year = Number(data.split('/')[2])
        const currentYear = (new Date()).getFullYear()
        const difference = currentYear - year
        if(difference < 10 || difference > 200) return false
        return true
      }, {
        message: 'Use uma data válida',
      }),
    phone: z.object({
      number: z
        .string()
        .optional()
        .refine(
          (data) => {
            if (!data) return true;
            return data.length >= 14;
          },
          {
            message: 'Use um telefone válido',
          },
        ),
    }),
    user_roles: z.array(rolesSchema, {
      message: 'Campo Perfil é obrigatório',
    }).min(1, 'Campo Perfil é obrigatório'),
    enrollment_number: z.string().optional(),
    branch_id: z.object({
      label: z.string(),
      value: z.string(),
    }, { message: 'Campo Filial é obrigatório' }),
  })
  .superRefine((data, ctx) => {
    if (!data.email && !data.phone.number) {
      ctx.addIssue({
        path: ['email'],
        code: z.ZodIssueCode.custom,
        message:
          'É obrigatório preencher ao menos um dos campos (Email e/ou Telefone)',
      });
    }
  });

export type ClientUserValidationSchema = z.infer<
  typeof clientUserSchema
>;
