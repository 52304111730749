import { ChevronLeftIcon } from "lucide-react";
import { Link } from "react-router-dom";

interface ShowHeaderProps {
  backTo?: string;
  displayName?: string;
  logoUrl?: string;
  LogoPlaceholder?: JSX.Element;
  buttons?: JSX.Element;
}

export const ShowHeader = ({
  backTo,
  displayName,
  logoUrl,
  LogoPlaceholder,
  buttons,
}: ShowHeaderProps) => {
  return (
    <div className="flex justify-between items-center mb-6">
      <div className="flex gap-2 items-center">
        {backTo && (
          <Link to={backTo} className="cursor-pointer">
            <ChevronLeftIcon size={40} strokeWidth={1} />
          </Link>
        )}

        {logoUrl ? (
          <img
            src={logoUrl}
            alt={displayName}
            className="size-8 rounded-full object-cover"
          />
        ) : LogoPlaceholder ? (
          <>
            {LogoPlaceholder}
          </>
        ) : (
          <div className="size-8 bg-white-300 rounded-full" />
        )}

        <h2 className="text-2xl font-bold">
          {displayName}
        </h2>
      </div>

      {buttons}
    </div>
  );
};
