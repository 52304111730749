import { EstablishmentCompanyData } from "@/features/company/types/company.type";

export const EstablishmentTooltipDetails = ({ establishment }: { establishment?: EstablishmentCompanyData }) => (
    <>
      {establishment?.matrix_branch?.photos[0]?.url && (
        <div className="w-full h-[128px] rounded-t-md">
          <img
            src={establishment.matrix_branch.photos[0].url}
            className="object-fill w-full h-full rounded-t-md"
          />
        </div>
      )}
      <div
        className={`flex flex-col p-3 w-[336px] bg-slate-50/10 ${!establishment?.matrix_branch?.photos[0]?.url ? 'rounded-t-md' : ''}`}
      >
        <div className="flex gap-2 items-center">
          {establishment?.general_information?.company_logo?.url && (
            <img src={establishment.general_information.company_logo.url} className="w-[36px] h-[36px] rounded-full" />
          )}
          <p className="font-bold text-lg">{establishment?.general_information?.fantasy_name}</p>
        </div>
        <p className="font-light mt-1 text-sm -skew-x-6">
          {`${establishment?.general_information?.address.street},
          ${establishment?.general_information?.address.number} -
          ${establishment?.general_information?.address.city?.state.display_name ? establishment?.general_information?.address.city?.state.display_name + ',' : ''}
          ${establishment?.general_information?.address.city?.display_name}`}
        </p>
        <p className="italic font-light text-sm -skew-x-6">Aberto - Não implementado</p>
      </div>
      <div className="flex-col p-3 rounded-b-md">
        <p className="text-base font-normal">Produtos</p>
        <div className="flex mt-1 justify-between">
          <div className="flex-col">
            <p className="font-normal text-xs">Gasolina Comum</p>
            <p>Não implementado</p>
          </div>
          <div className="border-l border-black" />
          <div className="flex-col">
            <p className="font-normal text-xs">Gasolina Aditivada</p>
            <p>Não implementado</p>
          </div>
          <div className="border-l border-black" />
          <div className="flex-col">
            <p className="font-normal text-xs">Diesel</p>
            <p>Não implementado</p>
          </div>
        </div>
      </div>
    </>
)
