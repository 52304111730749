import { unFormatCurrency } from '@/common/utils/unFormatters'
import { z } from 'zod'

export const updatePersonalCardLimitsSchema = z
  .object({
    limitByTransaction: z.boolean().optional(),
    limitByPeriod: z.boolean().optional(),
    limit_per_transaction: z.string().optional(),
    limit_period_in_days: z
      .object({
        label: z.string(),
        value: z.enum(['1', '7', '15', '30']),
      })
      .optional(),
    limit_per_period: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (!data.limitByTransaction || !data.limitByPeriod) {
      return
    }

    if (data.limitByTransaction) {
      if (!data.limit_per_transaction || unFormatCurrency(data.limit_per_transaction) <= 0) {
        ctx.addIssue({
          path: [`limit_per_transaction`],
          code: z.ZodIssueCode.custom,
          message: 'Caso opte por habilitar a restrição de limite por transação, preencha o valor total',
        })
      }
    }

    if (data.limitByPeriod) {
      if (!data.limit_period_in_days?.value) {
        ctx.addIssue({
          path: [`limit_period_in_days`],
          code: z.ZodIssueCode.custom,
          message: 'Campo obrigatório',
        })
      }
      if (!data.limit_per_period || unFormatCurrency(data.limit_per_period) <= 0) {
        ctx.addIssue({
          path: [`limit_per_period`],
          code: z.ZodIssueCode.custom,
          message: 'Caso opte por habilitar a restrição de limite por período, preencha o valor total',
        })
      }
    }
  })

export type UpdatePersonalCardLimitsValidationSchema = z.infer<typeof updatePersonalCardLimitsSchema>
