import usePrivateAxios from '@/common/api/usePrivateAxios'
import { defaultTransformerResponse } from '@/common/constants/defaultTransformerResponse.constant'
import { Mapper } from '@/common/services/mapper'
import { MetaResponse } from '@/common/types/metaResponse'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

type UseSupportTicketsProps<T> = {
  filters?: {
    search?: string
    page?: number
    status?: string
    branch?: string
  }
  mapper?: (data: any) => T
}

export const useSupportTickets = <T,>({
  filters: { search = '', page, status, branch } = {},
  mapper = Mapper.default<T>(),
}: UseSupportTicketsProps<T>) => {
  const privateAxios = usePrivateAxios()

  const { data: { data, meta } = defaultTransformerResponse<T>(), ...rest } = useQuery({
    queryKey: ['supportTickets', page, search, status, branch],
    queryFn: () => {
      return privateAxios.get('/support/tickets', {
        params: {
          search,
          page,
          'q[status_eq]': status,
          'q[branch_type_eq]': branch,
        },
      })
    },
    select: (data: { data: { data: any[]; meta: MetaResponse } }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      }
    },
    placeholderData: keepPreviousData,
  })

  return { data, meta, ...rest }
}
