import { z } from 'zod';

export const recoverCompanyUrlSchema = z.object({
  cpf: z.string().min(1, { message: 'Campo CPF é obrigatório' }),
  email: z.string().optional(),
  phone_number: z.string().optional(),
})
  .superRefine((data, ctx) => {
    if (!data.email && !data.phone_number) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Preencha o email ou telefone',
        path: ['email'],
      });

      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Preencha o email ou telefone',
        path: ['phone_number'],
      });
    }
  });

export type RecoverCompanyUrlValidationSchema = z.infer<typeof recoverCompanyUrlSchema>;
