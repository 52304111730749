import { forwardRef } from "react";
import { VariantProps } from 'class-variance-authority';
import { twMerge } from "tailwind-merge";
import { cn } from "../../utils/cn";
import { textAreaVariants } from "./styles";

export interface TextAreaProps extends React.HTMLAttributes<HTMLTextAreaElement>, VariantProps<typeof textAreaVariants> {
  label?: string,
  disabled?: boolean,
  RightIcon?: JSX.Element;
  LeftIcon?: JSX.Element;
  placeholder?: string;
  errorMessage?: string;
  value?: string;
  onIconClick?: React.MouseEventHandler<HTMLDivElement>,
  rows?: number;
  markAsRequired?: boolean;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({
  label,
  variant,
  className,
  disabled = false,
  placeholder,
  errorMessage,
  onIconClick,
  rows = 4,
  markAsRequired,
  ...props
}, ref) => {

  return (
    <div className={className}>
      <label className="relative flex flex-col items-start justify-center">
        {label && <p className={twMerge('text-xs mb-1')}>{label}{markAsRequired && <span className="text-danger-soft"> *</span>}</p>}
        <textarea
          ref={ref}
          rows={rows}
          disabled={disabled}
          placeholder={placeholder}
          className={cn("resize-none placeholder:text-white-300", textAreaVariants({ variant: disabled ? 'disabled' : variant }))}
          {...props}
        />
      </label>
      {errorMessage?.length && <span className="text-danger-soft text-sm">{errorMessage}</span>}
    </div>
  );
});

export default TextArea;
