import { userStatusTranslation } from '@/common/constants/userStatus.constants';
import { LastConsumption } from '@/features/users/components/LastConsumption';
import { ClientUserData } from '@/features/users/types/clientUsers';
import { ArrowBigRightIcon } from 'lucide-react';
import { Link } from 'react-router-dom';

export class ClientUsersTransformer {
  static tableTransformer() {
    return (users: ClientUserData[]) => {
      return users.map((user) => ({
        ...user,
        role: !!user?.user_roles?.length
          ? user?.user_roles?.map((userRole) => userRole.role.display_name).join(', ') || ''
          : '',
        branchName: user.branch?.display_name || '',
        status: userStatusTranslation[(user?.status || 'default') as keyof typeof userStatusTranslation],
        fullName: user.full_name,
        lastConsumption: user?.last_consumption ? <LastConsumption lastConsumption={user.last_consumption} /> : '',
        actions: (
          <Link to={`/users/${user.id}`}>
            <ArrowBigRightIcon />
          </Link>
        ),
      }));
    };
  }
}
