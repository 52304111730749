import {
  AreaChart,
  CarFront,
  Coins,
  FileText,
  Fuel,
  GitFork,
  PieChart,
  Receipt,
  TicketIcon,
  UserRound,
} from 'lucide-react'

const routes = [
  {
    label: 'Dados gerais',
    Icon: <PieChart strokeWidth={1} />,
    path: '/company',
    permission: 'Client::Companies::Mine',
  },
  {
    label: 'Estrutura',
    Icon: <GitFork strokeWidth={1} />,
    path: '/company-branches',
    permission: 'Client::CompanyBranches::Filter',
  },
  {
    label: 'Financeiro',
    Icon: <Coins strokeWidth={1} />,
    path: [
      {
        label: 'Transações',
        Icon: <Receipt strokeWidth={1} />,
        path: '/billing-events',
        permission: 'Client::Billing::Events::Filter',
      },
      {
        label: 'Totalizadores',
        Icon: <AreaChart strokeWidth={1} />,
        path: '/movements-totalizer',
        permission: 'Client::Companies::MovementsTotalizer',
      },
      {
        label: 'Faturas',
        Icon: <FileText strokeWidth={1} />,
        path: '/billing-bills',
        permission: 'Client::Billing::Bills::Filter',
      },
    ],
  },
  {
    label: 'Rede credenciada',
    Icon: <Fuel strokeWidth={1} />,
    path: '/establishments',
    permission: 'Client::Establishment::Companies::GeoClustered',
  },
  {
    label: 'Equipamentos',
    Icon: <CarFront strokeWidth={1} />,
    path: '/equipments',
    permission: 'Client::EquipmentCategories::Filter',
  },
  {
    label: 'Usuários',
    Icon: <UserRound strokeWidth={1} />,
    path: '/users',
    permission: 'Client::Users::Filter',
  },
  {
    label: 'Chamados',
    Icon: <TicketIcon strokeWidth={1} />,
    path: '/support',
    permission: 'Client::Support::Tickets::Filter',
  },
]

export default routes
