import Modal from "@/common/components/Modal";

interface RequestPasswordResponseModalProps {
  requestPasswordResponse: string[] | null;
  setRequestPasswordResponse: React.Dispatch<React.SetStateAction<string[] | null>>;
}

const RequestPasswordResponseModal = ({ requestPasswordResponse, setRequestPasswordResponse }: RequestPasswordResponseModalProps) => {
  return (
    <div className="absolute top-0">
      <Modal
        isOpen={!!requestPasswordResponse}
        setIsOpen={() => setRequestPasswordResponse(null)}
        onConfirm={() => setRequestPasswordResponse(null)}
        Trigger={<></>}
        footer="Entendido!"
        title=""
        body={(
          <div className="flex flex-col gap-2 py-2">
            {requestPasswordResponse?.map((message, index) => (
              <p key={index} className="text-[16px] text-danger-soft">
                {message}
              </p>
            ))}
          </div>
        )}
      />
    </div>
  );
};

export default RequestPasswordResponseModal;