import Box from "@/common/components/Box";
import TextField from "@/common/components/TextField";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import MaskedTextField from "@/common/components/MaskedTextField";
import { recoverCompanyUrlSchema, RecoverCompanyUrlValidationSchema } from "../../schemas/recoverCompanyDomain.schema";

const RecoverCompanyUrl = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<RecoverCompanyUrlValidationSchema>({
    resolver: zodResolver(recoverCompanyUrlSchema)
  });

  const onSubmit: SubmitHandler<RecoverCompanyUrlValidationSchema> = () => {
    reset();
    navigate('/', { replace: true });
  };

  return (
    <div className="flex flex-col items-center gap-8 w-screen">
      <img src="/Logo.svg" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className=" flex flex-col gap-4 rounded-lg rounded-b-none backdrop-blur-lg w-[460px]">
          <div className="flex flex-col gap-2 text-center">
            <h3 className="text-2xl font-bold">
              Confirme seus dados
            </h3>

            <p className="font-light">
              Informe seu CPF, Email e/ou Telefone para recuperar a url da sua empresa.
            </p>
          </div>

          <MaskedTextField
            mask="000.000.000-00"
            label="CPF"
            markAsRequired
            variant={errors?.cpf?.message ? 'error' : 'default'}
            errorMessage={errors.cpf?.message || undefined}
            {...register('cpf')}
          />

          <TextField
            label="Email"
            variant={errors?.email?.message ? 'error' : 'default'}
            errorMessage={errors?.email?.message || undefined}
            {...register('email')}
          />

          <TextField
            label="Telefone"
            variant={errors?.phone_number?.message ? 'error' : 'default'}
            errorMessage={errors?.phone_number?.message || undefined}
            {...register('phone_number')}
          />

          <div>
            <p className="text-[12px] text-white-400">
              Ao menos um (Email/Telefone) deve ser preenchido
            </p>
          </div>
        </Box>

        <button type="submit" className="w-full bg-white-800 text-black rounded-b-lg rounded-t-md py-4">
          {false ? 'Carregando...' : 'Enviar'}
        </button>
      </form>

      <div className="flex flex-col items-center">
        <span className="text-[10px] italic font-light">
          Desenvolvido por
        </span>

        <img src='/Avantsoft.svg' />
      </div>
    </div>
  );
};

export default RecoverCompanyUrl;