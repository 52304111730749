import { addressSchema } from '@/common/schemas/address.schema';
import { optionSchema } from '@/common/schemas/option.schema';
import { z } from 'zod';

export const intervalsSchema = z.array(z.object({
  start_time: z.object({
    label: z.string().optional(),
    value: z.string().optional()
  }).optional().nullable(),
  end_time: z.object({
    label: z.string().optional(),
    value: z.string().optional()
  }).optional().nullable(),
}));

export const productGrantedCreditSchema = z.object({
  id: z.string().optional(),
  _destroy: z.boolean().optional(),
  product_id: z.string(),
  granted_credit: z.string(),
  display_name: z.string(),
});

export type ProductGrantedCreditValidationSchema = z.infer<typeof productGrantedCreditSchema>;

export const productCategoryGrantedCreditSchema = z.object({
  id: z.string().optional(),
  _destroy: z.boolean().optional(),
  product_category_id: z.string(),
  granted_credit: z.string(),
  product_granted_credits_attributes: z.array(productGrantedCreditSchema).optional(),
});

export type ProductCategoryGrantedCreditValidationSchema = z.infer<typeof productCategoryGrantedCreditSchema>;

export const companyBranchSchema = z.object({
  display_name: z.string().min(1, 'Campo Nome é obrigatório'),
  parent_id: optionSchema,
  master_client_user_id: optionSchema,
  general_information_attributes: z.object({
    willUseOwnData: z.boolean().optional(),
    cnpj: z.string().optional().nullable(),
    email: z.string().email('Use um formato de e-mail válido').optional().nullable(),
    fantasy_name: z.string().optional().nullable(),
    official_name: z.string().optional().nullable(),
    primary_cnae: z.string().optional().nullable(),
    state_inscription: z.string().optional().nullable(),
    address_attributes: addressSchema.optional(),
  }).optional()
    .superRefine((data, ctx) => {
      if (
        data?.willUseOwnData &&
        !data?.cnpj &&
        !data?.email &&
        !data.fantasy_name &&
        !data.official_name &&
        !data.primary_cnae &&
        !data.state_inscription &&
        !data.address_attributes?.zipcode &&
        !data.address_attributes?.street &&
        !data.address_attributes?.number &&
        !data.address_attributes?.city_id &&
        !data.address_attributes?.neighborhood
      ) {
        ctx.addIssue({
          path: ['willUseOwnData'],
          code: z.ZodIssueCode.custom,
          message: 'Caso opte por especificar dados, preencha todos os campos'
        });
      }
    }
    ),
  enablePeriodRestriction: z.boolean().optional(),
  period_restrictions_attributes: z.array(z.object({
    id: z.string().optional(),
    week_day: z.string().optional(),
    blocked: z.boolean().optional(),
    intervals_attributes: intervalsSchema.optional(),
  })).optional(),
  granted_credit: z.string().optional(),
  enableCreditConfiguration: z.boolean().optional(),
  product_category_granted_credits_attributes: z.array(productCategoryGrantedCreditSchema),
  minimum_kilometers_usage_between_consumptions: z.string().optional(),
  minimum_seconds_usage_between_consumptions: z.string().optional(),
  minimum_seconds_interval_between_consumptions: z.string().optional(),
}).superRefine((data, ctx) => {
  if (
    data?.enablePeriodRestriction &&
    data?.period_restrictions_attributes?.length &&
    data.period_restrictions_attributes.every((periodRestriction) => {
      if (periodRestriction.blocked || periodRestriction.intervals_attributes?.some((interval) => interval.start_time?.value === 'all_day')) {
        return true;
      }
      return periodRestriction.intervals_attributes?.every((interval) => interval.start_time?.value && interval.end_time?.value);
    }) === false
  ) {
    ctx.addIssue({
      path: ['enablePeriodRestriction'],
      code: z.ZodIssueCode.custom,
      message: 'Caso opte por habilitar a restrição de período, preencha todos os campos'
    });
  }

  if (data?.enableCreditConfiguration && !data?.product_category_granted_credits_attributes?.length) {
    ctx.addIssue({
      path: ["enableCreditConfiguration"],
      code: z.ZodIssueCode.custom,
      message: "Caso opte por habilitar o crédito por serviço, selecione pelo menos um serviço",
    });
  }
});

export type CompanyBranchValidationSchema = z.infer<typeof companyBranchSchema>;
