import { twMerge } from "tailwind-merge";

export interface BoxProps extends React.HTMLAttributes<HTMLDivElement> { }

const Box = ({
  className,
  ...props
}: BoxProps) => {
  return (
    <div className={twMerge('bg-white-100 p-6 rounded-sm', className)} {...props} />
  );
};

export default Box;
