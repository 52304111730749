import { twMerge } from "tailwind-merge";
import Select, { SelectProps } from "../Select";
import { ActionMeta } from "react-select";

export type ListFiltersProps = {
  filters: {
    className: string;
    placeholder: string;
    options: SelectProps['options'];
    value?: SelectProps['value'];
    defaultValue?: SelectProps['defaultValue'];
    onInputChange?: (inputValue: string) => void;
    onChange: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void;
  }[];
};

const ListFilters = ({ filters }: ListFiltersProps) => {
  return (
    <div className="flex gap-2 items-center justify-start">
      {filters.map((filter) => (
        <Select
          key={filter.placeholder}
          onInputChange={filter.onInputChange}
          className={twMerge("text-xs rounded-xl bg-white-100 shadow-[0px_0px_8px_4px_rgba(0,_0,_0,_0.2)]", filter.className)}
          badge
          placeholder={filter.placeholder}
          defaultValue={filter.defaultValue}
          value={filter?.value}
          onChange={filter.onChange}
          options={filter.options}
        />
      ))}

    </div>
  );
};

export default ListFilters;
