import { ImageWithPlaceholder } from '@/common/components/ImageWithPlaceholder'
import { formatDateTime } from '@/common/utils/formatters'
import { ClientUserData } from '@/features/users/types/clientUsers'
import { BriefcaseBusinessIcon } from 'lucide-react'

interface LastConsumptionProps {
  lastConsumption: ClientUserData['last_consumption']
}

export const LastConsumption = ({ lastConsumption }: LastConsumptionProps) => {
  return (
    <div className="flex items-center gap-x-2">
      <ImageWithPlaceholder
        placeholderIcon={BriefcaseBusinessIcon}
        className="size-6"
        iconClassName="size-4"
        url={lastConsumption.establishment_branch.general_information.company_logo.url}
        alt={lastConsumption.establishment_branch.general_information.fantasy_name}
      />

      <div className='space-y-1'>
        <p className='text-xs'>{lastConsumption.establishment_branch.general_information.fantasy_name}</p>
        <p className='text-xs italic'>{formatDateTime(new Date(lastConsumption.created_at))}</p>
      </div>
    </div>
  )
}
