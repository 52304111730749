import { CopyButton } from "@/common/components/CopyButton";
import Modal from "@/common/components/Modal";
import TextField from "@/common/components/TextField";
import { useCopyButton } from "@/common/hooks/useCopyButton";

interface TemporaryPasswordModalProps {
  temporaryPassword: string;
  setTemporaryPassword: React.Dispatch<React.SetStateAction<string>>;
  onClose?: () => void;
}

const TemporaryPasswordModal = ({ onClose, temporaryPassword, setTemporaryPassword }: TemporaryPasswordModalProps) => {
  const { control, copySuccess } = useCopyButton();

  return (
    <div className="absolute top-0">
      <Modal
        isOpen={!!temporaryPassword}
        setIsOpen={() => {
          setTemporaryPassword('');
          onClose?.();
        }}
        onConfirm={() => {
          setTemporaryPassword('');
          onClose?.();
        }}
        Trigger={<></>}
        footer="Entendido!"
        title="Esta é a senha temporária do usuário"
        subtitle="Utilize esta senha para entrar e redefinir a senha"
        subtitleClassName="mt-1 font-light text-white-300"
        body={(
          <div className="flex flex-col gap-2 py-2">
            <TextField
              disabled
              label="Senha temporária"
              className="grow"
              RightIcon={<CopyButton control={control} className="text-blue-800" />}
              onIconClick={() => copySuccess(temporaryPassword)}
              value={temporaryPassword}
            />
          </div>
        )}
      />
    </div>
  );
};

export default TemporaryPasswordModal;
