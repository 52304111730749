import Badge from "@/common/components/Badge";
import Box from "@/common/components/Box";
import { FileBox } from "lucide-react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { CurrencyTextField } from "@/common/components/CurrencyTextField";
import { CompanyBranchValidationSchema } from "../../schemas/companyBranch.schema";

interface CreditProductConfigProps {
  product: { display_name: string; };
  categoryIndex: number;
  productIndex: number;
  removeProduct: () => void;
  control: Control<CompanyBranchValidationSchema>;
  errors: FieldErrors<CompanyBranchValidationSchema>;
  disabled?: boolean;
}

export const CreditProductConfig = ({ product, categoryIndex, productIndex, removeProduct, control, errors, disabled }: CreditProductConfigProps) => {
  return (
    <Box className="flex flex-col gap-4">
      <Badge
        Icon={FileBox}
        text={product?.display_name || ""}
        onDelete={() => removeProduct()}
        className="rounded-sm flex bg-neutral-op-8 w-fit flex-row-reverse overflow-hidden !p-0 gap-0"
        overwriteClassName
        deleteClassName="bg-black p-2"
        contentClassName="px-3 py-2"
        deleteIconColor="var(--foreground)"
        disabled={disabled}
      />

      <Controller
        name={`product_category_granted_credits_attributes.${categoryIndex}.product_granted_credits_attributes.${productIndex}.granted_credit`}
        control={control}
        disabled={disabled}
        render={({ field }) => (
          <CurrencyTextField
            label="Valor total"
            markAsRequired
            maxValue={9_999_999.99}
            errorMessage={errors.product_category_granted_credits_attributes?.[categoryIndex]?.product_granted_credits_attributes?.[productIndex]?.granted_credit?.message || " "}
            {...field}
          />
        )}
      />
    </Box>
  );
};
