import usePrivateAxios from '@/common/api/usePrivateAxios';
import { getRailsUpdate } from '@/common/utils/getRailsUpdate';
import { mapToDeletion } from '@/common/utils/railsHelpers';
import { transformNewRoles } from '@/common/utils/roleTransformers';
import { removeSpecialCharacters } from '@/common/utils/unFormatters';
import { ClientUserValidationSchema } from '@/features/users/schemas/create.schema';
import { ClientUserData } from '@/features/users/types/clientUsers';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

interface UseUpdateClientUserProps {
  clientUserId?: string;
  clientUserData?: ClientUserData;
  onSuccess: (response: AxiosResponse) => void;
  onError: (error: AxiosError) => void;
}

export const useUpdateClientUser = ({
  clientUserId,
  clientUserData,
  onSuccess,
  onError,
}: UseUpdateClientUserProps) => {
  const privateAxios = usePrivateAxios();

  return useMutation({
    mutationKey: ['updateClientUser'],
    mutationFn: async (form: ClientUserValidationSchema) => {
      const payload = {
        cpf: removeSpecialCharacters(form.cpf),
        date_of_birth: form.date_of_birth || null,
        user_roles_attributes: getRailsUpdate(
          clientUserData?.user_roles || [],
          form?.user_roles || [],
          (userRole) => userRole.role.id,
          (role) => role.value || '',
          mapToDeletion,
          transformNewRoles,
        ),
        status: form?.status?.value || 'enabled',
        branch_id: form?.branch_id?.value || null,
        phone_attributes: { number: form?.phone?.number ? removeSpecialCharacters(form?.phone?.number) : null },
        email: form.email || null,
        enrolment_number: form.enrollment_number || null,
      };

      return privateAxios.put(`/users/${clientUserId}`, {
        client_user: payload,
      });
    },
    onSuccess,
    onError,
  });
};
