export const unitTranslation = {
  hours: 'Hora-uso',
  kilometers: 'Quilometragem',
};

export const unitStrategy = {
  hours: 'H',
  liters: 'L',
  kilometers: 'Km',
};

export const unities: { [key: string]: string; } = {
  liters: 'L',
  kilometers: 'Km',
  hours: 'H',
};

export const unitiesLabel: { [key: string]: string; } = {
  liter: 'Litro',
  liters: 'Litros',
  kilometer: 'Km',
  kilometers: 'Km',
  hours: 'Horas',
  item: 'Unidades',
  gram: 'Gramas',
  cubic_meter: 'Metros cubicos',
};
