import Box from "@/common/components/Box";
import TextField from "@/common/components/TextField";
import { zodResolver } from "@hookform/resolvers/zod";
import { Eye, EyeOff } from "lucide-react";
import { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPasswordSchema, ResetPasswordValidationSchema } from "../../schemas/resetPassword.schema";
import useResetPassword from "../../hooks/useResetPassword";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const from = useMemo(() => location?.state?.from?.pathname || '/company', []);

  const [requestError, setRequestError] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<ResetPasswordValidationSchema>({
    resolver: zodResolver(resetPasswordSchema)
  });

  const { mutate: resetPassword, isPending: resetPasswordIsPending } = useResetPassword({
    onSuccess: () => {
      setRequestError(false);
      navigate(from, { replace: true });
    },
    onError: (error) => {
      const { errors: { base: errors } } = error?.response?.data as { errors: { base: string[]; }; };

      if (errors && errors.length) {
        setRequestError(true);
        setError('confirmPassword', { message: errors[0] });
      }
    }
  });

  const onSubmit: SubmitHandler<ResetPasswordValidationSchema> = (data) => resetPassword(data);

  return (
    <div className="flex flex-col items-center gap-8 w-screen">
      <img src="/Logo.svg" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className=" flex flex-col gap-4 rounded-xl rounded-b-none backdrop-blur-lg w-[460px] h-[280px]">
          <div className="flex flex-col gap-2">
            <h3 className="text-2xl font-bold">
              Crie nova senha
            </h3>
          </div>

          <TextField
            label="Insira a nova senha"
            type={showPassword ? 'text' : 'password'}
            RightIcon={showPassword ? <EyeOff /> : <Eye />}
            onIconClick={() => setShowPassword(!showPassword)}
            variant={errors.password?.message || requestError ? 'error' : 'default'}
            errorMessage={errors.password?.message || undefined}
            {...register('password')}
          />

          <TextField
            label="Confirme a nova senha"
            type={showConfirmPassword ? 'text' : 'password'}
            RightIcon={showConfirmPassword ? <EyeOff /> : <Eye />}
            onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
            variant={errors.confirmPassword?.message ? 'error' : 'default'}
            errorMessage={errors.confirmPassword?.message || undefined}
            {...register('confirmPassword')}
          />
        </Box>

        <button type="submit" className="w-full bg-white-800 text-black rounded-t-md py-4">
          {resetPasswordIsPending ? 'Carregando...' : 'Continuar'}
        </button>

        <button type="button" className="w-full bg-black text-white rounded-b-lg py-4" onClick={() => navigate('/login')}>
          Cancelar
        </button>
      </form>

      <div className="flex flex-col items-center">
        <span className="text-[10px] italic font-light">
          Desenvolvido por
        </span>

        <img src='/Avantsoft.svg' />
      </div>
    </div>
  );
};

export default ResetPassword;
