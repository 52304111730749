import TextField from "@/common/components/TextField";
import { useCompanyBranches } from "@/common/hooks/queries/useCompanyBranches";
import { Mapper } from "@/common/services/mapper";
import { CompanyBranchData } from "@/common/types/companyBranches.type";

export type BranchInformationProps = {
  displayName?: string;
  parentId?: string;
};

export const BranchInformation = ({
  displayName,
  parentId,
}: BranchInformationProps) => {
  const { data: companyBranchOptions } = useCompanyBranches({
    mapper: Mapper.mapToOptions<CompanyBranchData>({ labelFieldName: 'display_name', valueFieldName: 'id' }),
  });

  return (
    <div className="flex gap-2 w-full justify-between">
      <TextField
        label="Nome da filial"
        value={displayName}
        className="w-full"
        disabled
      />
      {parentId && (
        <TextField
          label="Filial Mãe"
          className="w-full"
          value={companyBranchOptions?.find((branch) => branch.value === parentId)?.label}
          disabled
        />
      )}
    </div>
  );
};
