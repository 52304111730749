import { privateAxios } from '@/common/api/axios'
import { unFormatCurrency } from '@/common/utils/unFormatters'
import { UpdatePersonalCardLimitsValidationSchema } from '@/features/users/components/showUser/updatePersonalCardLimits/schema'
import { UseMutationResult, useMutation } from '@tanstack/react-query'

interface useUpdateClientUserPersonalCardLimitsOptions {
  userId?: string
  cardId?: string
  onSuccess?: () => void
  onError?: (error: any) => void
}

export const useUpdateClientUserPersonalCardLimits = ({
  userId,
  cardId,
  onSuccess,
  onError,
}: useUpdateClientUserPersonalCardLimitsOptions): UseMutationResult<
  void,
  Error,
  UpdatePersonalCardLimitsValidationSchema
> => {
  return useMutation({
    mutationFn: async (form: UpdatePersonalCardLimitsValidationSchema) => {
      await privateAxios.put(`/users/${userId}/update_card`, {
        client_card: {
          id: cardId,
          limit_per_transaction:
            form?.limitByTransaction && form?.limit_per_transaction && unFormatCurrency(form?.limit_per_transaction) > 0
              ? unFormatCurrency(form?.limit_per_transaction)
              : null,
          limit_period_in_days: form?.limitByPeriod ? form?.limit_period_in_days?.value : 7,
          limit_per_period:
            form?.limitByPeriod && form?.limit_per_period && unFormatCurrency(form?.limit_per_period) > 0
              ? unFormatCurrency(form?.limit_per_period)
              : null,
        },
      })
    },
    onSuccess,
    onError,
  })
}
