import Accordion from "@/common/components/Accordion";
import Checkbox from "@/common/components/Checkbox";
import { Control, FieldErrors, useFieldArray, UseFormRegister, UseFormWatch } from "react-hook-form";
import { EquipmentValidationSchema } from "../../schemas/create.schema";
import { PeriodRestrictionInfo } from "./PeriodRestrictionInfo";
import { PeriodRestrictionIntervals } from "./PeriodRestrictionIntervals";
import { weekDaysStrategy } from "@/common/constants/periodRestrictions.constant";

interface PeriodRestrictionProps {
  control: Control<EquipmentValidationSchema, any>;
  register: UseFormRegister<EquipmentValidationSchema>;
  watch: UseFormWatch<EquipmentValidationSchema>;
  errors: FieldErrors<EquipmentValidationSchema>;
  disabled?: boolean;
}

export const PeriodRestriction = (
  {
    control,
    register,
    watch,
    errors,
    disabled,
  }: PeriodRestrictionProps
) => {
  const { fields: periodRestrictionFields } = useFieldArray({
    control,
    name: `period_restrictions_attributes`,
  });

  return (
    <Accordion
      contentClassName="overflow-hidden"
      head={(
        <div className="flex flex-col gap-2">
          {errors.enablePeriodRestriction && (
            <p className="text-sm text-danger-soft">
              {errors.enablePeriodRestriction.message}
            </p>
          )}
          <div className="flex items-center gap-x-2">
            <Checkbox
              label="Restrição de períodos"
              onClick={(event) => event.stopPropagation()}
              {...register('enablePeriodRestriction')}
            />

            <p className="text-sm font-light italic">
              (As restrições exclusivas sobrepõem as regras de filial)
            </p>
          </div>
        </div>
      )}
      body={(
        <div className="flex flex-col gap-4">
          <PeriodRestrictionInfo />

          {periodRestrictionFields.map((periodRestriction, periodRestrictionIndex) => (
            <div className="flex justify-between" key={periodRestriction.week_day}>
              <div className="flex flex-col gap-2">
                <p>
                  {weekDaysStrategy[periodRestriction.week_day as keyof typeof weekDaysStrategy]}
                </p>

                <Checkbox
                  label="Não permitido"
                  boxClassName="w-[24px] h-[24px]"
                  labelClassName="text-[10px] italic font-light"
                  {...register(`period_restrictions_attributes.${periodRestrictionIndex}.blocked`)}
                  disabled={disabled}
                />
              </div>

              {!watch(`period_restrictions_attributes.${periodRestrictionIndex}.blocked`) && (
                <div className="flex flex-col gap-2 items-start">
                  <PeriodRestrictionIntervals
                    periodRestrictionIndex={periodRestrictionIndex}
                    control={control}
                    watch={watch}
                    errors={errors}
                    disabled={disabled}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    />
  );
};
