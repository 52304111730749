import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { twMerge } from "tailwind-merge";
import { useMenu } from "../stores/menu";

const Layout = () => {
  const { collapse } = useMenu();

  return (
    <div className="flex">
      <Sidebar />

      <div className={twMerge("w-[calc(100vw-283px)] p-8", collapse && 'w-[calc(100vw-88px)]')}>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
