import { FC } from "react";
import { Pagination } from "./pagination";
import Rows from "./rows";
import { twMerge } from "tailwind-merge";

export interface ColumnPattern {
  label: string;
  value: string;
  className?: string;
}

export interface TableProps {
  className?: string;
  headerClassName?: string;
  headerCellClassName?: string;
  footerClassName?: string;
  flatHeader?: boolean;
  columns: ColumnPattern[],
  rows: { [key: string]: unknown; }[];
  page: number;
  totalPages: number;
  totalCount?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  isPendingData: boolean;
  hasPagination?: boolean;
  customWidth?: string;
  bottomContent?: JSX.Element;
  rowEmptyValue?: string;
}

const Table: FC<TableProps> = ({
  className,
  headerClassName,
  headerCellClassName,
  footerClassName,
  flatHeader,
  columns,
  rows,
  page,
  totalPages,
  totalCount,
  setPage,
  isPendingData,
  hasPagination = false,
  bottomContent = null,
  rowEmptyValue
}) => {
  return (
    <div className={twMerge("max-h-[73vh] overflow-scroll relative rounded-lg no-scrollbar", className)}>
      <table className={"px-6 text-sm w-full rounded-t-lg whitespace-nowrap overflow-x-scroll"}>
        <thead className={twMerge("sticky top-0 bg-[#212023]", headerClassName)}>
          <tr className={!flatHeader ? "[&>*:last-child]:rounded-tr-lg [&>*:first-child]:rounded-tl-lg [&>*:first-child]:border-l-0 [&>*:last-child]:border-r-0" : ""}>
            {columns.map((column) => (
              <th className={twMerge("text-left px-4 border-x z-30 border-x-[#64676980] py-6 text-sm bold font-normal", headerCellClassName)} key={column.value}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody className="[&>*:nth-child(odd)]:bg-white-100 [&>*:nth-child(even)]:bg-blue-100">
          <Rows
            data={rows}
            columns={columns}
            isPending={isPendingData}
            emptyValue={rowEmptyValue}
          />
        </tbody>
      </table>
      <div className={twMerge("w-full px-4 py-2 bg-black-300 rounded-b-lg flex justify-between absolute bottom-0 left-0 items-center sticky bottom-0 bg-[#212023]", footerClassName, typeof totalCount === 'undefined' && 'justify-end')}>
        {typeof totalCount !== 'undefined'
          ? <p className="text-xs italic font-light">Mostrando {rows.length} resultados de {totalCount}</p>
          : null
        }
        {hasPagination && setPage
          ? <Pagination currentPage={page} setPage={setPage} totalPages={totalPages} />
          : bottomContent
        }
      </div>
    </div>
  );
};

export default Table;
