const CompanyFeeProduct = ({ product }: { product: string }) => {
    return (
      <div className="flex gap-2 items-center">
          <img src="/Paper.svg" />
          <div >
          <p className="">{product}</p>
          </div>
      </div>
    )
  }
  
  export default CompanyFeeProduct
  