import Checkbox from '@/common/components/Checkbox';
import TextField from '@/common/components/TextField';
import { formatCurrency, formatDecimal, formatUnit } from '@/common/utils/formatters';
import { FileBoxIcon } from 'lucide-react';
import { CardProductLimit } from '../../../types/equipments.type';
import { options as applicableProductsPeriodLimit } from "../../../constants/applicableProductsPeriodLimit.constant";


interface ApplicableProductProps {
  applicableProduct: CardProductLimit;
  limitByTransaction?: boolean;
  limitByPeriod?: boolean;
}

export const ApplicableProduct = ({
  applicableProduct,
  limitByTransaction,
  limitByPeriod,

}: ApplicableProductProps) => {
  return (
    <div className="flex w-full flex-col gap-y-6 bg-[#FFFFFF0D] rounded-sm p-4">
      <div className="flex items-center">
        <div className="flex items-center gap-x-2 bg-white-200 p-2">
          <FileBoxIcon strokeWidth={1} />

          <p>
            {applicableProduct.product.display_name}
          </p>
        </div>
      </div>

      <div className="flex items-center gap-x-6">
        <Checkbox
          label="Limite por transação"
          name="limitByTransaction"
          labelClassName="text-[10px] italic font-light"
          checked={limitByTransaction}
          disabled
        />

        <Checkbox
          label="Limite por período"
          name="limitByPeriod"
          labelClassName="text-[10px] italic font-light"
          checked={limitByPeriod}
          disabled
        />
      </div>

      {limitByTransaction && (
        <div className="flex flex-col gap-y-4">
          <p>
            Limite por Transação
          </p>

          <div className="flex w-full gap-x-2">
            <TextField
              label="Quantidade"
              value={applicableProduct.quantity_limit_per_transaction ? formatUnit(formatDecimal(applicableProduct.quantity_limit_per_transaction), applicableProduct?.product?.unit_for_quantity || '') : ''}
              className="w-full"
              disabled
            />

            <p className="mt-9">
              Ou
            </p>

            <TextField
              label="Valor total"
              value={applicableProduct.credit_limit_per_transaction ? formatCurrency(parseFloat(applicableProduct.credit_limit_per_transaction)) : ''}
              className="w-full"
              disabled
            />
          </div>

          <p className="font-light text-xs italic mt-[-8px]">
            Caso selecione <span className="font-bold not-italic">Quantidade</span> e <span className="font-bold not-italic">Valor total</span>, vale o que for atingido primeiro.
          </p>
        </div>
      )}

      {limitByPeriod && (
        <div className="flex flex-col gap-y-4">
          <p>
            Limite por Período
          </p>

          <TextField
            label="Período"
            value={applicableProduct.period_in_days ? applicableProductsPeriodLimit.find((option) => option.value === `${applicableProduct.period_in_days}`)?.label || '' : ''}
            disabled
          />

          <div className="flex w-full gap-x-2">
            <TextField
              label="Quantidade"
              value={applicableProduct.quantity_limit_per_period ? formatUnit(formatDecimal(applicableProduct.quantity_limit_per_period) || '', applicableProduct?.product?.unit_for_quantity || '') : ''}
              className="w-full"
              disabled
            />

            <p className="mt-9">
              Ou
            </p>

            <TextField
              label="Valor total"
              value={applicableProduct.credit_limit_per_period ? formatCurrency(parseFloat(applicableProduct.credit_limit_per_period)) : ''}
              className="w-full"
              disabled
            />
          </div>

          <p className="font-light text-xs italic mt-[-8px]">
            Caso selecione <span className="font-bold not-italic">Quantidade</span> e <span className="font-bold not-italic">Valor total</span>, vale o que for atingido primeiro.
          </p>
        </div>
      )}
    </div>
  );
};
