import { UseQueryResult, useQuery } from "@tanstack/react-query";
import usePrivateAxios from "@/common/api/usePrivateAxios";
import { CompanyEventData } from "../types/companyEventData.type";


export const useCompanyFee: (id: string) => UseQueryResult<CompanyEventData, unknown> = (id) => {
  const privateAxios = usePrivateAxios();
  return useQuery<{ data: { data: CompanyEventData; }; }, unknown, CompanyEventData>({
    queryKey: ['companyFee', id],
    queryFn: () => privateAxios.get(`/billing/events/${id}`),
    enabled: !!id,
    select: (data) => {
      return data?.data?.data;
    }
  });
};
