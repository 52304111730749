import { UseQueryResult, useQuery } from "@tanstack/react-query";
import usePrivateAxios from "@/common/api/usePrivateAxios";
import { CompanyBranchData, CompanyBranchResponse } from "@/features/company/types/company.type";

export const useCompanyBranch: (id: string, enabled?: boolean) => UseQueryResult<CompanyBranchData, unknown> = (id, enabled = true) => {
  const privateAxios = usePrivateAxios();
  return useQuery<CompanyBranchResponse, unknown, CompanyBranchData>({
    queryKey: ['companyBranch', id],
    queryFn: () => privateAxios.get(`/company_branches/${id}`),
    enabled,
    select: (data) => {
      return data?.data?.data;
    }
  });
};
