import { initialPeriodRestrictions } from "@/common/constants/periodRestrictions.constant";
import { RestrictionData } from "@/features/company/types/company.type";


export const companyBranchDefaultPeriodRestrictionData = (periodRestrictions?: RestrictionData['period_restrictions']) => {
  return periodRestrictions?.length ?
  periodRestrictions?.map(
    (periodRestriction) => ({
      id: periodRestriction.id,
      week_day: periodRestriction.week_day,
      blocked: !periodRestriction.intervals?.length,
      intervals_attributes: periodRestriction.intervals?.length ? periodRestriction.intervals.map((interval) => ({
        id: interval.id,
        start_time: {
          label: (interval.start_time === '00:00' && interval.end_time === '23:59') ? '24 horas' : interval.start_time,
          value: (interval.start_time === '00:00' && interval.end_time === '23:59') ? 'all_day' : interval.start_time,
        },
        end_time: {
          label: (interval.start_time === '00:00' && interval.end_time === '23:59') ? undefined : interval.end_time,
          value: (interval.start_time === '00:00' && interval.end_time === '23:59') ? undefined : interval.end_time,
        },
      })) : [
        {
          start_time: undefined,
          end_time: undefined,
        }
      ],
    })
  ) : [
    ...initialPeriodRestrictions?.map((periodRestriction) => ({
      week_day: periodRestriction.week_day,
      blocked: periodRestriction.blocked,
      intervals_attributes: periodRestriction.intervals_attributes?.map((interval) => ({
        ...interval,
        start_time: null,
        end_time: null,
      }))
    })) || []
  ]
}
