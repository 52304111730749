import { useMutation } from "@tanstack/react-query";
import usePrivateAxios from "@/common/api/usePrivateAxios";
import { AxiosResponse } from "axios";

interface UseCardWithSecureInformation {
  onSuccess?: (response?: AxiosResponse<any, any>) => void;
  onError?: () => void;
}

export const useCardWithSecureInformation = ({ onSuccess, onError }: UseCardWithSecureInformation) => {
  const privateAxios = usePrivateAxios();
  return useMutation({
    mutationKey: ['cardWithSecureInformation'],
    mutationFn: (id: string) => {
      return privateAxios.get(`/cards/${id}/with_secure_information`);
    },
    onSuccess,
    onError,
  });
};
