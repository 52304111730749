import { z } from 'zod';

export const resetPasswordSchema = z.object({
  password: z.string({ message: 'Campo obrigatório' }).min(6, 'Campo senha tem o mínimo de 6 caracteres'),
  confirmPassword: z.string({ message: 'Campo obrigatório' }),
})
  .superRefine((data, ctx) => {
    if (data.password !== data.confirmPassword) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'As senhas não correspondem',
        path: ['confirmPassword'],
      });
    }
  });

export type ResetPasswordValidationSchema = z.infer<typeof resetPasswordSchema>;
