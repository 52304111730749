import usePrivateAxios from "@/common/api/usePrivateAxios"
import { useMutation } from "@tanstack/react-query"

interface UseCreateClientUserCard {
  clientUserId?: string,
  onSuccess?: () => void,
}

export const useCreateClientUserCard = ({ clientUserId, onSuccess }: UseCreateClientUserCard) => {
  const privateAxios = usePrivateAxios()
  return useMutation({
    mutationKey: ['createClientUserCard'],
    mutationFn: async () => {
      return privateAxios.post(`/users/${clientUserId}/request_card`)
    },
    onSuccess,
  })
}
