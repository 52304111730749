import React, { useState, useEffect, useRef } from "react";
import TextField from "@/common/components/TextField";
import { ArrowLeftToLine, Search } from "lucide-react";
import IconButton from "@/common/components/IconButton";
import { useAppDetails } from "@/common/hooks/useAppDetails";
import { Link } from "react-router-dom";
import Skeleton from "@/common/components/Skeleton";

const LoadingSidebar = () => {
  const { commitSha } = useAppDetails();
  const [skeletonCount, setSkeletonCount] = useState(0);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const calculateSkeletonCount = () => {
      if (sidebarRef.current && bottomRef.current) {
        const sidebarHeight = sidebarRef.current.clientHeight;
        const bottomHeight = bottomRef.current.offsetHeight;
        const availableHeight = sidebarHeight - bottomHeight - 240;
        const skeletonHeight = 28 + 18;
        const count = Math.max(0, Math.floor(availableHeight / skeletonHeight));

        setSkeletonCount(count);
      }
    };
    calculateSkeletonCount();
    window.addEventListener("resize", calculateSkeletonCount);
    return () => {
      window.removeEventListener("resize", calculateSkeletonCount);
    };
  }, []);

  return (
    <div
      ref={sidebarRef}
      className="relative h-screen w-[283px] left-0 bg-white-200 shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.2)] transition-all duration-150"
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <div className="flex justify-between pt-6 px-6">
            <img src="/Logo.svg" className="w-[83px] h-auto" />
            <IconButton Icon={<ArrowLeftToLine strokeWidth={1} />} />
          </div>

          <div className="px-6">
            <TextField
              LeftIcon={<Search className="-translate-y-1 translate-x-1" size="18px" />}
              placeholder="Pesquisar"
              className="text-xs"
            />
          </div>
        </div>

        <div className="w-full space-y-1 text-white-800 px-8 py-4">
          {Array(skeletonCount)
            .fill(0)
            .map((_, index) => {
              const randomWidth = `${Math.floor(Math.random() * (100 - 50 + 1) + 50)}%`;
              return (
                <React.Fragment key={index}>
                  <Skeleton width={randomWidth} height={28} />
                  <div className="h-[18px]" />
                </React.Fragment>
              );
            })}
        </div>
      </div>

      <div
        ref={bottomRef}
        className="absolute w-full bottom-0 p-4 flex flex-col gap-4 bg-white-200 shadow-[4px_0px_16px_0px_rgba(0,0,0,0.5)]"
      >
        <div className="flex items-center justify-between">
          <div className="flex flex-col items-start">
            <span className="text-[10px] italic font-light">Desenvolvido por</span>
            <img src="/Avantsoft.svg" />
          </div>

          <p className="font-light text-[10px] italic">
            Versão: <span className="font-bold not-italic">{commitSha}</span>
          </p>
        </div>

        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <Skeleton circle width={36} height={36} />
            <div className="flex flex-col">
              <p
                className="text-sm font-bold max-w-40 text-ellipsis text-nowrap overflow-hidden"
                data-testid="current_user_name"
              >
                Carregando...
              </p>

              <Link to="/users/me">
                <span className="italic font-light text-xs cursor-pointer">Configurações de perfil</span>
              </Link>
            </div>
          </div>
          <Skeleton width={36} height={36} />
        </div>
      </div>
    </div>
  );
};

export default LoadingSidebar;
