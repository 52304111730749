import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import {
  QueryClientProvider,
  QueryClient
} from '@tanstack/react-query';
import './index.css';
import { sentryInit } from './common/utils/sentry.ts';

sentryInit();

const client = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
);
