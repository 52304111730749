import { ImageCarousel } from "@/common/components/ImageCarousel";
import { checkIsImage } from "@/common/utils/fileValidator";
import { UploadIcon } from "lucide-react";
import { BaseSyntheticEvent } from "react";
import { ArrayPath, Control, FieldArrayWithId, FieldValues, useFieldArray } from "react-hook-form";

export interface MultipleImageUploaderProps<T extends FieldValues> extends React.HTMLAttributes<HTMLInputElement> {
  label: string | JSX.Element;
  name: string;
  accept?: string;
  uploadBlob: (data: { file: File; filename: string; }) => Promise<any>;
  submit?: (e?: BaseSyntheticEvent) => Promise<void>;
  control: Control<T, any>;
  fieldName: ArrayPath<T>;
  disabled?: boolean;
}

interface UploadedFile {
  id: string;
  file: File;
}

export const MultipleImageUploader = <T extends FieldValues>({
  label,
  name,
  uploadBlob,
  submit,
  control,
  fieldName,
  disabled,
  ...props
}: MultipleImageUploaderProps<T>) => {
  const { fields: files, append: appendFile, remove: removeFile } = useFieldArray({
    control,
    name: fieldName,
  });

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []).filter((file) => checkIsImage(file));

    if (files.length > 0) {
      for (const file of files) {
        const data = await uploadBlob({ file, filename: file.name });

        if (data?.data?.data?.id) {
          appendFile({ id: data.data.data.id, file } as unknown as FieldArrayWithId<T, ArrayPath<T>>);

          submit && submit();
        }
      }
    }
    e.target.value = '';
  };

  const handleRemoveImage = (currentIndex: number) => {
    if (files.length > 0) {
      removeFile(currentIndex);

      submit && submit();
    }
  };

  return (
    <div className="flex flex-wrap gap-4">
      {files.length > 0 && (
        <ImageCarousel files={files.map((file) => ({ id: file.id, file: (file as unknown as UploadedFile).file }))} showRemoveButton handleRemoveImage={handleRemoveImage} />
      )}
      <label className="relative cursor-pointer" htmlFor={name}>
        <div className="border size-72 flex justify-center items-center cursor-pointer rounded-lg">
          <div className="flex flex-col items-center gap-y-2">
            {typeof label === 'string' ? (<p className="text-xs">{label}</p>) : label}
            <UploadIcon strokeWidth={1} />
            <p className="font-light text-xs italic">Ou arraste até aqui</p>
          </div>
        </div>
      </label>
      <input type="file" name={name} className="hidden" id={name} multiple onChange={handleImageUpload} {...props} />
    </div>
  );
};
