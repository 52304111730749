import TextField from "@/common/components/TextField";
import { CompanyData } from "../../types/company.type";

type MasterClientUserProps = {
  masterClientUser?: CompanyData['matrix_branch']['master_client_user'];
};

export const MasterClientUser = ({
  masterClientUser,
}: MasterClientUserProps) => {
  return (
    <div className="flex flex-col gap-4">
      <p className="font-bold text-lg">
        Responsáveis
      </p>

      <TextField
        label='Gestor de frota'
        value={masterClientUser?.full_name}
        disabled
      />
    </div>
  );
};
