import usePrivateAxios from '@/common/api/usePrivateAxios'
import { ErrorResponse } from '@/common/types/errorResponse'
import { SupportTicketValidationSchema } from '@/features/support/schemas/create.schema'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

interface UseCreateSupportTicketProps {
  creator_id?: string
  onSuccess: (response: AxiosResponse) => void
  onError: (error: ErrorResponse) => void
}

export const useCreateSupportTicket = ({ creator_id, onSuccess, onError }: UseCreateSupportTicketProps) => {
  const privateAxios = usePrivateAxios()

  return useMutation({
    mutationKey: ['createSupportTicket'],
    mutationFn: async (form: SupportTicketValidationSchema) => {
      const payload = {
        reason_id: form.reason.value,
        subject_line: form.subject_line,
        first_message_attributes: {
          creator_id,
          creator_type: 'Client::User',
          content: form.content,
          documents_attachments_attributes: form.documents?.length
            ? form.documents.map((document) => ({ blob_id: document.id }))
            : undefined,
        },
      }

      return privateAxios.post('/support/tickets', { support_ticket: payload })
    },
    onSuccess,
    onError,
  })
}
