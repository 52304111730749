import { formatCurrency } from '@/common/utils/formatters'
import { transformToNumber } from '@/common/utils/transformers'
import React from 'react'
import CompanyFeeName from '../components/CompanyFeeName'
import CompanyFeeProduct from '../components/CompanyFeeProduct'
import { CompanyEvent } from '../types/companyEventData.type'

export class CompanyFeeTransformer {
  static tableTransformer() {
    return (fees?: CompanyEvent[]) => {
      return fees?.map((fee) => {
        const totalFee = transformToNumber({ value: fee.amount, invert: true })
        return {
          name: fee.fee_source.fee_template.display_name
            ? React.createElement(CompanyFeeName, { name: fee.fee_source.fee_template.display_name })
            : '',
          product: fee.source.display_name
            ? React.createElement(CompanyFeeProduct, { product: fee.source.display_name })
            : '',
          amount: (
            <p className={totalFee > 0 ? 'text-valid' : totalFee < 0 ? 'text-danger-soft' : ''}>
              {formatCurrency(totalFee)}
            </p>
          ),
        }
      })
    }
  }
}
