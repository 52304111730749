import { create } from "zustand";
import { getFromLocalStorage } from "../services/localStorageGetSet";

type State = {
  accessToken: string;
};

type Action = {
  updateAccessToken: (newToken: State['accessToken']) => void;
};

export const useAuth = create<State & Action>((set) => ({
  accessToken: getFromLocalStorage('access-token') || '',
  updateAccessToken: (newToken: string) => set(() => ({ accessToken: newToken })),
}));
