import Button from '@/common/components/Button';
import Checkbox from '@/common/components/Checkbox';
import SelectController from '@/common/components/SelectController';
import { options as unitForQuantity } from "@/common/constants/unitForQuantityOptions.constant";
import { options as applicableProductsPeriodLimit } from "../../constants/applicableProductsPeriodLimit.constant";
import { FileBoxIcon, XIcon } from 'lucide-react';
import { Control, Controller, FieldErrors, UseFormRegister } from 'react-hook-form';
import { CurrencyTextField } from '@/common/components/CurrencyTextField';
import { CardProductLimitValidationSchema, EquipmentValidationSchema } from '../../schemas/create.schema';
import { UnitTextField } from '@/common/components/UnitTextField';

interface ApplicableProductProps {
  applicableProduct: CardProductLimitValidationSchema;
  applicableProductIndex: number;
  removeApplicableProduct: (index: number) => void;
  limitByTransaction?: boolean;
  limitByPeriod?: boolean;
  control: Control<EquipmentValidationSchema, any>;
  register: UseFormRegister<EquipmentValidationSchema>;
  errors: FieldErrors<EquipmentValidationSchema>;
  disabled?: boolean;
}

export const ApplicableProduct = ({
  applicableProduct,
  applicableProductIndex,
  removeApplicableProduct,
  limitByTransaction,
  limitByPeriod,
  control,
  register,
  errors,
  disabled,
}: ApplicableProductProps) => {
  return (
    <div className="flex w-full flex-col gap-y-6 bg-[#FFFFFF0D] rounded-sm p-4">
      <div className="flex items-center">
        <div className="flex items-center gap-x-2 bg-white-200 p-2">
          <FileBoxIcon strokeWidth={1} />
          <p>{applicableProduct.display_name}</p>
        </div>
        <Button
          onClick={() => removeApplicableProduct(applicableProductIndex)}
          className="bg-black hover:bg-black-900 rounded-none p-2"
        >
          <XIcon strokeWidth={1} />
        </Button>
      </div>
      <div className="flex items-center gap-x-6">
        <Checkbox
          label="Limite por transação"
          labelClassName="text-[10px] italic font-light"
          {...register(`card_product_limits_attributes.${applicableProductIndex}.limitByTransaction`)}
          disabled={disabled}
        />
        <Checkbox
          label="Limite por período"
          labelClassName="text-[10px] italic font-light"
          {...register(`card_product_limits_attributes.${applicableProductIndex}.limitByPeriod`)}
          disabled={disabled}
        />
      </div>
      {limitByTransaction && (
        <div className="flex flex-col gap-y-4">
          <p>Limite por Transação</p>
          <div className="flex w-full gap-x-2">
            <UnitTextField
              label="Quantidade"
              unit={unitForQuantity.find((option) => option.value === applicableProduct.unit_for_quantity)?.symbol}
              className="w-full"
              {...register(`card_product_limits_attributes.${applicableProductIndex}.transactionLimit.quantity`)}
              errorMessage={errors.card_product_limits_attributes?.[applicableProductIndex]?.transactionLimit?.quantity?.message || undefined}
              disabled={disabled}
            />
            <p className="mt-9">Ou</p>
            <Controller
              name={`card_product_limits_attributes.${applicableProductIndex}.transactionLimit.totalValue`}
              control={control}
              disabled={disabled}
              render={({ field }) => (
                <CurrencyTextField
                  label="Valor total"
                  className="w-full"
                  errorMessage={errors.card_product_limits_attributes?.[applicableProductIndex]?.transactionLimit?.totalValue?.message || undefined}
                  {...field}
                />
              )}
            />
          </div>
          {errors.card_product_limits_attributes?.[applicableProductIndex]?.transactionLimit?.root?.message && (
            <p className="text-danger-soft text-sm mt-[-8px]">{errors.card_product_limits_attributes?.[applicableProductIndex]?.transactionLimit?.root?.message}</p>
          )}
          <p className="font-light text-xs italic mt-[-8px]">Caso selecione <span className="font-bold not-italic">Quantidade</span> e <span className="font-bold not-italic">Valor total</span>, vale o que for atingido primeiro.</p>
        </div>
      )}
      {limitByPeriod && (
        <div className="flex flex-col gap-y-4">
          <p>Limite por Período</p>
          <SelectController
            control={control}
            name={`card_product_limits_attributes.${applicableProductIndex}.periodLimit.period`}
            label="Período"
            options={applicableProductsPeriodLimit}
            errorMessage={errors.card_product_limits_attributes?.[applicableProductIndex]?.periodLimit?.period?.message || undefined}
            isDisabled={disabled}
          />
          <div className="flex w-full gap-x-2">
            <UnitTextField
              label="Quantidade"
              unit={unitForQuantity.find((option) => option.value === applicableProduct.unit_for_quantity)?.symbol}
              className="w-full"
              {...register(`card_product_limits_attributes.${applicableProductIndex}.periodLimit.quantity`)}
              errorMessage={errors.card_product_limits_attributes?.[applicableProductIndex]?.periodLimit?.quantity?.message || undefined}
              disabled={disabled}
            />
            <p className="mt-9">Ou</p>
            <Controller
              name={`card_product_limits_attributes.${applicableProductIndex}.periodLimit.totalValue`}
              control={control}
              disabled={disabled}
              render={({ field }) => (
                <CurrencyTextField
                  label="Valor total"
                  className="w-full"
                  errorMessage={errors.card_product_limits_attributes?.[applicableProductIndex]?.periodLimit?.totalValue?.message || undefined}
                  {...field}
                />
              )}
            />
          </div>
          {errors.card_product_limits_attributes?.[applicableProductIndex]?.periodLimit?.root?.message && (
            <p className="text-danger-soft text-sm mt-[-8px]">{errors.card_product_limits_attributes?.[applicableProductIndex]?.periodLimit?.root?.message}</p>
          )}
          <p className="font-light text-xs italic mt-[-8px]">Caso selecione <span className="font-bold not-italic">Quantidade</span> e <span className="font-bold not-italic">Valor total</span>, vale o que for atingido primeiro.</p>
        </div>
      )}
    </div>
  );
};
