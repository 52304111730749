import { Option } from "@/common/types/option";

export class Mapper {
  static default<T>() {
    return (data: T) => data;
  }

  static mapToOptions<T>({ labelFieldName, valueFieldName }: { labelFieldName: keyof T | (keyof T)[], valueFieldName: keyof T; }) {
    return (data: T[]) =>
      data.map(item => ({
        label: (labelFieldName instanceof Array) ? labelFieldName.flatMap((fieldName) => item[fieldName] ? item[fieldName] : []) : item[labelFieldName],
        value: item[valueFieldName]
      })) as Option[];
  }

  static mapWithTransformer<T, R>({ transformer }: { transformer: (data: T) => R; }) {
    return (data: T) => transformer(data);
  }
}
